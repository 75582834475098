import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import vm from '../reset-password.controller';
import core from 'root/utils/core';

class FloatingResetPassword extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div
                        className='reset-page-box'
                        onClick={() => {
                            this.props.close();
                        }}
                    >
                        <div
                            className='reset-popup'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <i
                                className='icon-close2'
                                onClick={() => {
                                    this.props.close();
                                }}
                            ></i>

                            <CSSTransition classNames='alert' unmountOnExit timeout={300} in={this.state.requestSuccess}>
                                <div className='input-success'>{t('reset:reset.request.messages.success')}</div>
                            </CSSTransition>

                            {this.state.requestFail ? (
                                <div className='input-fail'>
                                    <Trans i18nKey='reset:reset.request.messages.notfound'></Trans>
                                </div>
                            ) : (
                                !this.state.requestSuccess && (
                                    <Fragment>
                                        <p className='reset-title'>{t('reset:reset.finish.title', 'Reset Password')}</p>

                                        <div className='msg-tips'>
                                            <p>{t('reset:reset.request.messages.info')}</p>
                                            <p>
                                                <Trans i18nKey={'reset:reset.request.messages.info-2'}>
                                                    If you don't have an email address, <br /> please contact our
                                                    <span
                                                        onClick={() => {
                                                            core.openLivechat(this.props.language.countryLanguageKey, this.props.screen.viewType);
                                                        }}
                                                    >
                                                        customer service
                                                    </span>
                                                    .
                                                </Trans>
                                            </p>
                                        </div>

                                        <div>
                                            <div className='input-item'>
                                                <i className='icon-at-sign'></i>
                                                <div className='input'>
                                                    <input
                                                        type='text'
                                                        value={this.state.fields['email']}
                                                        onChange={(e) => {
                                                            vm.handleFieldChanged(e, 'email');
                                                        }}
                                                        placeholder={t('global:global.form.email', 'Email')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='btn-item'>
                                                <span className='input-error'>{t(this.state.errors['email'])}</span>

                                                <button
                                                    className='btn-reset'
                                                    onClick={() => {
                                                        vm.reset();
                                                    }}
                                                    disabled={this.state.disabledButton}
                                                >
                                                    {t('reset:reset.finish.form.button', 'Reset').toUpperCase()}
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('reset')(withRouter(FloatingResetPassword)));
