import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import MyCollection from '../MyCollection';
import DroppingHot from '../../home/desktop/DroppingHot';
import Donotmiss from '../../home/desktop/Donotmiss';
import Loading from 'root/webapp/shared/loading/loading';

import vm from '../favourite.controller';

class Favourite extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getHotGames();
        vm.getJackpotValue();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        let hotGameSource = {
            hotGameList: this.state.hotGameList,
        };

        let favouriteSource = {
            favoritedGame: this.state.favoritedGame,
            updateMemberFavoriteGame: vm.updateMemberFavoriteGame,
        };

        return (
            <Translation>
                {(t) => (
                    <div className='favourite-panel'>
                        {this.state.isLoading && <Loading className='vw-100 vh-100'></Loading>}

                        {/* my collection */}
                        <MyCollection {...favouriteSource} />

                        {/* hot slot games */}
                        <DroppingHot jackpotObj={this.state.jackpotObj} maxGameShow={14} {...hotGameSource} {...favouriteSource} />

                        {/* 4 game category quick shortcut menu */}
                        <Donotmiss />
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('favourite')(withRouter(Favourite)));
