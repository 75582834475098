import providerService from 'root/utils/providerService';
import { TweenMax } from 'gsap';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';
import { PROVIDER_DATA } from 'root/utils/constants';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            currentProvider: '',
            currentProviderKey: '',
            tabFilter: 'HTML5',
            keyword: '',
            brandActive: 0,
            typeActive: 0,
            typeList: [],
            brandList: [],
            gameList: [],
            showMore: false,
            favoritedGame: [],
            tween1: null,
            tween2: null,
            displayGames: [],
        };
    },

    getProviderList(tween1, tween2, selectProviderBasedOnUrl = false) {
        const { language } = controller.vm.props;
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let loadGetSubmenu = new Promise((resolve) => {
            window.SPL_Content.getSubmenu(language.countryLanguageKey, 'web', 'SL').then((data) => resolve(data));
        });

        let loadProviderGameMaintenanceList = new Promise((resolve) => {
            window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
        });

        return new Promise((resolve) => {
            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let brandList = [];
                let currentProvider = '';
                let currentProviderKey = '';
                let brandActive = 0;

                if (window.location.pathname.indexOf('/slots') >= 0 && window.location.pathname.indexOf('/slots/') <= -1) {
                    selectProviderBasedOnUrl = false;
                }

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    let foundProvider = PROVIDER_DATA && PROVIDER_DATA[provider];

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                foundProvider.underMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundProvider) {
                        if (selectProviderBasedOnUrl === false) {
                            if (i === 0) {
                                currentProvider = foundProvider.provider;
                                currentProviderKey = foundProvider.key;
                                brandActive = i;
                            }
                        } else {
                            // Create a regular expression to match the whole word "drg"
                            var regex = new RegExp('\\b' + foundProvider.routeName + '\\b');

                            // test is used to check whether regular expression is matching correctly for each foundProvider.
                            if (regex.test(window.location.pathname)) {
                                currentProvider = foundProvider.provider;
                                currentProviderKey = foundProvider.key;
                                brandActive = i;
                            }
                            // if (window.location.pathname.indexOf(foundProvider.routeName) >= 0) {
                            //     currentProvider = foundProvider.provider;
                            //     currentProviderKey = foundProvider.key;
                            //     brandActive = i;
                            // }
                        }

                        foundProvider.isHotProvider = isHotProvider;
                        foundProvider.isNewProvider = isNewProvider;

                        brandList.push(foundProvider);
                    }
                }

                controller.vm.setState(
                    {
                        brandList: brandList,
                        brandActive: brandActive,
                        currentProvider: currentProvider,
                        currentProviderKey: currentProviderKey,
                        tween1: tween1,
                        tween2: tween2,
                    },
                    () => {
                        // controller.getProviderGameList(currentProvider, undefined, undefined, tween1, tween2);
                        resolve();
                    }
                );
            });
        });
    },

    getDefaultTab() {
        const { currentProvider } = controller.vm.state;
        // all WhiteLabel required set NS default tab as hot
        if (currentProvider === 'NS') {
            return 'HOT';
        } else {
            return 'HTML5';
        }
    },

    getMobileProviderList() {
        return new Promise(function (resolve) {
            const { language } = controller.vm.props;
            let currencyLang = language.currencyLang;

            if (language.country === 'KH') currencyLang = 'KHUSD';

            // window.SPL_Content.getSubmenu(language.countryLanguageKey, 'mobile', 'SL').then((data) => {
            //     let brandList = [];

            //     for (let i = 0; i < data.length; i++) {
            //         let provider = data[i].provider;
            //         const isHotProvider = data[i].isHotProvider;
            //         let foundProvider = controller.providerData[provider];

            //         if (foundProvider) {
            //             foundProvider.isHotProvider = isHotProvider;
            //             brandList.push(foundProvider);
            //         }
            //     }

            //     controller.vm.setState({ brandList: brandList });
            //     resolve();
            // });

            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, 'mobile', 'SL').then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let brandList = [];

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    let foundProvider = PROVIDER_DATA && PROVIDER_DATA[provider];

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                foundProvider.underMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundProvider) {
                        foundProvider.isHotProvider = isHotProvider;
                        foundProvider.isNewProvider = isNewProvider;

                        brandList.push(foundProvider);
                    }
                }

                controller.vm.setState({ brandList: brandList }, () => {
                    resolve();
                });
            });
        });
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    checkUnderMaintenance(providerObj, underMaintenance, i) {
        if (!underMaintenance) {
            controller.resetProviderFilter();
            controller.updateSelectedProvider(providerObj, underMaintenance);
            // vm.getProviderGameList(providerObj.provider);
            controller.toggleTab(i, 'brand');
        }
    },

    toggleTab(index, type = 'type') {
        if (type === 'type' && index === controller.vm.state.typeActive) return;
        if (type === 'type') {
            controller.vm.setState({ typeActive: index });
        } else {
            controller.vm.setState({ brandActive: index });
        }
    },

    updateSelectedProvider(providerObj) {
        switch (providerObj.provider) {
            case 'MEGA':
            case 'SCR2':
            case 'PSY8':
                controller.vm.props.history.push('/' + providerObj.routeName);
                break;
            default:
                controller.vm.props.history.push('/slots/' + providerObj.routeName);
                break;
        }
        controller.vm.setState({ currentProvider: providerObj.provider, currentProviderKey: providerObj.key, showMore: false }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    updateTabFilter(type) {
        controller.vm.setState({ tabFilter: type.toUpperCase() }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    resetProviderFilter() {
        controller.vm.setState({
            tabFilter: 'HTML5',
            // keyword: '',
            typeActive: 0,
            typeList: [],
            gameList: [],
        });
    },

    getProviderGameList(provider, tabFilter = 'HTML5', keyword = '', tween1, tween2) {
        const { screen, language, portal } = controller.vm.props;

        let passProvider = provider;

        controller.vm.setState({ gameList: [] });

        // switch (provider) {
        //   case 'PT2':
        //     passProvider = 'PT';
        //     break;

        //   default:
        //     break;
        // }
        return new Promise(async (resolve) => {
            let data = await window.SPL_Content.getAllSlotGames(screen.viewType, true, passProvider, tabFilter, keyword, portal && portal.settings && portal.settings.features && portal.settings.features.providerPageSettings && portal.settings.features.providerPageSettings.hotNewUseJson);
            for (let i = 0; i < data.displayGames.length; i++) {
                if (data.displayGames[i].customImage) {
                    data.displayGames[i].imggame = data.displayGames[i].thumbnail;
                } else {
                    data.displayGames[i].imggame = `/public/html/games/images/s1/${provider}/${data.displayGames[i].game}.jpg`;
                }
                data.displayGames[i].text = language.key === 'zh-CN' ? data.displayGames[i].nameCN : data.displayGames[i].name;
            }

            for (let k = 0; k < data.slotCategory.length; k++) {
                switch (data.slotCategory[k]) {
                    case 'HTML5':
                        data.slotCategory[k] = 'All';
                        break;

                    case 'HOT':
                        data.slotCategory[k] = 'Hot';
                        break;

                    case 'NEW':
                        data.slotCategory[k] = 'New';
                        break;

                    case 'SLOT':
                        data.slotCategory[k] = 'Slot';
                        break;

                    case 'ARCADE':
                        data.slotCategory[k] = 'Arcade';
                        break;

                    case 'TABLE':
                        data.slotCategory[k] = 'Table';
                        break;

                    case 'SCRATCH':
                        data.slotCategory[k] = 'Scratch';
                        break;

                    case 'CARD':
                        data.slotCategory[k] = 'Card';
                        break;

                    case 'VIDEO':
                        data.slotCategory[k] = 'Video';
                        break;

                    case 'KENO':
                        data.slotCategory[k] = 'Keno';
                        break;

                    default:
                        break;
                }
            }

            let displayGame = [];

            if (!controller.vm.state.showMore && data.displayGames.length > 25) {
                for (let i = 0; i < 25; i++) {
                    displayGame.push(data.displayGames[i]);
                }
            } else {
                displayGame = data.displayGames;
                controller.vm.setState({ showMore: true });
            }

            let brandList = controller.vm.state.brandList;
            for (let j = 0; j < brandList.length; j++) {
                if (provider === brandList[j].provider) {
                    displayGame.underMaintenance = brandList[j].underMaintenance;
                }
            }

            controller.vm.setState({ typeList: data.slotCategory, loading: false }, () => {
                controller.getMemberFavoriteGame(displayGame, tween1, tween2);
                resolve();
            });
        });
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const { screen, language } = controller.vm.props;
        let isMobile = null;

        if (screen.viewType === 'web') {
            isMobile = false;
        } else {
            isMobile = true;
        }

        for (let i = 0; i < gameList.length; i++) {
            gameList[i].isFav = false;

            //massage gamelist name
            if (gameList[i].i18n && language.key !== 'zh-CN') {
                let gameName = gameList[i].name;
                try {
                    gameName = JSON.parse(gameList[i].i18n);
                    if (gameName[language.key.toUpperCase()]) {
                        gameList[i].name = gameName[language.key.toUpperCase()];
                    }
                } catch (e) {}
            } else {
                if (language.key === 'zh-CN') {
                    gameList[i].name = gameList[i].nameCN;
                }
            }
        }

        window.SPL_Member.getMemberFavoriteGame(isMobile).then((data) => {
            let favGameList = data.favoritedGame;

            for (let k = 0; k < favGameList.length; k++) {
                favGameList[k].imggame = `/public/html/games/images/s1/${favGameList[k].provider}/${favGameList[k].game}.jpg`;

                for (let a = 0; a < gameList.length; a++) {
                    if (gameList[a].id === favGameList[k].id) {
                        gameList[a].isFav = true;
                    }
                }
            }

            controller.vm.setState({ favoritedGame: favGameList, gameList: gameList }, () => {
                controller.doSuspendAni(tween1, tween2);
            });
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;

            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    // animation effect
    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    },

    showMoreGames() {
        controller.vm.setState({ showMore: true }, () => {
            const { currentProvider, tabFilter } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter);
        });
    },

    launchSlotGame(gameObj, isFun) {
        const { language, screen, user } = controller.vm.props;
        let params = {
            domain: window.location.hostname,
            isMobile: screen.isMobile,
            isFun: isFun,
            isMenuMode: !isFun,
            isMiniGame: false,
            languageKey: language.key,
        };

        if (gameObj) {
            gameObj.category = 'SL';
        }

        if (controller.vm.state.provider === 'UL' || gameObj.provider === 'UL') {
            params.gameCode = 'S-UL01';
            params.provider = 'UL';
        } else {
            params.gameCode = gameObj.game;
            params.provider = gameObj.provider;
        }

        providerService.launchGame(gameObj, language, user.isLogin, screen, isFun, params, controller.vm);
    },
};

export default controller;
