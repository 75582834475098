import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import MyCollection from '../MyCollection';
import DroppingHot from '../../home/desktop/DroppingHot';
import Donotmiss from '../../home/desktop/Donotmiss';
import Loading from 'root/webapp/shared/loading/loading';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import { DebounceInput } from 'react-debounce-input';

import vm from '../favourite.controller';

class Favourite extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getHotGames();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        let hotGameSource = {
            hotGameList: this.state.hotGameList,
        };

        let favouriteSource = {
            favoritedGame: this.state.favoritedGame,
            updateMemberFavoriteGame: vm.updateMemberFavoriteGame,
        };

        return (
            <Translation>
                {(t) => (
                    <div className='m-favourite-panel'>
                        {this.state.isLoading && <Loading className='vw-100 vh-100'></Loading>}

                        <HomeMemberProfile />

                        <div className='search-box'>
                            <DebounceInput
                                type='text'
                                debounceTimeout={1000}
                                values={this.state.keyword}
                                placeholder={t('global:global.search', 'Search')}
                                onChange={(e) => {
                                    vm.handleKeywordChanged(e);
                                }}
                            />
                            <i className='icon-search'></i>
                        </div>

                        {/* my collection */}
                        <MyCollection {...favouriteSource} searchKeyword={this.state.search} />

                        {/* hot slot games */}
                        <DroppingHot maxGameShow={14} {...hotGameSource} {...favouriteSource} searchKeyword={this.state.search} />

                        {/* 4 game category quick shortcut menu */}
                        <Donotmiss />
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('favourite')(withRouter(Favourite)));
