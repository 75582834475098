import Transfer from './component/transfer/transfer.selector';
import Transaction from './component/transaction/transaction.selector';
import Promotion from './component/promotion/promotion.selector';
import Rebate from './component/rebate/rebate.selector';
import Rewards from './component/rewards/rewards.selector';
import Utils from 'root/utils';

const controller = {
    vm: null,
    historyTabs: [
        {
            id: 0,
            name: 'transfer',
            tabHeader: 'settings:settings.history.transfer',
            selected: false,
            pathname: '/myaccount/history',
            component: Transfer,
        },
        {
            id: 1,
            name: 'transaction',
            tabHeader: 'settings:settings.history.withdrawalDeposit',
            selected: false,
            pathname: '/myaccount/history/transaction',
            component: Transaction,
        },
        {
            id: 2,
            name: 'promotion',
            tabHeader: 'settings:settings.history.promotionApply',
            selected: false,
            pathname: '/myaccount/history/promotion',
            component: Promotion,
        },
        {
            id: 3,
            name: 'rebate',
            tabHeader: 'settings:settings.history.rebateCashback',
            selected: false,
            pathname: '/myaccount/history/rebate',
            component: Rebate,
        },
        {
            id: 4,
            name: 'rewards',
            tabHeader: 'settings:settings.history.rewards',
            selected: false,
            pathname: '/myaccount/history/rewards',
            component: Rewards,
        },
    ],

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            displayTab: controller.historyTabs,
            selectedTabIndex: 0,
        };
    },

    highlightTab(pathname = null) {
        if (!pathname) {
            pathname = controller.vm.props.location.pathname;
        }
        let selectedElem = null;
        let displayTab = controller.vm.state.displayTab;
        let selectedTabIndex = 0;
        for (let i = 0; i < displayTab.length; i++) {
            if (displayTab[i].pathname === pathname) {
                displayTab[i].selected = true;
                selectedTabIndex = displayTab[i].id;
                selectedElem = document.getElementById('history-tab-' + displayTab[i].id);
                if (selectedElem) {
                    selectedElem.scrollIntoView();
                }
            } else {
                displayTab[i].selected = false;
            }
        }
        selectedElem = null;
        controller.vm.setState({ displayTab, selectedTabIndex });
    },

    toggleTab(tab) {
        let pathname = controller.historyTabs.filter((a) => a.id === tab)[0].pathname;
        controller.vm.setState({ selectedTabIndex: tab });

        if (!Utils.force2Mobile()) {
            controller.vm.props.history.push(pathname);
        }
        controller.highlightTab(pathname);
    },

    getTabContent(tabIndex) {
        // TODO: if forced index --> get index tab instead of window search location. (URL)
        let pathname = tabIndex !== undefined ? controller.historyTabs.filter((a) => a.id === tabIndex)[0].pathname : controller.vm.props.location.pathname;
        let tab = controller.historyTabs.filter((a) => a.pathname === pathname)[0];
        return tab ? tab.component : null;
    },
};

export default controller;
