// library
import React, { useLayoutEffect, useState, useEffect, lazy, Suspense } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { mapStateToProps } from './redux/selector/selector';
import { BrowserRouter, Route } from 'react-router-dom';

// logic/util
import core from './utils/core';
import { Fragment } from 'react';
import commonUtil from 'root/utils/common-util';
import { systemFeatureAction, portalAction, userAction, languageAction } from '@redux/action';
import { POPUP_TYPE } from '@constants';

// components
import MainRoute from './webapp/main-route';
import Loading from './webapp/shared/loading/loading';
import RouterChangeHandler from './handler/routerChangeHandler';
import SetupPinPopup from '@components/organisms/set-up-pin-popup/set-up-pin-popup'; // lazy import bu dao due to, password expect inside <form tag>, need time change and test
const EmailVerificationPopup = lazy(() => import('@components/organisms/email-verification-popup/email-verification-popup'));
const PhoneVerificationPopup = lazy(() => import('@components/organisms/phone-verification-popup/phone-verification-popup'));
const GoogleAuthPopup = lazy(() => import('@components/organisms/google-auth-popup/google-auth-popup'));
const EditVerifyAuthPopup = lazy(() => import('@components/organisms/edit-verify-auth-popup/edit-verify-auth-popup'));
const EnterPinPopup = lazy(() => import('@components/organisms/enter-pin-popup/enter-pin-popup'));
// const SetupPinPopup = lazy(() => '@components/organisms/set-up-pin-popup/set-up-pin-popup');
class App extends React.Component {
    interval;

    constructor() {
        super();

        this.state = {
            initialize: false,
            isLoading: false,
            langPath: '',
            hideLangPathUrl: false,
            languageDomains: null,
            languageLoaded: false,
        };
    }

    componentDidMount() {
        this.interval = this.splLibrary();

        let fingerPrintInterval;
        fingerPrintInterval = setInterval(() => {
            // check whether finger print is ready
            if (window.SPL_FingerPrint) {
                window.SPL_FingerPrint.initialize();
                clearInterval(fingerPrintInterval);
            }
        }, 500);
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.account != prevProps.user.account) {
            this.initializePortalSettings();
        }
    }

    initializePortalSettings = () => {
        window.SPL_Content.getPortalSettings().then((settings) => {
            this.props.dispatch(portalAction.setSettings(settings));
            core.getCurrentLanguage(false, true, this, languageAction).then((langPath) => {
                let hideLangPathUrl = false;
                if (settings && settings.features && settings.features.hideLangPathUrl) {
                    hideLangPathUrl = settings.features.hideLangPathUrl;
                }
                this.redirectDomains(langPath);
                this.setState({ langPath: langPath, hideLangPathUrl: hideLangPathUrl, languageDomains: settings.languageDomains, languageLoaded: true });
            });
        });
    };

    splLibrary = () => {
        return setInterval(() => {
            this.isIdentity();
            this.getCaptchaSetting();
        }, 100);
    };

    isIdentity = () => {
        if (window.SPL_Auth && window.SPL_Content && window.SPL_Member && window.SPL_Transaction && window.SPL_Other && window.cssLoaded) {
            clearInterval(this.interval);

            if (window.SPL_Auth.isAuthenticated()) {
                this.props.dispatch(userAction.isAuthenticated()).then(() => {
                    this.setState({ initialize: true });
                });
            } else {
                this.setState({ initialize: true });
            }

            window.SPL_Handler.validateAffiliateParameters(() => {
                this.props.dispatch(portalAction.affiliateValidated(true));
            });

            this.initializePortalSettings();

            window.SPL_Register.getAdIdLanguage().then((data) => {
                this.setState({ adLang: data.languageCurrency });
            });

            let mobile = false;
            let viewMode = window.SPL_Cookie.getCookie('viewMode');
            if (this.props.screen.viewType === 'mobile' && viewMode !== 'web') {
                mobile = true;
            } else if (viewMode === 'web') {
                this.props.screen.viewType = 'web';
            }

            let metaViewport = document.getElementById('meta-viewport');
            if (this.props.screen.viewType === 'web' && viewMode === 'web') {
                metaViewport.setAttribute('content', 'width=1024');
            } else {
                metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=0,viewport-fit=cover');
            }
            metaViewport = null;

            setTimeout(() => {
                let liveChat = {
                    languageCurrency: this.props.language.countryLanguageKey,
                    isAffiliate: false,
                    isMobile: mobile,
                    isLogin: this.props.user.isLogin,
                };

                window.SPL_Other.appendLivechatScript(liveChat).then((data) => {
                    //console.log('liveChat', data);
                });

                let comm100Params = {
                    isLogin: this.props.user.isLogin,
                    login: this.props.user.account && this.props.user.account.login ? this.props.user.account.login : '',
                    groupType: this.props.user.account && this.props.user.account.membershipLevel ? this.props.user.account.membershipLevel : '',
                    groupName: this.props.user.account && this.props.user.account.groupName ? this.props.user.account.groupName : '',
                    isAgent: window.isAgent,
                    isAffiliate: window.isAffiliate,
                    merchantName: window.globMerchantName,
                };
                window.SPL_Other.initComm100(comm100Params);
            }, 1000);
        }
    };

    getCaptchaSetting = () => {
        if (window.SPL_Content) {
            window.SPL_Content.getCaptchaSetting().then((data) => {
                if (data && data.publicKey) {
                    injectGoogleRecaptchaScript(data.publicKey);
                }
            });
        }

        function injectGoogleRecaptchaScript(publicKey) {
            let googleScriptLoaded = document.getElementById('googleScriptID');
            if (!googleScriptLoaded) {
                let googleScript = document.createElement('script');
                googleScript.id = 'googleScriptID';
                googleScript.src = 'https://www.recaptcha.net/recaptcha/api.js?render=' + publicKey;
                document.head.appendChild(googleScript);
            }
        }
    };

    // if member curreny is MYR then redirect them to MYR domain
    redirectDomains(langPath) {
        let { user } = this.props;
        let pathname = window.location.pathname;
        let account = user.account;
        let memberCurrency = null;
        if (account && window.SPL_Auth.isAuthenticated()) {
            memberCurrency = account.currency;
        }

        if (memberCurrency === 'KHUSD') memberCurrency = 'USD';

        let countryLanguageKey = langPath.toUpperCase().replace('-', '_');
        let languageDomains = this.state.languageDomains;
        if (memberCurrency != null && languageDomains && languageDomains.hasOwnProperty(countryLanguageKey)) {
            let defaultCountryLanguage = window.countryLanguageKey;
            let lastDefaultKey = defaultCountryLanguage.split('_').pop();
            let selectedKey = countryLanguageKey.split('_').pop();
            let urlParam = languageDomains[countryLanguageKey];
            let urlObj = new URL(urlParam);
            let domainHost = urlObj.host;
            let currentHost = window.location.host;
            let langKey = langPath.replace('_', '-').toLowerCase();
            let path = '/home';
            let jwt = window.SPL_Cookie.getCookie('t');
            if (domainHost !== currentHost && lastDefaultKey != selectedKey) {
                window.location.href = urlParam + '?langCode=' + langKey + '&path=' + path + '&t=' + jwt;
            }
        }
    }

    render() {
        const { initialize, langPath, hideLangPathUrl, languageLoaded } = this.state;
        let basename = '';
        if (!hideLangPathUrl) {
            basename = langPath;
        }
        if (langPath === '' || languageLoaded === false) {
            return <Loading className='vw-100 vh-100'></Loading>;
        } else {
            return (
                initialize && (
                    <Translation>
                        {(t) => (
                            <BrowserRouter basename={basename}>
                                <div onClick={(e) => commonUtil.dropdownUpdate(e, false, this.props)}>
                                    <AppComponent />
                                    <PopupComponents />
                                    <RouterChangeHandler></RouterChangeHandler>
                                    <ShowWindowDimensions parentProps={this.props}></ShowWindowDimensions>
                                    <Route
                                        render={(props) => {
                                            let { history, location } = props;
                                            const globalHistory = history.globalHistory || [];
                                            const index = globalHistory.findIndex((it) => it === location.pathname);
                                            if (index >= 0) globalHistory.splice(index, 1);
                                            globalHistory.push(location.pathname);
                                            history.globalHistory = globalHistory;
                                            let _location = location.pathname;
                                            if (this.props.user.account && this.props.user.account.currency) {
                                                if (['/login'].includes(_location)) {
                                                    _location = '/home';
                                                    history.push(_location);
                                                }
                                            }
                                            return <MainRoute location={props.location}></MainRoute>;
                                        }}
                                    ></Route>
                                    <Fragment>{(window.initApp = true)}</Fragment>
                                </div>
                            </BrowserRouter>
                        )}
                    </Translation>
                )
            );
        }
    }
}

// to initialize redux, screen action
const ShowWindowDimensions = ({ parentProps }) => {
    useWindowSize(parentProps);
    return <span></span>;
};

const useWindowSize = (parentProps) => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        let viewMode = window.SPL_Cookie.getCookie('viewMode');

        function updateSize() {
            // let width = Math.min(document.documentElement.clientWidth, window.outerWidth, window.innerWidth);
            // let height = Math.min(document.documentElement.clientHeight, window.outerHeight, window.innerHeight);
            // if (
            //     (
            //         (parentProps.screen.isMobile && width > 768) || (!parentProps.screen.isMobile && width <= 768)
            //     )
            //         && viewMode === null) {
            //     parentProps.dispatch(screenAction.updateScreenSize(width, height, parentProps.screen.isMobile));
            //     setSize([width, height]);
            // }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [parentProps, parentProps.screen, parentProps.screen.isMobile]);
    return size;
};

export default connect(mapStateToProps, null)(withTranslation('')(App));

export const AppComponent = () => {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language);

    useEffect(() => {
        if (language?.currencyLang) {
            dispatch(systemFeatureAction.loadSystemFeature());
            dispatch(systemFeatureAction.fetchCloudFlareTurnstileSetting());
        }
    }, [language.currencyLang, dispatch]);

    return <></>;
};

export const PopupComponents = () => {
    const popupReducer = useSelector((state) => state.popupReducer);
    return (
        <>
            <Suspense fallback={<div></div>}>
                {popupReducer?.items?.[POPUP_TYPE.VERIFY_EMAIL] && <EmailVerificationPopup />}
                {popupReducer?.items?.[POPUP_TYPE.VERIFY_PHONE] && <PhoneVerificationPopup />}
                {popupReducer?.items?.[POPUP_TYPE.GA] && <GoogleAuthPopup />}
                {popupReducer?.items?.[POPUP_TYPE.EDIT_VERIFY_AUTH] && <EditVerifyAuthPopup />}
                {popupReducer?.items?.[POPUP_TYPE.PIN_ENTRY] && <EnterPinPopup />}
                {popupReducer?.items?.[POPUP_TYPE?.SIX_PIN_SETUP] && <SetupPinPopup />}
            </Suspense>
        </>
    );
};
