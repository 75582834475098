// library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// selector / component
import Slider from 'root/webapp/shared/component/Slider';
import { TweenMax } from 'gsap';
import Text from 'root/webapp/components/atoms/text/text';

// controller / util
import vm from '../4d.controller';

const aniList = [];
const fourD = [];

class FourD extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        window.SPL_Other.sequenceCallback(vm.initCompSettings)(() => {
            const { compSettings } = this.state;
            const { fourDPageSettings } = compSettings;

            if (fourDPageSettings?.enable4DDrawResults) {
                vm.getDrawResultConfig(() => {
                    const dateTime = vm.initDrawResultDateTime();
                    vm.checkTimeAndTriggerGetFourDResults();
                    vm.getFourDResults(dateTime);
                });
                vm.getPayoutHistory();
            }
        });
        vm.getFourDProviderList().then((data) => {
            fourD.length = 0;

            for (let i = 0; i < data.length; i++) {
                fourD.push(data[i]);
            }
            vm.selectProviderBasedOnUrl();
        });
    }

    componentDidUpdate(prevProps) {
        const { isAutoFetchIntervalTriggered, isFetchingDrawResultsData, hasTriggeredAutoFetchDrawResults } = this.state;

        // if trigger interval retrieve draw results and don't have any existing draw results function running
        if (isAutoFetchIntervalTriggered && !isFetchingDrawResultsData && !hasTriggeredAutoFetchDrawResults) {
            this.setState({ hasTriggeredAutoFetchDrawResults: true }, () => {
                vm.autoTriggerGetFourDResults();
            });
        }
    }

    componentWillUnmount() {
        fourD.length = 0;
        this.setState = () => {
            return;
        };
    }

    // animation effect
    doSuspendAni(index) {
        aniList.forEach((item) => item.kill());
        aniList.length = 0;
        const idList = fourD[index].suspend.map((item, j) => `#suspend_${index}_${j}`);

        const endStyleStore = [];
        const randomItem = (list) => {
            return list[0 + Math.round(Math.random() * (list.length - 1 - 0))];
        };

        TweenMax.delayedCall(0.02, () => {
            idList.map((id, k) => {
                const $item = document.querySelector(id);
                if ($item) {
                    endStyleStore[k] = {
                        left: getComputedStyle($item).left,
                        bottom: getComputedStyle($item).bottom,
                    };

                    TweenMax.set(id, {
                        x: -parseFloat(getComputedStyle($item).width) / 2,
                        y: [2, 3, 4, 10].includes(index) ? '100%' : 0,
                        bottom: 0,
                        left: '50%',
                        opacity: 0,
                        rotation: randomItem([-80, -40, 40, 80, 100]),
                        onComplete: () => {
                            TweenMax.set(id, {
                                left: getComputedStyle($item).left,
                            });

                            TweenMax.to(id, 0.8, {
                                x: 0,
                                y: 0,
                                left: endStyleStore[k].left,
                                bottom: endStyleStore[k].bottom,
                                opacity: 1,
                                rotation: 0,
                                delay: 0.2 * k + 0.5,
                                onComplete: () => {
                                    TweenMax.set(id, { left: '', bottom: '' });
                                    if (k == idList.length - 1) {
                                        idList.map((id) => {
                                            aniList.push(
                                                TweenMax.to(id, randomItem([5, 6, 7]), {
                                                    x: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    y: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    rotation: randomItem([15, 10, -10, -5]),
                                                    yoyo: true,
                                                    repeat: -1,
                                                })
                                            );
                                        });
                                    }
                                },
                            });
                        },
                    });
                }
            });
        });
    }

    render() {
        const { compSettings, drawList, drawListIndex, drawDate, drawResultSettings, selectedIndex, fourDList, payoutList, payoutCategoryIndex } = this.state;
        const { fourDPageSettings } = compSettings;

        return (
            <Translation>
                {(t) => (
                    <div className='four-d-panel'>
                        {this.state.list.length > 0 && this.state.selectedIndex !== null && (
                            <Slider
                                autoPlay={false}
                                onChange={(i) => {
                                    this.doSuspendAni(i);
                                    vm.selectProvider(i);
                                }}
                                items={this.state.list}
                                forceStart={true}
                                forceSelectIndex={this.state.selectedIndex}
                                checkOnclick={true}
                            />
                        )}

                        <div className='fourD-bottom-container'>
                            {/* sidebar provider list */}
                            <FourDProviderList providerList={fourD} selectedIndex={selectedIndex} t={t} />

                            {/* 4D Content */}
                            {fourDPageSettings?.enable4DDrawResults && (
                                <>
                                    <section className='fourD-draw-container'>
                                        <FourDDrawBoxTabAndDatePicker
                                            drawList={drawList}
                                            drawListIndex={drawListIndex}
                                            drawDate={drawDate}
                                            drawResultsConfig={drawResultSettings?.drawResultsConfig}
                                            onDrawDateChange={vm.onDrawDateChange}
                                            onDrawMenuClicked={vm.onDrawMenuClicked.bind(this)}
                                        />

                                        {drawList[drawListIndex] === 'drawResult' ? (
                                            <>
                                                <div>
                                                    <section className='draw-result-container'>
                                                        <FourDDrawResults
                                                            fourDList={fourDList}
                                                            drawDate={drawDate}
                                                            fourDResultSequence={drawResultSettings?.fourDResultSequence}
                                                        />
                                                    </section>
                                                </div>
                                            </>
                                        ) : (
                                            <FourDPayoutDetails
                                                payoutList={payoutList}
                                                payoutCategoryIndex={payoutCategoryIndex}
                                                onPayoutMenuClicked={vm.onPayoutMenuClicked}
                                            />
                                        )}
                                    </section>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('fourD')(withRouter(FourD)));

export const FourDProviderList = ({ providerList, selectedIndex, t }) => {
    return (
        <section className='brand-list'>
            {providerList.map((providerObj, i) => (
                <Fragment key={i}>
                    {providerList?.maintenance && <div className='maintenance-hover-box'></div>}
                    <div className={[i === selectedIndex ? 'on brand-content' : 'brand-content'].join(' ')}>
                        <span className='icon-box'>
                            {i === selectedIndex ? (
                                <i className={['on', 'icon-brand-' + providerObj?.key].join(' ')}></i>
                            ) : (
                                <i className={['icon-brand-' + providerObj?.key].join(' ')}></i>
                            )}
                        </span>
                        {providerObj?.isHotProvider && <div className='hot-provider-slots'></div>}
                        {providerObj?.isNewProvider && <div className='new-provider-slots'></div>}
                        <span>{t(providerObj?.providerName)}</span>
                    </div>
                </Fragment>
            ))}
        </section>
    );
};

export const FourDDrawBoxTabAndDatePicker = ({ drawList, drawListIndex, drawDate, onDrawDateChange, onDrawMenuClicked, drawResultsConfig }) => {
    const today = new Date();
    const startDate = new Date(drawResultsConfig?.startDateConstraint);

    return (
        <section className='fourD-draw-box'>
            <div className='draw-tab'>
                {(drawList || []).map((list, index) => {
                    return (
                        <div
                            key={index}
                            className={'tab-header ' + (drawListIndex === index && 'active')}
                            onClick={() => {
                                onDrawMenuClicked(index);
                            }}
                        >
                            <div className='tab-inner'>
                                <Text textKey={'fourD:fourD.' + list}></Text>
                            </div>
                        </div>
                    );
                })}
            </div>
            {drawList[drawListIndex] === 'drawResult' && drawDate && (
                <div className='draw-date'>
                    <Text textKey={'fourD:fourD.drawDate'}></Text>
                    <div className='standard-form-field'>
                        <DatePicker
                            id='from-date'
                            className='standard-input'
                            autoComplete='off'
                            type='text'
                            placeholderText={<Text textKey={'reports:reports.startDate'}>{'Start Date'}</Text>}
                            onChange={onDrawDateChange}
                            selected={new Date(drawDate)}
                            dateFormat='yyyy-MM-dd'
                            minDate={startDate}
                            maxDate={today}
                            customInput={<DatePickerCustomInput />}
                        />
                    </div>
                </div>
            )}
        </section>
    );
};

export const DatePickerCustomInput = ({ value, onClick }) => {
    return (
        <div className='standard-input' onClick={onClick}>
            {value}
            <LazyLoadImage src={'/public/html/images/fourD/calender-icon.svg'} alt='' className='calender-icon' />
        </div>
    );
};

export const FourDDrawResults = ({ fourDList, fourDResultSequence }) => {
    const renderRank = (rankData, pokerCombinationTypes) => {
        if (typeof rankData === 'string') {
            return <>{rankData}</>;
        } else if (typeof rankData === 'object') {
            return (
                <>
                    {Object.keys(rankData).map((number, index) => {
                        return (
                            <Fragment key={index}>
                                <div key={number} className={'number-value number-' + Object.keys(rankData).length}>
                                    {rankData[number]}
                                </div>
                                {pokerCombinationTypes === '6D' && Object.keys(rankData).length > 1 && index === 0 && <span className='rank-title'>or</span>}
                            </Fragment>
                        );
                    })}
                </>
            );
        } else if (Array.isArray(rankData)) {
            return rankData.map((number) => {
                return { number };
            });
        }
    };

    const renderRankTitle = (rankTitleData) => {
        if (rankTitleData !== 'translation') {
            return (
                <>
                    <Text textKey={'fourD:fourD.rank.' + rankTitleData}></Text>
                </>
            );
        }
    };

    return (
        <>
            {fourDList &&
                Object.keys(fourDResultSequence).map((jackpotBrand, i) => {
                    //jackpotBrand = Magnum
                    let jackpotBrandData = fourDList[jackpotBrand] && Object.keys(fourDList[jackpotBrand]).length > 0 && fourDList[jackpotBrand];
                    return (
                        <Fragment key={i}>
                            <div className={'draw-result-' + jackpotBrand + ' draw-result-type'}>
                                <div className='draw-result-title'>
                                    <div className='provider-icon'>
                                        <LazyLoadImage src={`/public/html/images/fourD/provider-icon/${jackpotBrand}.svg`} alt={jackpotBrand} />
                                    </div>
                                    <span className='provider-name'>{<Text textKey={'fourD:fourD.provider.' + jackpotBrand + '.title'}></Text>}</span>
                                </div>
                                <>
                                    {Object.keys(jackpotBrandData).map((pokerCombinationTypes, j) => {
                                        //pokerCombinationTypes = ['4D', '6D+1', '6D']
                                        let pokerCombinationTypesData = jackpotBrandData[pokerCombinationTypes];
                                        return (
                                            <Fragment key={j}>
                                                <div className={'result-' + pokerCombinationTypes.replace('+', 'plus')}>
                                                    {pokerCombinationTypes !== '4D' && (
                                                        <div className='result-title'>
                                                            <Text textKey={'fourD:fourD.provider.' + jackpotBrand + '.' + pokerCombinationTypes.replace('+', 'plus')}>
                                                                {'fourD:fourD.provider.' + jackpotBrand + '.' + pokerCombinationTypes.replace('+', 'plus')}
                                                            </Text>
                                                        </div>
                                                    )}
                                                    {pokerCombinationTypesData &&
                                                        Object.keys(pokerCombinationTypesData).map((rank, k) => {
                                                            let rankData = pokerCombinationTypesData[rank];
                                                            return (
                                                                <Fragment key={k}>
                                                                    <div className={rank + '-rank draw-rank-container'}>
                                                                        <div className='rank-title'>{renderRankTitle(rank)}</div>
                                                                        <div className={`${rankData.length < 2 ? 'single-column' : 'number-container'}`}>
                                                                            {renderRank(rankData, pokerCombinationTypes)}
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            );
                                                        })}
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                </>
                            </div>
                        </Fragment>
                    );
                })}
        </>
    );
};

export const FourDPayoutDetails = ({ payoutList, payoutCategoryIndex, onPayoutMenuClicked }) => {
    const payoutSequence = ['details', 'ibox', 'GD', 'GDibox', '9Lotto', '9Lottoibox'];
    return (
        <>
            {payoutList && (
                <div className='payout-container'>
                    <section className='payout-title-container'>
                        {(payoutSequence || []).map((list, listIndex) => {
                            return (
                                <Fragment key={listIndex}>
                                    {Object.keys(payoutList).map((payoutTitle, index) => {
                                        if (list === payoutTitle) {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`payout-title ${payoutCategoryIndex === listIndex && 'active'}`}
                                                    onClick={() => onPayoutMenuClicked(listIndex)}
                                                >
                                                    <Text textKey={'fourD:fourD.' + payoutTitle}></Text>
                                                </div>
                                            );
                                        }
                                    })}
                                </Fragment>
                            );
                        })}
                    </section>
                    <section className='payout-table-container'>
                        {(Object.keys(payoutList) || []).map((list) => {
                            if (list === payoutSequence[payoutCategoryIndex]) {
                                return (
                                    <>
                                        <FourDPayoutCategory packageName={payoutSequence[payoutCategoryIndex]} packageData={payoutList[payoutSequence[payoutCategoryIndex]]} />
                                    </>
                                );
                            }
                        })}
                    </section>
                </div>
            )}
        </>
    );
};

export const FourDPayoutCategory = ({ packageName, packageData }) => {
    const iBoxTableSequence = ['big', 'small', 'Prize'];
    return (
        <div className='payout-table'>
            {packageName.includes('ibox') ? (
                <>
                    {iBoxTableSequence.map((header) => {
                        return (
                            <>
                                {Object.keys(packageData).map((iboxHeader) => {
                                    if (header === iboxHeader) {
                                        return (
                                            <div key={iboxHeader} className='payout-table-result'>
                                                <Table data={packageData[iboxHeader]} tableName={iboxHeader} isIbox={packageName.includes('ibox')} />
                                            </div>
                                        );
                                    }
                                })}
                            </>
                        );
                    })}
                </>
            ) : (
                <Table data={packageData} />
            )}
        </div>
    );
};

export const Table = ({ data, tableName, isIbox }) => {
    const headers = Object.keys(data);
    const rowTitles = Object.keys(data[headers[0]]);

    //THIS PART WILL BE HARDCODE IF GOT ANY CHANGES CAN PUT IN SETTING
    const titleArrangement = isIbox
        ? ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Prize', '4A', '4B', '4C', '4D', '4E', '4F']
        : ['Price', 'Commission', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth'];

    const headersArrangement = isIbox ? ['ibox24', 'ibox12', 'ibox6', 'ibox4'] : ['Big', 'Small', '4a', 'A', 'Abc', '5D', '2D', '2C', '6D', '2A', '2F'];

    return (
        <table className='payout-details-table'>
            <thead>
                <tr>
                    <th className='payout-table-title'>{tableName && <Text textKey={'fourD:fourD.payoutSubTitle.' + tableName.toLowerCase()}></Text>}</th>
                    {(headersArrangement || []).map((title) => (
                        <>
                            {(headers || []).map((header) => {
                                if (title === header) {
                                    return (
                                        <th key={title} className='payout-table-subtitle'>
                                            <Text textKey={'fourD:fourD.payoutSubTitle.' + header.toLowerCase()}></Text>
                                        </th>
                                    );
                                }
                            })}
                        </>
                    ))}
                </tr>
            </thead>
            <tbody>
                {(titleArrangement || []).map((rowTitle) => (
                    <>
                        {(rowTitles || []).map((title) => (
                            <>
                                {title === rowTitle && (
                                    <tr key={rowTitle}>
                                        <th>
                                            <Text textKey={'fourD:fourD.payoutDetails.' + rowTitle}>{rowTitle}</Text>
                                        </th>

                                        {(headersArrangement || []).map((title) => (
                                            <>
                                                {(headers || []).map((header) => {
                                                    if (title === header) {
                                                        return (
                                                            <td key={header} className=''>
                                                                {data[header][rowTitle]}
                                                            </td>
                                                        );
                                                    }
                                                })}
                                            </>
                                        ))}
                                    </tr>
                                )}
                            </>
                        ))}
                    </>
                ))}
            </tbody>
        </table>
    );
};
