import { portalAction } from 'root/redux/action/portal.action';
import { userAction } from 'root/redux/action/user.action';
import core from 'root/utils/core';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            fields: {
                email: '',
            },
            errors: {},
            disabledButton: true,
            requestSuccess: false,
            requestFail: false,
        };
    },

    close() {
        controller.vm.props.dispatch(portalAction.floatingPageUpdated(''));
    },

    handleFieldChanged(e, field) {
        const { fields } = controller.vm.state;
        let errors = controller.vm.state.errors;

        fields[field] = e.target.value;
        controller.vm.setState({ fields });

        if (field === 'email' && typeof fields['email'] !== undefined) {
            let email = fields['email'];

            window.SPL_Register.validateEmailFormat(email).then((errMsg) => {
                controller.handleInputError(errMsg, 'email', errors);
            });
        }
    },

    handleInputError(errMsg, field, errors) {
        if (errMsg.length > 0) {
            errors[field] = errMsg[0];

            controller.vm.setState({ errors: errors, disabledButton: true });
        } else {
            errors[field] = '';

            controller.vm.setState({ errors: errors, disabledButton: false });
        }
    },

    reset() {
        let email = controller.vm.state.fields['email'];

        window.SPL_Member.reqResetPasswordEmail(email).then((res) => {
            if (res === 'success') {
                controller.vm.setState({ requestSuccess: true, requestFail: false, disabledButton: true });
            } else {
                controller.vm.setState({ requestSuccess: false, requestFail: true, disabledButton: false }, () => {
                    setTimeout(() => {
                        controller.vm.setState({ requestFail: false });
                    }, 2000);
                });
            }
        });
    },
};

export default controller;
