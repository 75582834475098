// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { AiOutlineCopy } from 'react-icons/ai';

// components/files

import { LazyLoadImage } from 'react-lazy-load-image-component';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import AddBankDialog from '../../../../../add-bank-dialog/component/add-bank-dialog_96c';
import ImageHelper from 'root/webapp/shared/image-helper/image-helper';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

// controller/logic
import vm from '../../bank-transfer.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import { popupAction } from '../../../../../../../../redux/action';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE as MT, AMOUNT_DEPOSIT_METHOD_CODE } from '@constants';
class BankTransfer extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.getMerchantAndMemberBanks();
            // vm.getCorrectDepositMinMaxLimit();
            vm.loadBankTransferDropdownOption();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.B));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.B) });
        }
    }

    render() {
        const {
            bankList,
            selectedBankIndex,
            depositAmountOptions,
            depositMinMaxLimit,
            depositOptions,
            depositAllSettings,
            isFormValid,
            disableDepositButton,
            fields,
            addBankDialogIsShow,
            errors,
            hoveredIndex,
            amountErrorValue,
            selectedChannel,
            memberBankList,
            receiptImg,
            receiptRequired,
            showAmountField,
            popupCollection,
            customTranslationObj,
            isShowNoticeBox,
        } = this.state;
        const { user, portal } = this.props;
        let transactionNoteProps = {
            customFilename: this.state.customFilename,
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.deposit.cashdeposit.banktransfer', 'Bank Transfer'),
            customTotalAllowTitle: this.props.t('transaction:transaction.deposit.cashdeposit.totalallowed2', 'Total Allow'),
            customMinMaxTitle: this.props.t('transaction:transaction.deposit.cashdeposit.min/maxlimit2', 'Min / Max'),
        };

        let addBankDialogProps = {
            addBankSuccess: vm.addBankSuccess,
            unOwnedBankList: this.state.unOwnedBankList,
        };

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {(addBankDialogIsShow || (popupCollection && popupCollection['add-bank'])) && (
                            // <div
                            //     className='addBank-box'
                            //     onClick={() => {
                            //         transactionHelper.close(this, 'addBank');
                            //     }}
                            // >
                            //     <div
                            //         className='addBank-popup'
                            //         onClick={(e) => {
                            //             e.stopPropagation();
                            //         }}
                            //     >
                            //         <div className='title'>
                            //             <span>{t('transaction:transaction.deposit.addBank.addBankTitle', 'Add Bank')}</span>
                            //             <i
                            //                 className='icon-close3'
                            //                 onClick={() => {
                            //                     transactionHelper.close(this, 'addBank');
                            //                 }}
                            //             ></i>
                            //         </div>

                            //         <div className='content'>
                            //             <div className='input'>
                            //                 <div className='bankName'>{t('transaction:transaction.deposit.addBank.bankName', 'Bank Name')}</div>
                            //                 <select
                            //                     value={accountBankfields['selectedBankIndex']}
                            //                     onChange={(e) => {
                            //                         vm.handleBankChange(e, 'fullname');
                            //                     }}
                            //                 >
                            //                     {unOwnedBankList &&
                            //                         unOwnedBankList.length > 0 &&
                            //                         unOwnedBankList.map((bank, index) => (
                            //                             <option key={index} value={index}>
                            //                                 {t(bank.bankName)}
                            //                             </option>
                            //                         ))}
                            //                 </select>
                            //             </div>

                            //             {showBankName && (
                            //                 <div className='input'>
                            //                     <div className='bankName'>{t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}</div>
                            //                     <DebounceInput
                            //                         type='text'
                            //                         values={accountBankfields['bankName']}
                            //                         placeholder={t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}
                            //                         onChange={(e) => {
                            //                             transactionHelper.handleAddBankChange(this, e, 'bankName');
                            //                         }}
                            //                     />
                            //                 </div>
                            //             )}

                            //             <div className='input'>
                            //                 <div>{t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}</div>
                            //                 <DebounceInput
                            //                     type='text'
                            //                     values={accountBankfields['bankBranch']}
                            //                     placeholder={t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}
                            //                     onChange={(e) => {
                            //                         transactionHelper.handleAddBankChange(this, e, 'bankBranch');
                            //                     }}
                            //                 />
                            //             </div>

                            //             <div className='input'>
                            //                 <div>{t('transaction:transaction.deposit.addBank.accountName', 'Account Name')}</div>
                            //                 <DebounceInput type='text' placeholder={user.account.name} disabled />
                            //             </div>

                            //             <div className='input'>
                            //                 <div>{t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}</div>
                            //                 <DebounceInput
                            //                     type='text'
                            //                     values={accountBankfields['accountNumber']}
                            //                     placeholder={t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}
                            //                     onChange={(e) => {
                            //                         transactionHelper.handleAddBankChange(this, e, 'accountNumber');
                            //                     }}
                            //                 />
                            //             </div>

                            //             <div className='input-error'>{t(errors['accountNumber'])}</div>

                            //             {/****** Bank Statement *******/}
                            //             {reqBankStatementByCurrencies && (
                            //                 <div className='input bank-statement-input'>
                            //                     <div>{t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')}</div>
                            //                     <button className='standard-upload-button upload-btn'>
                            //                         {t('transaction:transaction.withdrawal.upload', 'Upload')}
                            //                             <input
                            //                                 id='hiddenBankStatementInput'
                            //                                 style={{ display: 'none' }}
                            //                                 type='file'
                            //                                 accept='image/*'
                            //                                 onChange={(e) => vm.handleReceiptImageChange(e, this)}
                            //                             ></input>
                            //                         </button>
                            //                     <div className='input-error'>
                            //                         {t(
                            //                             'transaction:transaction.withdrawal.bankStatementRequirement2',
                            //                             'Bank statement must show with full name and bank account number'
                            //                         )}
                            //                     </div>

                            //                     {receiptImg.length > 0 && (
                            //                         <div className='group-item'>
                            //                             <div className='title'></div>
                            //                             <div className='amount-content'>
                            //                                 <div className='input-box receipt-image-box'>
                            //                                     <LazyLoadImage src={receiptImg} alt='' />
                            //                                 </div>
                            //                             </div>
                            //                         </div>
                            //                     )}

                            //                 </div>
                            //             )}

                            //             <button
                            //                 className='btn-submit'
                            //                 disabled={!transactionHelper.isValidForm(this)}
                            //                 onClick={() => transactionHelper.handdleAddAccountBankSubmit(this)}
                            //             >
                            //                 {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                            //             </button>
                            //         </div>
                            //     </div>
                            // </div>
                            <AddBankDialog
                                {...addBankDialogProps}
                                closeFunction={() => {
                                    vm.togglePopup('add-bank');
                                    this.props.dispatch(popupAction.setPopupVisible(false));
                                }}
                            />
                        )}

                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list'>
                                <ul>
                                    {bankList.map((bank, i) => (
                                        <li
                                            key={i}
                                            className={[
                                                'icon-hover-box',
                                                selectedBankIndex === i ? 'on' : '',
                                                bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                            ].join(' ')}
                                            onClick={() => {
                                                vm.onBankChanged(bank);
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({ hoveredIndex: i });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ hoveredIndex: null });
                                            }}
                                        >
                                            <span className={`icon-box ${bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''}`}>
                                                <i
                                                    className={[
                                                        'icon-bank',
                                                        selectedBankIndex === i || (hoveredIndex !== null && hoveredIndex === i) ? 'icon-bank-on' : 'icon-bank-off',
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-bank' : '',
                                                    ].join(' ')}
                                                >
                                                    <div>
                                                        <BankIcon bankCode={bank.code} />
                                                    </div>
                                                </i>
                                            </span>
                                            <span>{bank.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {bankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>
                                        {' '}
                                        {customTranslationObj && customTranslationObj.bankAccount
                                            ? t(customTranslationObj.bankAccount)
                                            : t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}
                                    </span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-account-container'>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].accountHolder}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].accountHolder)}></AiOutlineCopy>
                                    </div>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].accountNumber}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].accountNumber)}></AiOutlineCopy>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <div className='deposit-input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={fields['amount']}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {depositAllSettings.showDepositChannel && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <select value={selectedChannel} onChange={(e) => vm.changeChannel(e)}>
                                            {depositOptions.map((channel, index) => (
                                                <option key={index} value={index}>
                                                    {channel.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!depositAllSettings.hideSenderBank && (
                            <div className='group-item input-item bank-selection-form-field'>
                                <div className='title'>
                                    <span>
                                        {customTranslationObj && customTranslationObj.bank
                                            ? t(customTranslationObj.bank)
                                            : t('transaction:transaction.deposit.depositBankDetails', 'Bank Details')}
                                    </span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <select value={this.state.selectedMemberBankIndex} onChange={(e) => vm.handleBankDetailChange(e)}>
                                            {memberBankList.map((bank, index) => (
                                                <option key={index} value={index}>
                                                    {bank.displayName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {!depositAllSettings.hideAddBankAccount && (
                                    <AddBankDetailButton
                                        onClick={() => {
                                            vm.togglePopup('add-bank');
                                            this.props.dispatch(popupAction.setPopupVisible(true));
                                        }}
                                        text={t('transaction:transaction.deposit.addBank.add', 'ADD')}
                                    />
                                )}
                                <div
                                    className='important-notice-container'
                                    onMouseEnter={() => vm.onImportantNoticeIconHover(true)}
                                    onMouseLeave={() => vm.onImportantNoticeIconHover(false)}
                                >
                                    <ImageHelper displaySVG={'info-icon'} className={`info-icon`} svgType='SVGElements' isSVG={true} />
                                </div>

                                {isShowNoticeBox && (
                                    <div className={`important-notice-box`}>
                                        <div>
                                            {t('transaction:transaction.deposit.bank-select-notify', 'Add or select a bank account you wish to transfer the money from.')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {depositAllSettings.showRefId && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='reference'
                                            type='text'
                                            autoComplete='off'
                                            placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}
                                            value={fields['reference']}
                                            onChange={(e) => transactionHelper.handleFieldChanged(this, e, 'reference')}
                                            onBlur={() => {
                                                transactionHelper.handleValidation(this);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {depositAllSettings.uploadReceipt && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.uploadbtn', 'Upload Receipt')}</span>
                                    {receiptRequired && <span className='required'>*</span>}
                                </div>

                                <div className='amount-content'>
                                    {receiptImg.length > 0 && (
                                        <div className='group-item'>
                                            <div className='amount-content'>
                                                <div className='input-box receipt-image-box'>
                                                    <LazyLoadImage src={receiptImg} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className='amount-content'>
                                        {/* input box for width */}
                                        <div className='input-box upload-receipt-box'>
                                            <button
                                                className='btn upload-btn'
                                                onClick={() => {
                                                    transactionHelper.handleUploadReceiptButtonOnClick();
                                                }}
                                            >
                                                {t('transaction:transaction.deposit.cashdeposit.uploadbtn', 'Upload Receipt')}
                                                <input
                                                    id='hiddenFileInput'
                                                    style={{ display: 'none' }}
                                                    type='file'
                                                    accept='image/*'
                                                    onChange={(e) => transactionHelper.handleReceiptImageChange(e, this)}
                                                ></input>
                                            </button>
                                        </div>
                                        <div className='error-validation alert-message'>
                                            {t(
                                                'transaction:transaction.deposit.cashdeposit.uploadReceiptRemark',
                                                'Interbank transfer and cash deposit machine require receipts for fast approval of deposits'
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className='clear-receipt-button'
                                    onClick={() => {
                                        transactionHelper.handleClearUploadedReceiptButtonOnClick(this);
                                    }}
                                >
                                    {t('transaction:transaction.deposit.cashdeposit.clearBtn', 'Clear').toUpperCase()}
                                </button>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton || receiptRequired}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                                {/* {receiptRequired && <div className='error-validation'>{t('transaction:transaction.deposit.receiptIsRequired')}</div>} */}
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                        {/* <div className='notice-box'>
                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.option1', 'Option:Bank Transfer')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.mode1', 'Mode:Online & Offline')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.min/maxlimit', 'Min/Max Limit')}</span>
                                <span className='value'>
                                    {depositMinMaxLimit === null ? (
                                        <SmallLoading></SmallLoading>
                                    ) : (
                                        ` ${user.account.currency} ${window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0)} 
                                                / ${window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0)}`
                                    )}
                                </span>
                            </div>

                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.dailylimit', 'Daily Limit:')}</span>
                                <span className='value'>&nbsp;{t('transaction:transaction.deposit.onlinetransfer.unlimited', 'Unlimited')}</span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.totalallowed', 'Total Allowed:')}</span>
                                <span className='value'>&nbsp; {t('transaction:transaction.deposit.onlinetransfer.unlimited', 'Unlimited')}</span>
                            </div>

                            <div className='display-info'>
                                <p>
                                    {t(
                                        'transaction:transaction.deposit.onlinetransfer.note1',
                                        'Note: Please use Bank Transfer via your local bank account. We do not accept all kinds of deposit by ""Cheque"" or ""Bank Draft"" (Company OR Personal Cheque) as your deposit method. '
                                    )}
                                </p>
                            </div>

                            <div className='display-info'>
                                <p>
                                    {t(
                                        'transaction:transaction.deposit.onlinetransfer.note2',
                                        'Once you have successfully submitted your deposit form and once your funds is cleared in our account, just leave it to our team to process your transactions as speedy as possible. If more than 10 minutes, let us know by contacting our Customer Service support. They will assist you 24/7 anytime. '
                                    )}
                                </p>
                            </div>

                            <div className='display-info'>
                                <p>
                                    {t(
                                        'transaction:transaction.deposit.onlinetransfer.note3',
                                        'Please make sure that you fill up accurate bank account details to avoid any inconveniences & using third-party withdrawal is NOT accepted by us.'
                                    )}
                                </p>
                            </div>
                        </div> */}
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(BankTransfer)));

export const AddBankDetailButton = ({ text, onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT?.BANK_DETAIL_BANK, callback: onClick });

    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <button className='addBankButton' onClick={onAddBtnClick}>
            {text}
        </button>
    );
};
