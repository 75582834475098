import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import SVGElements from 'root/components/SVGElements';
import commonUtil from 'root/utils/common-util';

class LeftSubMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            menuData: {
                S: { path: '/sports', submenu: 'S', key: 'sports', name: 'global:global.menu.sportsbook2', index: 1 },
                ES: { path: '/esports', submenu: 'ES', key: 'esports', name: 'global:global.menu.esport2', index: 2 },
                C: { path: '/casino', submenu: 'C', key: 'casino', name: 'global:global.menu.casino2', index: 3 },
                SL: { path: '/slots', submenu: 'SL', key: 'slots', name: 'global:global.menu.slot2', index: 4 },
                P: { path: '/poker', submenu: 'P', key: 'poker', name: 'global:global.menu.poker2', index: 5 },
                F: { path: '/fishing', submenu: 'F', key: 'fishing', name: 'global:global.menu.fishing2', index: 6 },
                L: { path: '/lottery', submenu: 'L', key: 'lottery', name: 'global:global.menu.lottery2', index: 7 },
                G: { path: '/games', submenu: 'G', key: 'games', name: 'global:global.menu.games2', index: 8 },
                CF: { path: '/cockfight', submenu: 'CF', key: 'cockfight', name: 'global:global.menu.cockfight', index: 9 },
                FD: { path: '/4d', submenu: 'FD', key: 'fourD', name: 'global:global.menu.4d', index: 9 },
                FG: { path: '/fastgames', submenu: 'FG', key: 'fastgames', name: 'global:global.menu.fastgames', index: 10 },
            },
            navList: [],
            getScrollUpBtn: false,
            getScrollDownBtn: false,
            minStart: 0,
            maxEnd: 6,
            getScrollUpDown: false,
        };
    }

    componentDidMount() {
        this.getMenu();
        commonUtil.loadCustomTranslation('leftSubMenu', this, function () {});
    }

    getMenu() {
        const { language, user } = this.props;

        let menuList = [];
        let homeMenu = { path: '/home', key: 'home', name: 'global:global.menu.home2', index: 0 };

        menuList.push(homeMenu);

        window.SPL_Content.getMenu(language.countryLanguageKey, 'mobile').then((data) => {
            // filter out menu is showing after Login
            let menuDataList = [];

            if (!(user && user.isLogin)) {
                menuDataList = data.filter((nav) => {
                    // if didn't set in custom-menu.json then by default no filter
                    if (!nav.showAfterLogin) {
                        return true;
                    }

                    // if set in custom-menu.json then can filter by using ALL or other languages eg.EN_VN
                    if (nav.showAfterLogin.includes('ALL')) {
                        return false;
                    }

                    if (nav.showAfterLogin.includes(language.countryLanguageKey)) {
                        return false;
                    }

                    return true;
                });
            } else {
                menuDataList = data;
            }

            for (let i = 0; i < menuDataList.length; i++) {
                let menu = menuDataList[i].submenu;
                let foundMenu = this.state.menuData[menu];
                if (foundMenu) {
                    menuList.push(foundMenu);
                }
            }

            if (menuList.length > 7) {
                this.setState({ getScrollDownBtn: true });
            } else {
                this.setState({ getScrollDownBtn: false });
            }

            this.setState({ navList: menuList });
        });
    }

    doClick(path, index) {
        const { location } = this.props;

        if (location.pathname !== path) {
            this.props.history.push({ pathname: path, state: { currentIndex: index } });
        }
    }

    getScrollBtn() {
        let scrollBox = document.getElementById('submenu-scroll-box');

        if (scrollBox && scrollBox.offsetHeight + scrollBox.scrollTop + 1 >= scrollBox.scrollHeight) {
            this.setState({ getScrollUpDown: true });
        } else {
            this.setState({ getScrollUpDown: false });
        }
    }

    render() {
        const { history } = this.props;
        const { customTranslationObj } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='box-left'>
                        <div className='submenu-box' id='submenu-scroll-box' onScroll={() => this.getScrollBtn()}>
                            <ul id='item-box'>
                                {this.state.navList.map(({ key, submenu, name, path }, i) => (
                                    <li
                                        id={'sideBar-' + key}
                                        key={key}
                                        className={history.location.pathname === path ? 'on' : ''}
                                        onClick={() => {
                                            if (!window.isAgent && !window.isAffiliate) {
                                                if (window.SPL_LiteApp.isZT828LiteApp()) {
                                                    window.SPL_LiteApp.onMenuCategoryToggle(submenu);
                                                }
                                            }

                                            this.doClick(path, i);
                                        }}
                                    >
                                        <span>{customTranslationObj && customTranslationObj[key] ? customTranslationObj[key] : t(name)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {this.state.getScrollDownBtn && (
                            <li className={`${this.state.getScrollUpDown ? 'arrow-up' : 'arrow-down'}`}>
                                <SVGElements className='icon-arrow-down' name='arrow-down-icon' />
                            </li>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(LeftSubMenu));
