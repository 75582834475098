import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import GameList from 'root/webapp/shared/component/GameList';
import vm from '../../lottery.controller';

let tween1, tween2;

class LotteryGames extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getLotteryProviderList(tween1, tween2, '');
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='provider-games provider-games-lottery'>
                        <div className='container'>
                            <div className='provider-games-items'>
                                <ul className='brand-list'>
                                    {(this.state.brandList || []).map((providerObj, i) => (
                                        <li
                                            key={i}
                                            className={[i == this.state.brandActive ? 'on' : ''].join(' ')}
                                            onClick={() => {
                                                vm.checkUnderMaintenance(providerObj, this.state.brandList[i].underMaintenance, i);
                                            }}
                                        >
                                            {this.state.brandList[i].underMaintenance && <div className='maintenance-hover-box'></div>}
                                            <span className='icon-box'>
                                                <i className={['on', 'icon-brand-lottery-' + providerObj.key].join(' ')}></i>
                                                <i className={['icon-brand-lottery-' + providerObj.key].join(' ')}></i>
                                            </span>
                                            {providerObj.isHotProvider && <div className='hot-provider-fishing slots'></div>}
                                            {providerObj.isNewProvider && <div className='new-provider-fishing slots'></div>}
                                            <span>{t(providerObj.text)}</span>
                                        </li>
                                    ))}
                                </ul>
                                <div className='provider-games-right'>
                                    <div className='type-game-brandList-box'>
                                        {this.state.gameList && this.state.gameList.length > 0 && (
                                            <GameList list={this.state.gameList} updateMemberFavoriteGame={vm.updateMemberFavoriteGame} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'fishing', 'home'])(withRouter(LotteryGames)));
