import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import GameList from 'root/webapp/shared/component/GameList';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import { TweenMax } from 'gsap';
import { DebounceInput } from 'react-debounce-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SVGElements from 'root/components/SVGElements';
import Loading from 'root/webapp/shared/loading/loading';

import vm from '../games-3d.controller';

let tween1, tween2;

class SubGameMenu extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        // if (!this.props.location.query) {
        //     this.props.history.push({ pathname: '/games' });
        //     return;
        // }

        if (window.SPL_LiteApp.isZT828LiteApp()) {
            window.SPL_LiteApp.onGameListDisableRefresh(true);
        }

        vm.getProviderList(tween1, tween2, '');
    }

    componentWillUnmount() {
        if (window.SPL_LiteApp.isZT828LiteApp()) {
            window.SPL_LiteApp.onGameListDisableRefresh(false);
        }
    }

    toggleTab(index) {
        if (index == this.state.typeActive) return;

        this.setState({ typeActive: index });

        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='games-3d-page'>
                        <HomeMemberProfile />
                        <div className='games-3d-box-container'>
                            {this.state.loading && <Loading className='vw-100 vh-100'></Loading>}
                            <div className='gamelist-page'>
                                <div className='tools-box'>
                                    <div className='btn-box'>
                                        <span
                                            className='icon-box'
                                            onClick={() => {
                                                vm.clearGameList();
                                                this.props.history.push('/games');
                                            }}
                                        >
                                            <SVGElements className='icon-back2' name='back-icon' />
                                            {/* <i className="icon-back2"></i> */}
                                        </span>
                                        <span className='icon-box'>
                                            <LazyLoadImage src={'/public/html/images/3d_games/icon/' + this.state.currentProvider.toLowerCase() + '_on.png'} alt='' />
                                        </span>
                                        <span>{this.state.currentProvider && t(vm.providerData[this.state.currentProvider].text)}</span>
                                    </div>
                                    <div className='input-box'>
                                        <DebounceInput
                                            type='text'
                                            debounceTimeout={1000}
                                            values={this.state.keyword}
                                            placeholder={t('global:global.search', 'Search')}
                                            onChange={(e) => {
                                                vm.handleKeywordChanged(e);
                                            }}
                                        />
                                        <i className='icon-search'></i>
                                    </div>
                                </div>
                                <div className='games-3d-games-bottom'>
                                    <div className='type-game-list-box'>
                                        {this.state.gameList && this.state.gameList.length > 0 && (
                                            <GameList list={this.state.gameList} keyword={this.state.keyword} updateMemberFavoriteGame={vm.updateMemberFavoriteGame} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('global')(withRouter(SubGameMenu)));
