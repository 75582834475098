// library
import React, { useRef, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { CSSTransition } from 'react-transition-group';
import { createPortal } from 'react-dom';

// selector / component
import Text from 'root/webapp/components/atoms/text/text';

// controller / util
import providerService from 'root/utils/providerService';
import memberService from 'root/utils/memberService';

class GameList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // list: props.list,
            mobileActive: 0,
            isShowMobileBox: false,
            mobileSelectedGameObj: null,
        };
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    openMobileHoverBox(i, gameObj) {
        if (!document.querySelector('#fixed-container')) return; //非mobile版
        document.body.style.overflow = 'hidden';
        //document.querySelector('#root').style.filter = "blur(1px)";
        //document.querySelector('#fixed-container .tool-bar').style.filter = 'blur(1px)';

        this.setState((state) => ({ ...state, mobileActive: i, isShowMobileBox: true, mobileSelectedGameObj: gameObj }));
    }

    closeMobileHoverBox() {
        if (!document.querySelector('#fixed-container')) return; //非mobile版
        document.body.style.overflow = '';
        //document.querySelector('#root').style.filter = "";
        document.querySelector('#fixed-container .tool-bar').style.filter = '';
        this.setState((state) => ({ ...state, isShowMobileBox: false, mobileSelectedGameObj: null }));
    }

    handleClick(game, isFun) {
        const { history, screen } = this.props;
        const { isShowMobileBox } = this.state;
        let isWeb = screen.viewType === 'web';

        // NOTE: when closing modal have transition, user might clicked play now or demo during close.
        if (isWeb === false && !isShowMobileBox) {
            return;
        }

        if (game && game.underMaintenance) {
            history.push({
                pathname: '/maintenance',
                search: '?provider=' + game.providerMaintenanceName + '&startTime=' + game.underMaintenance.startTime + '&endTime=' + game.underMaintenance.endTime + '',
            });

            document.body.style.overflow = '';
            if (this.props.screen.viewType === 'mobile') {
                document.querySelector('#fixed-container .tool-bar').style.filter = '';
            }
        } else {
            if (this.props.handleCustomClick) {
                this.props.handleCustomClick(game, isFun);
            } else {
                const { language, screen, user } = this.props;
                let gameObj = {
                    provider: game.provider,
                    gameCode: game.gameCode,
                    category: game.category,
                };

                providerService.launchGameFromHomePage(gameObj, language, user.isLogin, screen, isFun, this);
            }
        }
    }

    handleIsShowMobileBoxFunction() {
        const { list } = this.props;
        const { mobileActive } = this.state;
        this.setState({ isShowMobileBox: !list?.[mobileActive]?.isFav });
    }

    render() {
        const { list, searchKeyword, maxGameShow, isFavGame } = this.props;
        const { isShowMobileBox, mobileSelectedGameObj, mobileActive } = this.state;
        // let isWeb = screen.viewType === 'web';

        return (
            <Translation>
                {(t) => (
                    <ul className='game-content'>
                        <GameListContent
                            list={list}
                            searchKeyword={searchKeyword}
                            maxGameShow={maxGameShow}
                            openMobileHoverBox={this.openMobileHoverBox.bind(this)}
                            handleClick={this.handleClick.bind(this)}
                            isFavGame={isFavGame}
                            updateMemberFavoriteGame={(game) => {
                                memberService.updateMemberFavoriteGame(this.props.parentVm, this.props.parentVm.props, game);
                            }}
                        />

                        {document.querySelector('#fixed-container') &&
                            createPortal(
                                <MobileHoverBox
                                    isShowMobileBox={isShowMobileBox}
                                    mobileSelectedGameObj={mobileSelectedGameObj}
                                    handleClick={this.handleClick.bind(this)}
                                    list={list}
                                    mobileActive={mobileActive}
                                    handleIsShowMobileBoxFunction={this.handleIsShowMobileBoxFunction.bind(this)}
                                    closeMobileHoverBox={this.closeMobileHoverBox.bind(this)}
                                    updateMemberFavoriteGame={(game) => {
                                        memberService.updateMemberFavoriteGame(this.props.parentVm, this.props.parentVm.props, game);
                                    }}
                                />,
                                document.querySelector('#fixed-container')
                            )}

                        {/* {this.props.list &&
                            this.props.list
                                .filter((gameObj) => {
                                    if (!this.props.searchKeyword) {
                                        return gameObj;
                                    } else if (gameObj.name.toLowerCase().includes(this.props.searchKeyword.toLowerCase())) {
                                        return gameObj;
                                    }
                                })
                                .map((gameObj, i) =>
                                    // {

                                    //     let isUnderMaintiance = false;
                                    //     if(i < this.props.maxGameShow || (!this.props.maxGameShow && this.props.list.underMaintenance)){
                                    //         isUnderMaintiance = true;
                                    //     }

                                    //     return <GameListContent isUnderMaintiance={isUnderMaintiance}/>
                                    // }
                                    i < this.props.maxGameShow ? (
                                        <li
                                            key={i}
                                            className='force-hover'
                                            onClick={() => {
                                                this.openMobileHoverBox(i, gameObj);
                                            }}
                                        >
                                            <div
                                                className={['img-box', 'icon-game-common'].join(' ')}
                                                style={{ backgroundImage: gameObj.customImage ? `url("${gameObj.thumbnail}")` : `url("${gameObj.imggame}")` }}
                                            >
                                                <div className='hover-box'>
                                                    <span
                                                        style={{ textTransform: 'uppercase' }}
                                                        onClick={() => {
                                                            this.handleClick(gameObj, false);
                                                        }}
                                                    >
                                                        {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                    </span>
                                                    {gameObj.playForFun && (
                                                        <span
                                                            style={{ textTransform: 'uppercase' }}
                                                            onClick={() => {
                                                                this.handleClick(gameObj, true);
                                                            }}
                                                        >
                                                            {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <p>
                                                <span>{t(gameObj.name ? gameObj.name : gameObj.text ? gameObj.text : gameObj.content)}</span>
                                                <i
                                                    className={[this.props.isFavGame ? 'icon-love-on' : gameObj.isFav ? 'icon-love-on' : 'icon-love-off'].join(' ')}
                                                    onClick={() => {
                                                        isWeb && this.props.updateMemberFavoriteGame(gameObj);
                                                    }}
                                                ></i>
                                            </p>
                                        </li>
                                    ) : (
                                        !this.props.maxGameShow && (
                                            <li
                                                key={i}
                                                className='force-hover'
                                                onClick={() => {
                                                    this.openMobileHoverBox(i, gameObj);
                                                }}
                                            >
                                                <div
                                                    className={['img-box', 'icon-game-common'].join(' ')}
                                                    style={{ backgroundImage: gameObj.customImage ? `url("${gameObj.thumbnail}")` : `url("${gameObj.imggame}")` }}
                                                >
                                                    <div className='hover-box'>
                                                        {!this.props.list.underMaintenance && (
                                                            <Fragment>
                                                                <span
                                                                    style={{ textTransform: 'uppercase' }}
                                                                    onClick={() => {
                                                                        this.handleClick(gameObj, false);
                                                                    }}
                                                                >
                                                                    {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                                </span>
                                                                {gameObj.playForFun && (
                                                                    <span
                                                                        style={{ textTransform: 'uppercase' }}
                                                                        onClick={() => {
                                                                            this.handleClick(gameObj, true);
                                                                        }}
                                                                    >
                                                                        {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                                    </span>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </div>
                                                </div>

                                                <p>
                                                    <span>{t(gameObj.name ? gameObj.name : gameObj.text ? gameObj.text : gameObj.content)}</span>
                                                    <i
                                                        className={[this.props.isFavGame ? 'icon-love-on' : gameObj.isFav ? 'icon-love-on' : 'icon-love-off'].join(' ')}
                                                        onClick={() => {
                                                            isWeb && this.props.updateMemberFavoriteGame(gameObj);
                                                        }}
                                                    ></i>
                                                </p>
                                            </li>
                                        )
                                    )
                                )}

                        {document.querySelector('#fixed-container') &&
                            createPortal(
                                <CSSTransition classNames='alert' in={this.state.isShowMobileBox} timeout={500} unmountOnExit>
                                    <div className='mobile-hover-box-wrap'>
                                        <div>
                                            <div
                                                className={['img-box', 'icon-game-common'].join(' ')}
                                                style={{
                                                    backgroundImage: this.state.mobileSelectedGameObj
                                                        ? this.state.mobileSelectedGameObj.customImage
                                                            ? `url("${this.state.mobileSelectedGameObj.thumbnail}")`
                                                            : `url("${this.state.mobileSelectedGameObj.imggame}")`
                                                        : '',
                                                }}
                                            >
                                                <div className='hover-box'>
                                                    <span
                                                        style={{ textTransform: 'uppercase' }}
                                                        onClick={() => {
                                                            this.handleClick(this.state.mobileSelectedGameObj, false);
                                                        }}
                                                    >
                                                        {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                    </span>
                                                    {this.state.mobileSelectedGameObj && this.state.mobileSelectedGameObj.playForFun && (
                                                        <span
                                                            style={{ textTransform: 'uppercase' }}
                                                            onClick={() => {
                                                                this.handleClick(this.state.mobileSelectedGameObj, true);
                                                            }}
                                                        >
                                                            {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <p>
                                                <span className='rctvybunim'>
                                                    {this.props.list[this.state.mobileActive] &&
                                                        (this.props.list[this.state.mobileActive].name
                                                            ? this.props.list[this.state.mobileActive].name
                                                            : this.props.list[this.state.mobileActive].text
                                                            ? this.props.list[this.state.mobileActive].text
                                                            : this.props.list[this.state.mobileActive].content)}
                                                </span>
                                                <i
                                                    className={[
                                                        this.props.list[this.state.mobileActive] && this.props.list[this.state.mobileActive].isFav
                                                            ? 'icon-love-on'
                                                            : 'icon-love-off',
                                                    ].join(' ')}
                                                    onClick={() => {
                                                        this.setState({ isShowMobileBox: !this.props.list[this.state.mobileActive].isFav });
                                                        this.closeMobileHoverBox();
                                                        this.props.updateMemberFavoriteGame(this.props.list[this.state.mobileActive]);
                                                    }}
                                                ></i>
                                            </p>

                                            <i
                                                className='icon-close'
                                                onClick={() => {
                                                    this.closeMobileHoverBox();
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </CSSTransition>,

                                document.querySelector('#fixed-container')
                            )} */}
                    </ul>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['slot', 'global'])(GameList));

const GameListContent = ({ list, searchKeyword, maxGameShow, openMobileHoverBox, handleClick, isFavGame, updateMemberFavoriteGame }) => {
    const screenRedux = useSelector((state) => state.screen);
    const isWeb = screenRedux?.viewType === 'web';

    if (list.length <= 0) {
        return;
    }

    const filterGamesList = list.filter((gameObj) => {
        if (!searchKeyword) {
            return true;
        } else if (gameObj?.name?.toLowerCase().includes(searchKeyword?.toLowerCase())) {
            return true;
        }
        return false;
    });

    return filterGamesList.map((gameObj, i) => {
        const showFilterGamesList = i < maxGameShow || !maxGameShow;
        const isUnderMaintenance = i < maxGameShow || (!maxGameShow && !list?.underMaintenance);
        const gameNameTextKey = gameObj?.name || gameObj?.text || gameObj?.content;
        const isFavGameClassName = isFavGame || gameObj?.isFav ? 'icon-love-on' : 'icon-love-off';
        const backgroundImageStyle = {
            backgroundImage: `url("${gameObj?.customImage ? gameObj?.thumbnail : gameObj?.imggame}")`,
        };

        return (
            <Fragment key={gameObj?.gameCode}>
                {showFilterGamesList && (
                    <li
                        className='force-hover'
                        onClick={() => {
                            openMobileHoverBox(i, gameObj);
                        }}
                    >
                        <div className='img-box icon-game-common' style={backgroundImageStyle}>
                            <div className='hover-box'>
                                {isUnderMaintenance && (
                                    <>
                                        <span
                                            style={{ textTransform: 'uppercase' }}
                                            onClick={() => {
                                                handleClick(gameObj, false);
                                            }}
                                        >
                                            <Text textKey={'slot:slot.playNow'}>Play Now</Text>
                                        </span>
                                        {gameObj?.playForFun && (
                                            <span
                                                style={{ textTransform: 'uppercase' }}
                                                onClick={() => {
                                                    handleClick(gameObj, true);
                                                }}
                                            >
                                                <Text textKey={'slot:slot.demo'}>Demo</Text>
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <p>
                            <span>
                                <Text textKey={gameNameTextKey}>{gameNameTextKey}</Text>
                            </span>
                            <i
                                className={isFavGameClassName}
                                onClick={() => {
                                    isWeb && updateMemberFavoriteGame(gameObj);
                                }}
                            ></i>
                        </p>
                    </li>
                )}
            </Fragment>
        );
    });
};

const MobileHoverBox = ({
    isShowMobileBox,
    mobileSelectedGameObj,
    handleClick,
    list,
    mobileActive,
    handleIsShowMobileBoxFunction,
    closeMobileHoverBox,
    updateMemberFavoriteGame,
}) => {
    const nodeRef = useRef(null);
    const backgroundImageStyle = mobileSelectedGameObj && {
        backgroundImage: mobileSelectedGameObj.customImage ? `url("${mobileSelectedGameObj.thumbnail}")` : `url("${mobileSelectedGameObj.imggame}")`,
    };
    const gameNameTextKey = list?.[mobileActive]?.name || list?.[mobileActive]?.text || list?.[mobileActive]?.content;
    const isFavGameClassName = list?.[mobileActive]?.isFav ? 'icon-love-on' : 'icon-love-off';

    return (
        <div>
            <CSSTransition nodeRef={nodeRef} classNames='alert' in={isShowMobileBox} timeout={500} unmountOnExit>
                <div ref={nodeRef} className='mobile-hover-box-wrap'>
                    <div>
                        <div className='img-box icon-game-common' style={backgroundImageStyle}>
                            <div className='hover-box'>
                                <span
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={() => {
                                        handleClick(mobileSelectedGameObj, false);
                                    }}
                                >
                                    <Text textKey={'slot:slot.playNow'}>Play Now</Text>
                                </span>
                                {mobileSelectedGameObj?.playForFun && (
                                    <span
                                        style={{ textTransform: 'uppercase' }}
                                        onClick={() => {
                                            handleClick(mobileSelectedGameObj, true);
                                        }}
                                    >
                                        <Text textKey={'slot:slot.demo'}>Demo</Text>
                                    </span>
                                )}
                            </div>
                        </div>

                        <p>
                            <span className='rctvybunim'>
                                <Text textKey={gameNameTextKey}>{gameNameTextKey}</Text>
                            </span>
                            <i
                                className={isFavGameClassName}
                                onClick={() => {
                                    handleIsShowMobileBoxFunction();
                                    closeMobileHoverBox();
                                    updateMemberFavoriteGame(list?.[mobileActive]);
                                }}
                            ></i>
                        </p>

                        <i
                            className='icon-close'
                            onClick={() => {
                                closeMobileHoverBox();
                            }}
                        ></i>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};
