import core from 'root/utils/core';
import { languageAction } from 'root/redux/action/language.action';
import commonUtil from 'root/utils/common-util';

const controller = {
    vm: null,
    fullLicenseList: [
        { id: 'bmm', name: 'bmm' },
        { id: 'itechlab', name: 'itechlab' },
        { id: 'global', name: 'global' },
        { id: 'payouts', name: 'payouts' },
        { id: 'godaddy', name: 'godaddy' },
        { id: 'iovation', name: 'iovation' },
    ],

    fullGamingLicenseList: [{ id: 'curacao', name: 'curacao' }],

    fullPaymentMethodList: {
        default: [
            { id: 'bitcoin', name: 'bitcoin' },
            { id: 'usdt', name: 'usdt' },
            { id: 'ethereum', name: 'ethereum' },
            { id: 'help2pay', name: 'help2pay' },
            { id: 'eeziepay', name: 'eeziepay' },
            { id: 'visa', name: 'visa' },
            { id: 'master-card', name: 'master-card' },
            { id: 'duitnow', name: 'duitnow' },
            { id: 'fpx', name: 'fpx' },
            { id: 'paytrust', name: 'paytrust' },
            { id: 'tng', name: 'tng' },
        ],
        VND: [
            { id: 'bank-icon-ftr', name: 'bank-icon-ftr' },
            { id: 'ethereum', name: 'ethereum' },
            { id: 'momo', name: 'momo' },
            { id: 'zalo', name: 'zalo' },
            { id: 'viettelpay', name: 'viettelpay' },
            { id: 'viettel', name: 'viettel' },
            { id: 'vinaphone', name: 'vinaphone' },
            { id: 'mobifone', name: 'mobifone' },
        ],
        THB: [
            { id: 'eeziepay', name: 'eeziepay' },
            { id: 'help2pay', name: 'help2pay' },
            { id: 'bitcoin', name: 'bitcoin' },
            { id: 'usdt', name: 'usdt' },
            { id: 'visa', name: 'visa' },
            { id: 'master-card', name: 'master-card' },
            { id: 'duitnow', name: 'duitnow' },
            { id: 'fpx', name: 'fpx' },
            { id: 'bay', name: 'bay' },
            { id: 'kkr', name: 'kkr' },
            { id: 'ktb', name: 'ktb' },
            { id: 'scb', name: 'scb' },
        ],
        KHUSD: [
            { id: 'advanced-bank', name: 'advanced-bank' },
            { id: 'acleda-bank', name: 'acleda-bank' },
            { id: 'maybank', name: 'maybank' },
            { id: 'wing', name: 'wing' },
            { id: 'true', name: 'true' },
            { id: 'usdt', name: 'usdt' },
            { id: 'eth', name: 'eth' },
            { id: 'bitcoin', name: 'bitcoin' },
        ],
        USD: [
            { id: 'advanced-bank', name: 'advanced-bank' },
            { id: 'acleda-bank', name: 'acleda-bank' },
            { id: 'maybank', name: 'maybank' },
            { id: 'wing', name: 'wing' },
            { id: 'true', name: 'true' },
            { id: 'usdt', name: 'usdt' },
            { id: 'eth', name: 'eth' },
            { id: 'bitcoin', name: 'bitcoin' },
        ],
        PHP: [
            { id: 'malakipay', name: 'malakipay' },
            { id: 'didipay', name: 'didipay' },
            { id: 'jilipay', name: 'jilipay' },
            { id: 'jili88pay', name: 'jili88pay' },
            { id: 'usdt', name: 'usdt' },
            { id: 'eth', name: 'eth' },
            { id: 'bitcoin', name: 'bitcoin' },
        ],
    },

    fullSecurityList: [
        { id: 'iovation', name: 'iovation' },
        { id: 'threat-metrix', name: 'threat-metrix' },
    ],

    fullResponsbileGamingList: [
        { id: '18above', name: '18above' },
        { id: 'gamcare', name: 'gamcare' },
        { id: 'bga', name: 'bga' },
    ],

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            currentLanguage: '',
            categoryList: [],
            mergeLayoutList: [],
            languageList: [],
            appDownloadLinkData: {},
            supportInfoList: {},
            followUsList: {},
            middleItemList: {},
        };
    },

    getAppDownloadLink() {
        commonUtil.getAppDownloadLink(controller.vm);
    },

    getFollowUsList() {
        controller.generateFollowUs().then((data) => {
            if (data) {
                controller.vm.setState({ followUsList: data });
            }
        });
    },

    getSupportInfo() {
        controller.generateSupportAndInfo().then((data) => {
            if (data) {
                controller.vm.setState({ supportInfoList: data });
            }
        });
    },

    generateCategoryList(isMobile) {
        const {
            vm,
            generateSupportAndInfo,
            generateProducts,
            generatePlatform,
            generateLicense,
            generatePaymentMethod,
            generateGamingLicense,
            generateFollowUs,
            generateCopyRight,
            generateSecurity,
            generateResponsibleGaming,
        } = controller;

        const desktopCategoryList = [
            generateGamingLicense(isMobile),
            generateCopyRight(isMobile),
            generateSecurity(isMobile),
            generatePaymentMethod(isMobile),
            generateResponsibleGaming(isMobile),
            generateLicense(isMobile),
        ];
        const mobileCategoryList = [
            generateGamingLicense(isMobile),
            generateLicense(isMobile),
            generateSecurity(isMobile),
            generateResponsibleGaming(isMobile),
            generatePaymentMethod(isMobile),
        ];

        let categoryList = [];
        if (isMobile) {
            categoryList = mobileCategoryList;
        } else {
            categoryList = desktopCategoryList;
        }

        Promise.all(categoryList).then((results) => {
            const mergeLayoutList = isMobile ? results.filter((e) => e.isMobileMergeLayout) : results.filter((e) => e.isDesktopMergeLayout);
            vm.setState({ categoryList: [...results], mergeLayoutList });
        });
    },

    getMiddleContainerItemList(isMobile) {
        const { vm, generateSupportAndInfo, generateFollowUs, generateCopyRight, generateLogo } = controller;

        const desktopCategoryList = [generateSupportAndInfo(isMobile), generateFollowUs(isMobile)];
        const mobileCategoryList = [generateCopyRight(isMobile), generateFollowUs(isMobile), generateLogo(isMobile)];

        let categoryList = [];
        if (isMobile) {
            categoryList = mobileCategoryList;
        } else {
            categoryList = desktopCategoryList;
        }

        Promise.all(categoryList).then((results) => {
            const mergeLayoutList = isMobile ? results.filter((e) => e.isMobileMergeLayout) : results.filter((e) => e.isDesktopMergeLayout);
            vm.setState({ middleItemList: [...results], mergeLayoutList });
        });
    },

    generateCopyRight(isMobile) {
        return new Promise(function (resolve) {
            resolve({
                type: 'text-icon',
                category: 'copy-right',
                isMobileMergeLayout: isMobile,
                isDesktopMergeLayout: !isMobile,
                hideTitle: true,
                list: [
                    {
                        id: 'mobile',
                        text: 'global:global.credits.desc_2',
                        name: '18',
                    },
                ],
            });
        });
    },

    generateSupportAndInfo(isMobile) {
        return new Promise(function (resolve) {
            let list = [
                { id: 'aboutUs', name: 'global:footer.providerList.supportAndInfo.aboutUs' },
                { id: 'openAccount', name: 'global:footer.providerList.supportAndInfo.openAccount' },
                // { id: 'accountTerms', name: 'global:footer.providerList.supportAndInfo.accountTerms' },
                // { id: 'faq', name: 'global:footer.providerList.supportAndInfo.faq' },
                { id: 'termsOfService', name: 'global:footer.providerList.supportAndInfo.termsAndCondition' },
                // { id: 'privacyPolicy', name: 'global:footer.providerList.supportAndInfo.privacyPolicy' },
                { id: 'responsibleGaming', name: 'global:footer.providerList.supportAndInfo.responsibleGaming' },
            ];

            if (controller.vm.props.user.isLogin) {
                list = list.filter((a) => a.id !== 'openAccount');
            }

            resolve({
                type: 'text',
                category: 'supportAndInfo',
                title: 'global:footer.providerList.supportAndInfo.title',
                list: list,
                isDesktopMergeLayout: !isMobile,
                hideTitle: true,
            });
        });
    },

    generatePowerBy(isMobile) {
        return new Promise(function (resolve) {
            resolve({
                type: 'text-icon',
                category: 'power-by',
                isMobileMergeLayout: isMobile,
                isDesktopMergeLayout: !isMobile,
                hideTitle: true,
                list: [
                    {
                        id: 'mobile',
                        text: 'global:footer.power-by',
                        name: 'logo-superswan',
                    },
                ],
            });
        });
    },

    generateLogo(isMobile) {
        return new Promise(function (resolve) {
            resolve({
                type: 'icon',
                category: 'logo-footer',
                isMobileMergeLayout: isMobile,
                isDesktopMergeLayout: !isMobile,
                hideTitle: true,
                list: [{ id: 'logo-footer', name: 'logo-footer' }],
            });
        });
    },

    generateProducts() {
        const { language } = controller.vm.props;
        return new Promise(function (resolve) {
            window.SPL_Content.getMenu(language.countryLanguageKey, 'web').then((data) => {
                let list = [];
                for (let i = 0; i < data.length; i++) {
                    let menuJson = generateMenuJson(data[i]);
                    if (menuJson) {
                        list.push(menuJson);
                    }
                }
                let returnArray = {
                    type: 'text',
                    category: 'products',
                    title: 'global:footer.providerList.products.title',
                    list: list,
                };
                resolve(returnArray);
            });
        });

        function generateMenuJson(data) {
            const dataName = data.name;
            switch (dataName) {
                case 'Sportsbook':
                    return { id: dataName, name: 'global:footer.providerList.products.sports' };
                case 'Casino':
                    return { id: dataName, name: 'global:footer.providerList.products.casino' };
                case 'Slot':
                    return { id: dataName, name: 'global:footer.providerList.products.slots' };
                case 'Fishing':
                    return { id: dataName, name: 'global:footer.providerList.products.fishing' };
                case 'Poker':
                    return { id: dataName, name: 'global:footer.providerList.products.poker' };
                case 'ESportsbook':
                    return { id: dataName, name: 'global:footer.providerList.products.esports' };
                case 'Lottery':
                    return { id: dataName, name: 'global:footer.providerList.products.lottery' };
                case 'Games':
                    return { id: dataName, name: 'global:footer.providerList.products.games' };
                default:
                    return null;
            }
        }
    },

    generatePlatform() {
        return new Promise(function (resolve) {
            resolve({
                type: 'text',
                category: 'platform',
                title: 'global:footer.providerList.platform.title',
                list: [{ id: 'mobile', name: 'global:footer.providerList.platform.mobile' }],
            });
        });
    },

    generateLicense(isMobile) {
        const { language } = controller.vm.props;
        return new Promise(function (resolve) {
            window.SPL_Content.getPortalSettings().then((data) => {
                let list = controller.fullLicenseList;
                if (data && data.features && data.features.hideLicenses && data.features.hideLicenses[language.currencyLang]) {
                    let hideList = data.features.hideLicenses[language.currencyLang];
                    list = list.filter(function (e) {
                        return hideList.indexOf(e.id) < 0;
                    });
                }

                let returnJson = {
                    type: 'icon',
                    category: 'license',
                    title: 'global:footer.providerList.certification.title',
                    list: list,
                    isDesktopMergeLayout: !isMobile,
                };
                resolve(returnJson);
            });
        });
    },

    generateGamingLicense(isMobile) {
        return new Promise(function (resolve) {
            let gamingLicenseText = '';
            const { key } = controller.vm.props.language;
            switch (key) {
                case 'en':
                    gamingLicenseText =
                        'me88 (9 Abraham De Veerstraat Willemstad, Curaçao) is licensed and regulated by the Government of Curaçao.';
                    break;
                case 'km':
                    gamingLicenseText =
                        'me88 (9 Abraham De Veerstraat Willemstad, Curaçao) is licensed and regulated by the Government of Curaçao.';
                    break;
                case 'ms':
                    gamingLicenseText =
                        'me88 (9 Abraham De Veerstraat Willemstad, Curaçao) dilesenkan serta dikawal selia oleh Kerajaan Curaçao.';
                    break;
                case 'th':
                    gamingLicenseText = 'me88 (9 Abraham De Veerstraat Willemstad, Curaçao) is licensed and regulated by the Government of Curaçao.';
                    break;
                case 'tl':
                    gamingLicenseText =
                        'me88 (9 Abraham De Veerstraat Willemstad, Curaçao) is licensed and regulated by the Government of Curaçao.';
                    break;
                case 'vi':
                    gamingLicenseText =
                        'me88 (9 Abraham De Veerstraat Willemstad, Curaçao) is licensed and regulated by the Government of Curaçao.';
                    break;
                case 'zh-CN':
                    gamingLicenseText = 'me88（9 Abraham De Veerstraat Willemstad, Curaçao）由库拉索岛政府许可和监管。';
                    break;
                default:
                    gamingLicenseText =
                        'me88 (9 Abraham De Veerstraat Willemstad, Curaçao) is licensed and regulated by the Government of Curaçao.';
                    break;
            }

            let returnJson = {
                type: 'icon',
                category: 'gamingLicense',
                title: 'global:footer.game-title',
                iconVerified: true,
                list: controller.fullGamingLicenseList,
                isMobileMergeLayout: !isMobile,
                isDesktopMergeLayout: !isMobile,
                text: gamingLicenseText,
            };
            resolve(returnJson);
        });
    },

    generateSecurity(isMobile) {
        return new Promise(function (resolve) {
            let returnJson = {
                type: 'icon',
                category: 'security',
                title: 'global:footer.security.header',
                list: controller.fullSecurityList,
                isMobileMergeLayout: isMobile,
                isDesktopMergeLayout: !isMobile,
            };
            resolve(returnJson);
        });
    },

    generateResponsibleGaming(isMobile) {
        return new Promise(function (resolve) {
            let returnJson = {
                type: 'icon',
                category: 'responsibleGaming',
                title: 'global:footer.providerList.supportAndInfo.responsibleGaming',
                list: controller.fullResponsbileGamingList,
                isMobileMergeLayout: isMobile,
                isDesktopMergeLayout: !isMobile,
            };
            resolve(returnJson);
        });
    },

    generatePaymentMethod(isMobile) {
        return new Promise(function (resolve) {
            const { language } = controller.vm.props;

            window.SPL_Content.getPortalSettings().then((data) => {
                let list = controller.fullPaymentMethodList[language.currencyLang]
                    ? controller.fullPaymentMethodList[language.currencyLang]
                    : controller.fullPaymentMethodList['default'];

                if (data && data.features && data.features.hidePaymentMethod && data.features.hidePaymentMethod[language.currencyLang]) {
                    let hideList = data.features.hidePaymentMethod[language.currencyLang];
                    list = list.filter(function (e) {
                        return hideList.indexOf(e.id) < 0;
                    });
                }

                let returnJson = {
                    type: 'icon',
                    category: 'paymentMethod',
                    title: 'global:footer.providerList.paymentMethod.title',
                    list: list,
                    isDesktopMergeLayout: !isMobile,
                };

                resolve(returnJson);
            });
        });
    },

    generateFollowUs(isMobile) {
        return new Promise(function (resolve) {
            const { language, screen } = controller.vm.props;

            core.getContact(language, screen).then((data) => {
                let list = data.socialMediaList;
                let returnJson = {
                    type: 'icon',
                    category: 'followUs',
                    title: 'global:footer.providerList.followUs.title',
                    list: list,
                    isMobileMergeLayout: isMobile,
                    isDesktopMergeLayout: !isMobile,
                    hideTitle: true,
                };

                resolve(returnJson);
            });
        });
    },

    handleCategoryClick(category, obj) {
        switch (category) {
            case 'supportAndInfo':
                controller.handleSupportAndInfo(obj);
                break;
            case 'products':
                controller.handleProducts(obj);
                break;
            case 'platform':
                controller.handlePlatform(obj);
                break;
            case 'followUs':
                controller.handleFollowUs(obj);
                break;
            case 'gamingLicense':
                controller.handleGamingLicense(obj);
                break;
            default:
                break;
        }
    },

    handleGamingLicense(obj) {
        const { language, portal } = controller.vm.props;
        switch (obj.id) {
            case 'curacao':
                const { curacaoHyperlink } = portal.settings;
                if (curacaoHyperlink && curacaoHyperlink[language.currencyLang]) {
                    window.open(curacaoHyperlink[language.currencyLang]);
                }
                break;
            default:
                break;
        }
    },

    handleFollowUs(contact) {
        let url = contact.url;
        if (url && url !== '') {
            window.open(url);
        }
    },

    handleSupportAndInfo(obj) {
        let langKey = controller.vm.props.language.countryLanguageKey.replace('_', '-').toLowerCase();

        switch (obj.id) {
            case 'aboutUs':
                controller.vm.props.history.push('/aboutus');
                break;

            case 'openAccount':
                controller.vm.props.history.push('/register');
                break;

            case 'termsOfService':
                const { newWindowTerms } = controller.vm.props.portal.settings;

                if (newWindowTerms) {
                    window.open('/' + langKey + '/terms', '', 'width=1025,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
                } else {
                    controller.vm.props.history.push('/terms');
                }
                break;

            case 'faq':
                controller.vm.props.history.push('/faq');
                break;

            case 'responsibleGaming':
                const { newWindowResponseGame } = controller.vm.props.portal.settings;

                if (newWindowResponseGame) {
                    window.open('/' + langKey + '/responsiblegaming', '', 'width=1025,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
                } else {
                    controller.vm.props.history.push('/responsiblegaming');
                }
                break;

            default:
                break;
        }
    },

    handleProducts(obj) {
        switch (obj.id) {
            case 'Sportsbook':
                controller.vm.props.history.push('/sports');
                break;
            case 'Casino':
                controller.vm.props.history.push('/casino');
                break;
            case 'Slot':
                controller.vm.props.history.push('/slots');
                break;
            case 'Fishing':
                controller.vm.props.history.push('/fishing');
                break;
            case 'Poker':
                controller.vm.props.history.push('/poker');
                break;
            case 'ESportsbook':
                controller.vm.props.history.push('/esports');
                break;
            case 'Lottery':
                controller.vm.props.history.push('/lottery');
                break;
            case 'Games':
                controller.vm.props.history.push('/games');
                break;
            default:
                break;
        }
    },

    handlePlatform(obj) {
        switch (obj.id) {
            case 'mobile':
                window.SPL_Other.setViewPort('mobile');
                break;
            default:
                break;
        }
    },
    getLanguageList() {
        window.SPL_Content.getLanguageList().then((data) => {
            let languageList = [];
            for (let i = 0; i < data.length; i++) {
                let languages = data[i].languages;
                for (let j = 0; j < languages.length; j++) {
                    languageList.push(languages[j]);
                }
            }
            let currentLanguage = controller.vm.props.countryLanguageKey;
            controller.vm.setState({ languageList: languageList, currentLanguage: currentLanguage });
        });
    },

    changeLanguage(langObject) {
        core.setLanguageKeyAndCountryLanguage(controller.vm, langObject.countryLanguageKey, languageAction, true, false);
    },
    // add mapStateToProps
    getMapStateToProps(state) {
        return {
            currencyLang: state.language.currencyLang,
            countryLanguageKey: state.language.countryLanguageKey,
            language: state.language,
            screen: state.screen,
        };
    },

    updateCurrentLanguage() {
        let currentLanguage = controller.vm.props.language.countryLanguageKey;
        controller.vm.setState({ currentLanguage: currentLanguage });
    },

    moveDynamicSeoContentToCorrectPosition() {
        controller.moveSeoComponentContentToCorrectPosition('footer');
        controller.moveSeoComponentContentToCorrectPosition('body');
    },

    moveSeoComponentContentToCorrectPosition(param) {
        let seoContent = document.getElementById('seo-' + param + '-container');
        let reactSeoFooterDiv = document.getElementById('dynamic-seo-' + param);
        if (seoContent && reactSeoFooterDiv) {
            let cloneSeoContent = seoContent.cloneNode(true);
            cloneSeoContent.id = 'seo-' + param + '-container';
            reactSeoFooterDiv.appendChild(cloneSeoContent);
            seoContent.parentElement.removeChild(seoContent);
            //controller.updateDynamicSeoContent();
        }
    },

    updateDynamicSeoContent() {
        let seoFooterNew = window.seoFooterNew ? window.seoFooterNew : false;
        let seoFooterList = window.seoFooterList ? window.seoFooterList.split(',') : [];
        let seoBodyNew = window.seoBodyNew ? window.seoBodyNew : false;
        let seoBodyList = window.seoBodyList ? window.seoBodyList.split(',') : [];
        controller.updateSeoContentDisplay('footer', seoFooterNew, seoFooterList);
        controller.updateSeoContentDisplay('body', seoBodyNew, seoBodyList);
    },

    updateSeoContentDisplay(param, seoComponentNew, seoComponentList) {
        const { history, language } = controller.vm.props;
        let seoContent = document.getElementById('seo-' + param + '-container');
        let reactSeoComponentDiv = document.getElementById('dynamic-seo-' + param);

        let route = history.location.pathname;
        if (seoContent && reactSeoComponentDiv) {
            if (route.startsWith('/')) {
                route = route.substring(1, route.length);
            }

            // hide all the seofooterlist first
            for (let i in seoComponentList) {
                let seoComponentListContent = document.getElementById(seoComponentList[i] + '-' + param);
                if (seoComponentListContent) {
                    seoComponentListContent.style.display = 'none';
                }
            }

            //set to empty first
            if (seoContent && reactSeoComponentDiv) {
                seoContent.style.display = 'block';
                seoContent.innerHTML = '';
            }

            let hostname = window.location.hostname;
            if (hostname === 'localhost') {
                hostname = window.tempDomain;
            }

            // display based on url folder
            window.SPL_Content.getSubSeoFooter(hostname, history.location.pathname, language.countryLanguageKey, param).then((data) => {
                if (data.data) {
                    seoContent.insertAdjacentHTML('afterbegin', data.data);
                    setTimeout(function () {
                        if (window.hideFooter) {
                            window.hideFooter();
                        }
                    }, 500);
                }
            });
        }
    },
};

export default controller;
