import Deposit from '../deposit/deposit.selector';
import DepositLanding from '../deposit/deposit-landing/deposit-landing.selector';
import Transfer from '../transfer/transfer.selector';
import Withdraw from '../withdraw/withdraw.selector';
import HistoryLanding from '../history/history-landing.selector';
import Profile from '../profile/profile.selector';
import Password from '../password/password.selector';
import Messaging from '../message/message.selector';
import Bank from '../bank-detail/bank-detail.selector';
import Referral from '../referral/referral.selector';
import Special from '../../../../components/organisms/special-for-you/special-for-you';
import InstantRebate from '../instant-rebate/instant-rebate.selector';
import core from 'root/utils/core';
import { walletAction } from 'root/redux/action/wallet.action';
import { promotionAction } from 'root/redux/action/promotion.action';

import commonUtil from 'root/utils/common-util';
import memberService from 'root/utils/memberService';
import { gu_getSettingFromPortalSettings, gu_getByViewType } from 'root/utils/general-util';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        let date = new Date();
        let dateMonth = date.getMonth() + 1;
        let dateYear = date.getFullYear();
        controller.vm.state = {
            accountMenu: [
                {
                    id: '1',
                    name: 'deposit',
                    path: '/myaccount/deposit',
                    svgIcon: 'deposit',
                    text: 'settings:settings.tabs.heading.deposit',
                    // component: Deposit,
                    // component: features && features.depositPagesSettings && features.depositPagesSettings.useDepositLanding ? DepositLanding : Deposit,
                    component: Deposit,
                    showMenu: true,
                },
                {
                    id: '2',
                    name: 'transfer',
                    path: '/myaccount/transfer',
                    svgIcon: 'transfer',
                    text: 'settings:settings.tabs.heading.transfer',
                    component: Transfer,
                    showMenu: true,
                },
                {
                    id: '3',
                    name: 'withdraw',
                    path: '/myaccount/withdraw',
                    svgIcon: 'withdraw',
                    text: 'settings:settings.tabs.heading.withdraw',
                    component: Withdraw,
                    showMenu: true,
                },
                {
                    id: '4',
                    name: 'history',
                    path: '/myaccount/history',
                    svgIcon: 'history',
                    text: 'settings:settings.tabs.heading.history',
                    component: HistoryLanding,
                    showMenu: true,
                },
                {
                    id: '5',
                    name: '',
                    path: '/myaccount/history/transaction',
                    svgIcon: 'icnprofile',
                    text: 'settings:settings.tabs.heading.history',
                    component: HistoryLanding,
                    showMenu: false,
                },
                {
                    id: '6',
                    name: '',
                    path: '/myaccount/history/promotion',
                    svgIcon: 'icnprofile',
                    text: 'settings:settings.tabs.heading.history',
                    component: HistoryLanding,
                    showMenu: false,
                },
                {
                    id: '7',
                    name: '',
                    path: '/myaccount/history/rebate',
                    svgIcon: 'icnprofile',
                    text: 'settings:settings.tabs.heading.history',
                    component: HistoryLanding,
                    showMenu: false,
                },
                {
                    id: '8',
                    name: '',
                    path: '/myaccount/history/rewards',
                    svgIcon: 'icnprofile',
                    text: 'settings:settings.tabs.heading.history',
                    component: HistoryLanding,
                    showMenu: false,
                },
            ],
            profileMenu: [
                {
                    id: '1',
                    name: 'profile',
                    path: '/myaccount/profile',
                    svgIcon: 'profile',
                    text: 'settings:settings.profiletab.myprofile',
                    component: Profile,
                    showMenu: true,
                },
                {
                    id: '2',
                    name: 'changepassword',
                    path: '/myaccount/password',
                    svgIcon: 'changepassword',
                    text: 'settings:settings.profiletab.pass',
                    component: Password,
                    showMenu: true,
                },
                // { id: '3', name: 'changegamepassword', path: '/myaccount/changegamepassword', svgIcon: 'changegamepassword', text: 'settings:settings.profiletab.providerpass', component: ChangeGamePassword, showMenu: false },
                {
                    id: '4',
                    name: 'messaging',
                    path: '/myaccount/messaging',
                    svgIcon: 'messaging',
                    text: 'settings:settings.tabs.heading.message',
                    component: Messaging,
                    showMenu: true,
                },
                {
                    id: '5',
                    name: 'bankdetails',
                    path: '/myaccount/bankingdetails',
                    svgIcon: 'bankdetail',
                    text: 'settings:settings.profiletab.bank',
                    component: Bank,
                    showMenu: true,
                },
            ],
            mainWalletRefreshing: false,
            mainWallet: null,
            hasFakeSeamless: false,
            disabledSeamless: true,
            refreshing: false,
            totalUnreadMessageCount: 0,
            // leaderboard fields
            fields: {
                dateYear: dateYear,
                dateMonth: dateMonth,
            },
            showPromotionNewTag: false,
        };
    },

    initCompSettings() {
        const { user } = controller.vm.props;
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let leaderboardSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'leaderboardSettings',
            });

            let promotionSettings = gu_getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'promotionSettings',
            });

            let showLeaderboardCurrency = false;

            if (user && user.account && user.account.currency) {
                leaderboardSettings.leaderboardCurrencyShow = leaderboardSettings.leaderboardCurrencyShow.filter((currency) => currency === user.account.currency);
                if (leaderboardSettings.leaderboardCurrencyShow.length > 0) {
                    showLeaderboardCurrency = true;
                }
            }
            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            // Step 3: Initial all into compSettings
            let compSettings = {
                leaderboardCurrencyShow: showLeaderboardCurrency,
                specialForYouEnable: promotionSettings?.specialForYouEnable,
            };

            controller.vm.setState({ compSettings }, () => {
                const { compSettings } = controller.vm.state;

                if (compSettings?.specialForYouEnable) {
                    controller.getAdditionalUserProfileMenu();
                }
                resolve();
            });
        });
    },

    getAdditionalUserProfileMenu() {
        let profileMenu = controller.vm.state.profileMenu;
        const { accountMenu } = controller.vm.state;
        const { user, language } = controller.vm.props;

        let params = {
            languageKey: language?.countryLanguageKey,
            login: user?.account?.login,
            size: 10,
            page: 0,
        };
        controller.vm.props.dispatch(promotionAction.getPromotionData(params));

        let promo = {
            id: '10',
            name: 'specialForYou',
            path: '/myaccount/specialforyou',
            svgIcon: 'side-promos',
            text: 'settings:settings.profiletab.specialForYou',
            component: Special,
            showMenu: true,
        };
        accountMenu.push(promo);

        controller.vm.setState({ profileMenu: profileMenu, accountMenu: accountMenu, referralEnabled: true }, () => {
            controller.getCurrentSelectedTab();
            controller.highlightModule();
        });
    },

    chkUseLanding() {
        const { accountMenu } = controller.vm.state;
        const { features } = controller.vm.props.portal.settings;
        const checkInstantRebate = gu_getByViewType(features?.memberSettings, controller.vm);

        if (features && features.depositPagesSettings && features.depositPagesSettings.useDepositLanding) {
            accountMenu.filter((e) => {
                if (e.name === 'deposit') {
                    e.component = DepositLanding;
                }
            });
        }

        if (checkInstantRebate && checkInstantRebate?.showInstantRebate) {
            let rebate = {
                id: '9',
                name: '',
                path: '/myaccount/rebate',
                svgIcon: 'rebate',
                text: 'settings:settings.tabs.heading.rebate',
                component: InstantRebate,
                showMenu: true,
            };
            accountMenu.push(rebate);

            controller.vm.setState({ accountMenu });
        }
    },

    initOnMount(vm) {
        controller.startMemberMessageUnreadCountTimer(vm);
    },

    highlightModule(pathname = null) {
        if (!pathname) {
            pathname = controller.vm.props.location.pathname;
        }
        let accountMenu = controller.vm.state.accountMenu;
        let profileMenu = controller.vm.state.profileMenu;

        for (let i = 0; i < accountMenu.length; i++) {
            if (pathname.indexOf(accountMenu[i].path) !== -1) {
                accountMenu[i].selected = true;
            } else {
                accountMenu[i].selected = false;
            }
        }
        for (let i = 0; i < profileMenu.length; i++) {
            if (pathname.indexOf(profileMenu[i].path) !== -1) {
                profileMenu[i].selected = true;
            } else {
                profileMenu[i].selected = false;
            }
        }
        controller.vm.setState({ accountMenu, profileMenu });
    },

    moduleClicked(path, index) {
        controller.vm.props.history.push(path);

        controller.highlightModule(path);
    },

    refreshWallet() {
        const { mainWalletRefreshing } = controller.vm.state;
        if (!mainWalletRefreshing) {
            controller.vm.props.dispatch(walletAction.udpateLoadWallet(true));
            memberService.getMainWallet(controller.vm);
        }
    },

    restoreWallet() {
        memberService.restoreWallet(controller.vm, controller.vm.props);
    },

    getUserSeamlessStatus() {
        window.SPL_Member.loadMemberSeamlessStatus().then((res) => {
            controller.vm.setState({
                hasFakeSeamless: res.hasFakeSeamless,
                disabledSeamless: res.isFakeSeamlessDisabled,
            });
        });
    },

    updateUserSeamless() {
        memberService.updateUserSeamless(controller.vm);
    },

    menuClicked(type) {
        if (type === 'livechat') {
            core.openLivechat(controller.vm.props.language.countryLanguageKey, controller.vm.props.screen.viewType);
        } else if (type === 'profile') {
            controller.vm.props.history.push('/myaccount/messaging');
        }
    },

    startMemberMessageUnreadCountTimer(vm) {
        if (controller.vm.props.user.isLogin) {
            controller.getMemberMessageCount(vm);
            let messageUnreadCountTimer = setInterval(() => {
                controller.getMemberMessageCount(vm);
            }, 180000);
            controller.vm.setState({ messageUnreadCountTimer: messageUnreadCountTimer });
        }
    },

    getMemberMessageCount(vm) {
        if (controller.vm.props.user.isLogin) {
            memberService.getMemberMessageCount(vm);
        }
    },

    getWithdrawalLimit() {
        window.SPL_Transaction.getRemainingAvailableWithdrawDetails().then((limit) => {
            controller.vm.setState({ maxAmountDisplay: limit.maxAmountDisplay });
        });
    },

    getMemberGroupLevel() {
        const { account } = controller.vm.props.user;
        const { key } = controller.vm.props.language;
        let lang_key = key.toLowerCase();
        window.SPL_Member.loadMemberGroupAutomationSummary(false, account.currency, account.groupName, account.imagePath, lang_key).then((data) => {
            if (data) {
                let upgradeTotalDeposit = data.upgradeTotalDeposit;
                let totalDeposit = data.totalDeposit;
                let requireDepositToUpgrade = upgradeTotalDeposit - totalDeposit;

                if (requireDepositToUpgrade < 0) {
                    requireDepositToUpgrade = 0;
                }

                controller.vm.setState({ upgradeTotalDeposit: upgradeTotalDeposit, totalDeposit: totalDeposit, requireDepositToUpgrade: requireDepositToUpgrade });
            }
        });
    },

    getAdditionalMenu() {
        window.SPL_Referral.getReferralTypeEnabled().then((data) => {
            if (data && data !== 'error') {
                if (data.referralRewards || data.referralComm) {
                    let profileMenu = controller.vm.state.profileMenu;
                    profileMenu.push({
                        id: '6',
                        name: 'referral',
                        path: '/myaccount/referral',
                        svgIcon: 'referral',
                        text: 'settings:settings.profiletab.referral',
                        component: Referral,
                        showMenu: true,
                    });
                    controller.vm.setState({ profileMenu: profileMenu, profileMenu, referralEnabled: true }, () => {
                        controller.getCurrentSelectedTab();
                        controller.highlightModule();
                    });
                }
            }
        });
    },

    getMobileMenuList() {
        const { location } = controller.vm.props.history;
        const { profileMenu, accountMenu } = controller.vm.state;
        let returnObj = {
            list: [],
            showMenuListLength: 0,
        };
        for (let i = 0; i < profileMenu.length; i++) {
            const menu = profileMenu[i];
            if (menu.path.includes(location.pathname)) {
                returnObj.list = profileMenu;
                returnObj.showMenuListLength = returnObj.list.filter((l) => l.showMenu).length;
                return returnObj;
            }
        }
        for (let i = 0; i < accountMenu.length; i++) {
            returnObj.list = accountMenu;
            returnObj.showMenuListLength = returnObj.list.filter((l) => l.showMenu).length;
            return returnObj;
        }

        return returnObj;
    },

    getCurrentSelectedTab() {
        const { location } = controller.vm.props.history;
        const { profileMenu, accountMenu } = controller.vm.state;
        for (let i = 0; i < profileMenu.length; i++) {
            const menu = profileMenu[i];
            if (menu.path.includes(location.pathname)) {
                controller.vm.setState({ tabSelected: i });
                break;
            }
        }
        for (let i = 0; i < accountMenu.length; i++) {
            const menu = accountMenu[i];
            if (menu.path.includes(location.pathname)) {
                controller.vm.setState({ tabSelected: i });
                break;
            }
        }
    },
};

export default controller;
