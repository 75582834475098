import React, { useState, createContext, useContext, useEffect } from 'react';
import { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Trans, Translation, withTranslation, useTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { DebounceInput } from 'react-debounce-input';
import DatePicker from 'react-datepicker';
import SVGElements from 'root/components/SVGElements';
import vm, {
    handleUsernameValidation,
    handleTelCodeChange,
    handlePhoneValidation,
    handleFullNameOnChange,
    handleCurrencyOnChange,
    handleEmailValidation,
    handlePasswordValidation,
    handleReferralValidation,
    handleStepProceed,
    getCurrencyDescription,
    checkIsExternalRegister,
} from '../register.controller';
import commonUtil from 'root/utils/common-util';
import SliderRecaptchaPopup from 'root/webapp/shared/sliderRecaptcha-popup/sliderRecaptcha-popup.selector';
import LangDisplay from 'root/webapp/shared/component/LangDisplay';
import FooterLiteApp from 'root/webapp/layout/footer/component/mobile/FooterLiteApp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Footer from 'root/webapp/layout/footer/component/mobile/Footer';
import { portalAction } from 'root/redux/action';
import { REG_FIELD } from '../register-constants';

import PasswordInput from 'root/components/molecules/passwordInput/passwordInput';

import TelegramButton from 'root/webapp/portal/telegram/telegram-button';
import Turnstile from 'react-turnstile';

const RegisterContext = createContext();

class RegisterPanel extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings().then(() => {
            vm.initCheckIpDevice();
            vm.processAffiliateOrReferralCodeLogicWithTimeOut();
            vm.initRegisterPageSettings();
            vm.getCountryInfo();
            checkIsExternalRegister();
            // vm.getMemberBenefit();
        });
        
        vm.getBrandLogo();

        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(true);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.portal.affiliateValidated !== this.props.portal.affiliateValidated) {
            vm.processAffiliateOrReferralCodeLogicWithTimeOut();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };

        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(false);
            }
        }
    }

    step1(isLiteApp) {
        const { t } = this.props;
        const { isFormValid, showSpecialCharsErrMsg } = this.state;

        return (
            <Fragment>
                <div className='step1-box'>
                    <form>
                        <UsernameField />

                        <PasswordField showSpecialCharsErrMsg={showSpecialCharsErrMsg} />

                        <div className='input'>
                            <input
                                type='password'
                                debounceTimeout={this.state.debouncedInputMS}
                                values={this.state.fields['confirmPassword']}
                                onChange={(e) => {
                                    vm.handleFieldChanged(e, 'confirmPassword');
                                }}
                            />
                            {!this.state.fields['confirmPassword'] && !this.state.autoFill['confirmPassword'] && (
                                <label className='label-placeholder'>
                                    {t('global:global.form.confirmpassword', 'Confirm Password')} <span>*</span>
                                </label>
                            )}
                            <div className='input-error'>{t(this.state.errors['confirmPassword'])}</div>
                        </div>

                        <ReferralField />

                        <button
                            className='btn-next'
                            onClick={(e) => {
                                e.preventDefault();
                                handleStepProceed(2);
                            }}
                            disabled={!isFormValid}
                        >
                            {t('register:register.form.button', 'REGISTER').toUpperCase()}
                        </button>
                    </form>
                </div>

                <AcknowledgeField getTnC={vm.getTnC} />

                <LoginField />
            </Fragment>
        );
    }

    step2() {
        const { t } = this.props;
        const { showSliderCaptcha } = this.state;

        let SliderRecaptchaPopupProps = {
            showSliderCaptcha: this.state.showSliderCaptcha,
            triggerV3token: vm.triggerV3token,
        };

        return (
            <Fragment>
                <div className='step2-box'>
                    {showSliderCaptcha && <SliderRecaptchaPopup {...SliderRecaptchaPopupProps} />}
                    <form>
                        <FullNameField />

                        <EmailField />

                        <TelCodeField />

                        <CurrencyField />

                        {(this.state.regConfig.hideDob === undefined || !this.state.regConfig.hideDob) && (
                            <div className='input'>
                                <div className='date-container'>
                                    <div className='date-display'>
                                        <select
                                            className='date'
                                            value={this.state.fields['date']}
                                            onChange={(e) => {
                                                vm.handleFieldChanged(e, 'date');
                                            }}
                                        >
                                            {this.state.optionsList['date'].map((option, i) => (
                                                <option key={i} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='month-display'>
                                        <select
                                            className='month'
                                            value={this.state.fields['month']}
                                            onChange={(e) => {
                                                vm.handleFieldChanged(e, 'month');
                                            }}
                                        >
                                            {this.state.optionsList['month'].map((option, i) => (
                                                <option key={i} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <DatePicker
                                        autoComplete='off'
                                        type='text'
                                        showYearPicker
                                        placeholderText={t('transaction:transaction.deposit.cashdeposit.depositdate', 'Deposit Date/Time')}
                                        selectsStart
                                        selected={this.state.fields['dob']}
                                        maxDate={this.state.maxDob}
                                        onChange={(e) => {
                                            vm.handleFieldChanged(e, 'dob');
                                        }}
                                        timeFormat='yyyy'
                                        dateFormat='yyyy'
                                        className='short'
                                        popperPlacement='bottom-end'
                                    />
                                </div>
                                <div className='input-error'>{t(this.state.errors['dob'])}</div>
                            </div>
                        )}

                        <SubmitButtonField />
                    </form>
                </div>

                <AcknowledgeField getTnC={vm.getTnC} />

                <LoginField />
            </Fragment>
        );
    }

    step3(isLiteApp) {
        const { t } = this.props;
        const { brandLogoObj } = this.state;
        return (
            <div className='step3-box'>
                {isLiteApp ? (
                    <img src='/public/html/images/mobile/login/step3-me88-logo.svg' alt='' className='icon-logo2'></img>
                ) : (
                    <i
                        className='icon-logo'
                        style={{
                            backgroundImage: `url(${brandLogoObj && brandLogoObj.brandLogoMobile})`,
                        }}
                    ></i>
                )}
                <div>
                    <p>{t('register:register.congratulation', 'CONGRATULATION!')}</p>
                    <p>{t('register:register.createSuccess', 'Successfuly register as a member', { merchant: window.globMerchantName })}</p>
                </div>
                <span
                    className='btn-next'
                    onClick={() => {
                        vm.redirectToDeposit(0);
                    }}
                >
                    {t('register:register.messages.deposit', 'DEPOSIT NOW')}
                </span>
                <p className='text-p' dangerouslySetInnerHTML={{ __html: t('register:register.regSuccessMsg', { interpolation: { escapeValue: false } }) }}></p>
            </div>
        );
    }

    onFieldChanged = (e, callback) => {
        const { value, name } = e.target;

        this.setState(
            (prevState) => ({ fields: { ...prevState.fields, [name]: value } }),
            () => {
                callback && callback();
            }
        );
    };

    renderContent() {
        let isWeb = this.props.screen.viewType === 'web';
        const passwordMinLimit = this.props.portal?.settings?.passwordMinLimit || 6;
        let memberBenefitImageSrc = `/public/html/images/register/member-benefit-mobile.png`;
        if (isWeb) {
            memberBenefitImageSrc = `/public/html/images/register/member-benefit-web.png`;
        }

        const {
            fields,
            errors,
            regConfig,
            currentTelCodeKey,
            optionsList,
            telCodeDropdownToggle,
            customDropdownToggle,
            autoFill,
            debouncedInputMS,
            referralToggle,
            autoReferralToggle,
            isFormValid,
            disabledButton,
            brandLogoObj,
            isRegisterSuccess,
            active,
            list,
            turnstileValidation
        } = this.state;
        const { USERNAME, PASSWORD, SEARCHKEYWORD, PHONE, FULLNAME, EMAIL, CURRENCY, AFFILIATEID } = REG_FIELD;
        let showMemberBenefitImage = true;
        if (!isWeb && isRegisterSuccess) {
            showMemberBenefitImage = false;
        }

        const registerContextProvider = {
            //usernameField
            username: fields?.[USERNAME],
            usernameErrors: errors?.[USERNAME],
            usernameAutoFill: autoFill?.[USERNAME],

            //passwordField
            password: fields?.[PASSWORD],
            passwordErrors: errors?.[PASSWORD],
            passwordAutoFill: autoFill?.[PASSWORD],
            passwordMinLimit: passwordMinLimit,

            //telcodeField
            phone: fields?.[PHONE],
            phoneErrors: errors?.[PHONE],
            toggleDropdown: vm.toggleDropdown,
            handleTelCodeSearch: vm.handleTelCodeSearch,
            hideContact: regConfig.hideContact,
            iosClass: commonUtil.isIOS() ? 'ios-margin' : '',
            viewType: this.props.screen?.viewType,
            internationalTelcode: this.props.portal.settings.internationalTelcode,
            currentTelCode: fields?.[currentTelCodeKey],
            currentTelCodeKey: currentTelCodeKey,
            currentTelCodeList: optionsList[currentTelCodeKey],
            telCodeDropdownToggle: telCodeDropdownToggle,
            searchKeyWord: fields[SEARCHKEYWORD],

            //FullnameField
            fullname: fields?.[FULLNAME],
            fullnameAutoFill: autoFill?.[FULLNAME],
            fullnameErrors: errors?.[FULLNAME],

            //EmailFIeld
            email: fields?.[EMAIL],
            emailAutoFIll: autoFill?.[EMAIL],
            emailErrors: errors?.[EMAIL],
            registerEmailOptional: regConfig.registerEmailOptional,

            //CurrencyField
            currency: fields?.[CURRENCY],
            optionsList: optionsList[CURRENCY],
            currencyErrors: errors[CURRENCY],
            hideCurrency: regConfig.hideCurrency,
            customDropdownToggle: customDropdownToggle,
            toggleDropdown: vm.toggleDropdown,

            //ReferralField
            affiliateID: fields?.[AFFILIATEID],
            affiliteIDErrors: errors?.[AFFILIATEID],
            debouncedInputMS: debouncedInputMS,
            hideAffiliate: regConfig.hideAffiliate,
            disabledAffiliate: fields?.disabledAffiliate,
            referralToggle: referralToggle,
            autoReferralToggle: autoReferralToggle,
            referralWithoutToggle: regConfig?.referralWithoutToggle,

            //submitButtonField
            disabledButton: disabledButton,
            isFormValid: isFormValid,
            handleSubmit: vm.handleSubmit,

            //AcknowledgeField
            getTnC: vm.getTnC,

            //LoginField
            isWeb: isWeb,

            //registerSuccess
            brandLogoObj: brandLogoObj?.brandLogoMobile,
            redirectToDeposit: vm.redirectToDeposit,
            isRegisterSuccess: isRegisterSuccess,
            //share
            debouncedInputMS: debouncedInputMS,
            onChange: this.onFieldChanged,
            telegramFrameUrl: this.props.portal?.settings?.telegram?.telegramFrameUrl,
            turnstileValidation: turnstileValidation

        };

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='register-panel'>
                            {this.state.ipWhiteList && (
                                <div className='register-box'>
                                    {!isWeb ? (
                                        <div className='title'>
                                            <HeaderTitle oneStepRegistration={regConfig?.oneStepRegistration} active={active} list={list} />

                                            <div
                                                className='icon-close3'
                                                onClick={() => {
                                                    this.props.onClose && this.props.onClose();
                                                }}
                                            ></div>
                                        </div>
                                    ) : (
                                        <div
                                            className='icon-close3'
                                            onClick={() => {
                                                this.props.onClose && this.props.onClose();
                                            }}
                                        ></div>
                                    )}

                                    <div className='content'>
                                        <div className='member-benefit-container'>
                                            {showMemberBenefitImage && <MemberBenefitImage imageSrc={memberBenefitImageSrc} />}
                                            {isWeb && <MemberBenefitContent />}
                                        </div>

                                        <RegisterContext.Provider value={registerContextProvider}>
                                            <div className='step-content' data-index={this.state.active}>
                                                {!this.state.regConfig?.oneStepRegistration && (
                                                    <div className='steps'>
                                                        <ul>
                                                            {this.state.list.map((stepObj, i) => (
                                                                <li key={i} className={i <= this.state.active ? 'on' : ''}>
                                                                    {i + 1}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}

                                                {this.state.isExternalRegister ? (
                                                    <ExternalRegister />
                                                ) : this.state.regConfig?.oneStepRegistration ? (
                                                    <OneStepRegistration showSpecialCharsErrMsg={this.state.showSpecialCharsErrMsg} props={this.props} />
                                                ) : (
                                                    this.state.list.map((item, i) => (
                                                        <CSSTransition key={i} classNames='page' in={this.state.active === i} timeout={300} unmountOnExit>
                                                            {[this.step1(), this.step2(), this.step3()][i]}
                                                        </CSSTransition>
                                                    ))
                                                )}
                                            </div>
                                        </RegisterContext.Provider>

                                        {!isWeb && !isRegisterSuccess && (
                                            <div className='mobile-member-benefit-content-container'>
                                                <MemberBenefitContent />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {!isWeb && this.state.ipWhiteList && (
                            <div className='footer-section'>
                                <Footer />
                            </div>
                        )}
                    </Fragment>
                )}
            </Translation>
        );
    }

    renderLiteAppContent() {
        const { language, history } = this.props;
        const { currentLanguage, languageList } = this.state;
        const { getLanguageList, updateCurrentLanguage, changeLanguage, onLanguageDisplayClick } = vm;
        let langSource = {
            countryLanguage: language,
            currentLanguage: currentLanguage,
            languageList: languageList,
            getLanguageList: getLanguageList,
            updateCurrentLanguage: updateCurrentLanguage,
            changeLanguage: changeLanguage,
            onLanguageDisplayClick,
            history,
        };

        return (
            <Translation>
                {(t) => (
                    <div className='register-panel'>
                        {this.state.ipWhiteList && (
                            <div className='register-box-liteapp'>
                                <div className='title'>
                                    <Fragment>
                                        <img
                                            src='/public/html/images/mobile/back.png'
                                            alt=''
                                            className='back-icon'
                                            onClick={() => {
                                                this.props.onClose && this.props.onClose();
                                            }}
                                        ></img>
                                        <img src='/public/html/images/mobile/login/header-me88-logo.svg' alt='' className='login-logo'></img>
                                        <LangDisplay {...langSource} />
                                    </Fragment>
                                </div>
                                <div className='content-liteapp'>
                                    <div className='step-content'>
                                        {
                                            <div className='step-box'>
                                                <div className='login-header'>
                                                    <div className='login-title'>{t('login:login.liteapp.title')}</div>
                                                    <div className='login-title'>{t('login:login.liteapp.title02')}</div>
                                                    <div className='login-desc'>{t('login:login.liteapp.desc')}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='steps'>
                                        <div className='step-bg' data-index={this.state.active}></div>
                                    </div>
                                    <div className='step-content' data-index={this.state.active}>
                                        {this.state.list.map((item, i) => (
                                            <CSSTransition key={i} classNames='page' in={this.state.active === i} timeout={300} unmountOnExit>
                                                {[this.step1(true), this.step2(true), this.step3(true)][i]}
                                            </CSSTransition>
                                        ))}
                                    </div>
                                </div>

                                <FooterLiteApp />
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }

    render() {
        return this.renderContent();
    }
}

export default connect(mapStateToProps)(withTranslation(['register', 'password', 'global', 'login'])(withRouter(RegisterPanel)));

const RegisterSuccess = ({ isLiteApp = false }) => {
    const { t } = useTranslation();
    const { brandLogoObj, redirectToDeposit } = useContext(RegisterContext);

    return (
        <div className='step3-box'>
            {isLiteApp ? (
                <img src='/public/html/images/mobile/login/step3-me88-logo.svg' alt='' className='icon-logo2'></img>
            ) : (
                <i
                    className='icon-logo'
                    style={{
                        backgroundImage: `url(${brandLogoObj})`,
                    }}
                ></i>
            )}
            <div>
                <p>{t('register:register.congratulation', 'CONGRATULATION!')}</p>
                <p>{t('register:register.createSuccess', { merchant: window.globMerchantName })}</p>
            </div>
            <span
                className='btn-next'
                onClick={() => {
                    redirectToDeposit(0);
                }}
            >
                {t('register:register.messages.deposit', 'DEPOSIT NOW')}
            </span>
            <p className='text-p' dangerouslySetInnerHTML={{ __html: t('register:register.regSuccessMsg', { interpolation: { escapeValue: false } }) }}></p>
        </div>
    );
};

const ExternalRegister = () => {
    const { isRegisterSuccess } = useContext(RegisterContext);
    if (isRegisterSuccess) {
        return <RegisterSuccess />;
    }

    return (
        <form>
            <EmailField />

            <FullNameField />

            <CurrencyField />

            <ReferralField />

            <SubmitButtonField />

            <AcknowledgeField />

            <LoginField />
        </form>
    );
};

const OneStepRegistration = ({ showSpecialCharsErrMsg, ...props}) => {
    const { isRegisterSuccess, telegramFrameUrl } = useContext(RegisterContext);
   
    if (isRegisterSuccess) {
        return <RegisterSuccess />;
    }

    const enabledTurnstile = vm.enabledTurnstile();

    return (
        <>
            {telegramFrameUrl && <TelegramButton action={'register'} />}

            <form>
                <UsernameField />

                <PasswordField showSpecialCharsErrMsg={showSpecialCharsErrMsg} />

                <TelCodeField />

                <FullNameField />

                <EmailField />

                <CurrencyField />

                <ReferralField />

                {enabledTurnstile && <Turnstile
                    theme={'dark'}
                    className="turnstile-wrapper"
                    sitekey={props?.props?.systemFeatureReducer?.siteKey}
                    onVerify={(token) => {
                        vm.handleTurnStileToken(token);
                    }}
                />}

                <SubmitButtonField />

                <AcknowledgeField />

                <LoginField />
            </form>
        </>
    );
};

const UsernameField = () => {
    const { USERNAME } = REG_FIELD;
    const { t } = useTranslation();
    const { username, usernameErrors, usernameAutoFill, debouncedInputMS, onChange } = useContext(RegisterContext);

    return (
        <div className='input'>
            {/** username */}
            <input
                type='text'
                name={USERNAME}
                debounceTimeout={debouncedInputMS}
                values={username}
                onChange={(e) => onChange(e)}
                onBlurCapture={(e) => {
                    handleUsernameValidation();
                }}
            />
            {!username && !usernameAutoFill && (
                <label className='label-placeholder'>
                    {t('global:global.form.username', 'Username')} <span>*</span>
                </label>
            )}
            <div className='input-error'>{t(usernameErrors)}</div>
        </div>
    );
};

const PasswordField = ({ showSpecialCharsErrMsg }) => {
    const { PASSWORD } = REG_FIELD;
    const { t } = useTranslation();
    const { password, passwordErrors, passwordAutoFill, passwordMinLimit, onChange } = useContext(RegisterContext);

    const onPasswordValidationGetErrorMessage = () => {
        const CONSTANT = {
            TICK: 'password-tick',
            CLOSE: 'close',
            SUCCESS: 'text-success',
            ERROR: 'text-danger',
        };

        return (passwordErrors || []).map((params, index) => {
            // If specialCharsFormat is true and showSpecialCharsErrMsg is false, return null
            if (params?.specialCharsFormat && !showSpecialCharsErrMsg) {
                return null;
            }

            let statusImage = params.status ? CONSTANT.TICK : CONSTANT.CLOSE;
            let statusMessage = params.status ? CONSTANT.SUCCESS : CONSTANT.ERROR;
            let errorImage = `/public/html/images/${statusImage}.png`;

            return (
                <div key={`password-rules-msg-${index + 1}`} className={`password-rules-msg-wrapper ${statusMessage}`}>
                    <img alt={''} className={`password-rules-msg-status-img`} src={errorImage} />
                    <p>
                        {t(params.error, {
                            min: params.minLength,
                            max: params.maxLength,
                            specialCharsFormat: params.specialCharsFormat,
                        })}
                    </p>
                </div>
            );
        });
    };

    const passwordErrorsContent = Array.isArray(passwordErrors) ? (
        <div className='input-error password-input-error'>{onPasswordValidationGetErrorMessage()}</div>
    ) : (
        <div className='input-error'>{t(passwordErrors, { min: passwordMinLimit })}</div>
    );

    return (
        <div className='input'>
            <PasswordInput name={PASSWORD} values={password} onFocus={(e) => onChange(e, handlePasswordValidation)} onChange={(e) => onChange(e, handlePasswordValidation)} />
            {!password && !passwordAutoFill && (
                <label className='label-placeholder'>
                    {t('global:global.form.newpassword', 'New Password')} <span>*</span>
                </label>
            )}
            {passwordErrorsContent}
        </div>
    );
};
const TelCodeField = () => {
    const { PHONE } = REG_FIELD;
    const { t } = useTranslation();
    const {
        phone,
        phoneErrors,
        toggleDropdown,
        handleTelCodeSearch,
        onChange,
        hideContact,
        iosClass,
        viewType,
        internationalTelcode,
        currentTelCode,
        currentTelCodeKey,
        currentTelCodeList,
        telCodeDropdownToggle,
        searchKeyWord,
        debouncedInputMS,
    } = useContext(RegisterContext);
    const prefix = viewType === 'mobile' ? 'm-' : '';

    let component = null;
    if (hideContact === undefined || !hideContact) {
        component = (
            <div className='input'>
                <div>
                    <Fragment>
                        <div className={`${prefix}telcode-display`} onClick={() => toggleDropdown()}>
                            {currentTelCode}
                            <CSSTransition classNames='rotate180' in={telCodeDropdownToggle} timeout={300}>
                                <SVGElements className='icon-arrow-down' name='arrow-down-icon' />
                            </CSSTransition>
                        </div>

                        {telCodeDropdownToggle && (
                            <div
                                className={`${prefix}telcode-dropdown-container ${iosClass}`}
                                onMouseLeave={() => {
                                    toggleDropdown();
                                    handleTelCodeSearch('');
                                }}
                            >
                                <DebounceInput
                                    type='text'
                                    debounceTimeout={debouncedInputMS}
                                    value={searchKeyWord}
                                    placeholder={t('global:global.search', 'Search')}
                                    onChange={(e) => {
                                        handleTelCodeSearch(e);
                                    }}
                                    className={`${prefix}telcode-search`}
                                />

                                <div className={`${prefix}telcode-dropdown`}>
                                    {currentTelCodeList.map((option, i) => (
                                        <span
                                            key={i}
                                            className={`${option.availableCountry || !internationalTelcode ? 'regioned' : ''} `}
                                            onClick={(e) => {
                                                handleTelCodeChange(e, currentTelCodeKey);
                                            }}
                                        >
                                            {internationalTelcode ? option.telCode : option}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Fragment>

                    <input
                        name={PHONE}
                        type='text'
                        values={phone}
                        debounceTimeout={debouncedInputMS}
                        placeholder={t('global:global.form.phone', 'Telephone Number')}
                        onFocus={() => {
                            handlePhoneValidation();
                        }}
                        onChange={onChange}
                        onBlurCapture={() => {
                            handlePhoneValidation();
                        }}
                        className='short'
                    />
                </div>
                <div className='input-error'>{t(phoneErrors)}</div>
            </div>
        );
    }
    return component;
};

const FullNameField = () => {
    const { FULLNAME } = REG_FIELD;
    const { t } = useTranslation();
    const [inputHover, setInputHover] = useState(false);
    const { fullname, fullnameAutoFill, fullnameErrors, onChange } = useContext(RegisterContext);

    return (
        <div className='input'>
            <input
                name={FULLNAME}
                type='text'
                values={fullname}
                onChange={(e) => {
                    onChange(e, handleFullNameOnChange);
                }}
                onMouseEnter={() => {
                    setInputHover(true);
                }}
                onMouseLeave={() => {
                    setInputHover(false);
                }}
                onFocus={() => {
                    setInputHover(true);
                    handleFullNameOnChange();
                }}
            />
            {!fullname && !fullnameAutoFill && (
                <label className='label-placeholder'>
                    {t('global:global.form.fullname', 'Full Name')} <span>*</span>
                </label>
            )}

            {inputHover && !fullname && (
                <div className='fullname-remark'>
                    {t(
                        'global:global.form.fullname-placeholderv2',
                        'Please enter your full name. MUST be the same as your bank account holder name or the deposit will be rejected.'
                    )}
                </div>
            )}
            <div className='input-error'>{t(fullnameErrors)}</div>
        </div>
    );
};

const EmailField = () => {
    const { EMAIL } = REG_FIELD;
    const { t } = useTranslation();
    const [inputHover, setInputHover] = useState(false);
    const { email, emailAutoFIll, emailErrors, registerEmailOptional, onChange } = useContext(RegisterContext);

    return (
        <div className='input'>
            <input
                name={EMAIL}
                type='text'
                values={email}
                onChange={(e) => onChange(e)}
                onBlurCapture={() => {
                    handleEmailValidation();
                }}
                onMouseEnter={() => {
                    setInputHover(true);
                }}
                onMouseLeave={() => {
                    setInputHover(false);
                }}
                onFocus={() => {
                    setInputHover(true);
                    handleEmailValidation();
                }}
            />
            {!email && !emailAutoFIll && (
                <label className='label-placeholder'>
                    {t('global:global.form.email', 'Email Address')} {!registerEmailOptional && <span>*</span>}
                </label>
            )}
            {inputHover && !email && (
                <div className='fullname-remark'>
                    {t('global:global.form.email-placeholderv2', 'Please enter a valid email address to receive important messages including password reset')}
                </div>
            )}
            {emailErrors && <div className='input-error'>{t(emailErrors)}</div>}
        </div>
    );
};

const CurrencyField = () => {
    const { CURRENCY } = REG_FIELD;
    const { t } = useTranslation();
    const { hideCurrency, currency, currencyErrors, optionsList, customDropdownToggle, toggleDropdown } = useContext(RegisterContext);
    let component = null;

    if (hideCurrency === undefined || !hideCurrency) {
        component = (
            <div className='input'>
                <div className='currency-display'>
                    <select
                        className='currency'
                        value={currency}
                        onChange={(e) => {
                            handleCurrencyOnChange(e);
                        }}
                        onClick={() => {
                            toggleDropdown(CURRENCY);
                        }}
                    >
                        {optionsList.map((option, i) => (
                            <option key={i} value={option}>
                                {getCurrencyDescription(option)}
                            </option>
                        ))}
                    </select>
                    <CSSTransition classNames='rotate180' in={customDropdownToggle === CURRENCY} timeout={300}>
                        <SVGElements className='icon-arrow-down' name='arrow-down-icon' />
                    </CSSTransition>
                </div>
                <div className='input-error'>{t(currencyErrors)}</div>
            </div>
        );
    }

    return component;
};

const ReferralField = () => {
    const { hideAffiliate, referralToggle, referralWithoutToggle, autoReferralToggle } = useContext(RegisterContext);

    let component = null;

    if (!hideAffiliate) {
        if (referralWithoutToggle) {
            component = <ReferralInputField />;
        } else {
            component = (
                <Fragment>
                    <div className='separator'></div>

                    <div>
                        <div className='input-dropdown-desc' onClick={() => vm.toggleReferralField(referralToggle)}>
                            <Trans i18nKey={'global:global.form.affiliateID-placeholder'}></Trans>
                            <CSSTransition classNames='rotate180' in={referralToggle || autoReferralToggle} timeout={300}>
                                <SVGElements className='icon-arrow-down' name='arrow-down-icon' />
                            </CSSTransition>
                        </div>

                        {(referralToggle || autoReferralToggle) && <ReferralInputField />}
                    </div>
                </Fragment>
            );
        }
    }

    return component;
};

const ReferralInputField = () => {
    const { AFFILIATEID } = REG_FIELD;
    const { t } = useTranslation();
    const { affiliateID, affiliteIDErrors, disabledAffiliate, debouncedInputMS, onChange } = useContext(RegisterContext);

    return (
        <div className='input'>
            <DebounceInput
                name={AFFILIATEID}
                type='text'
                debounceTimeout={debouncedInputMS}
                value={affiliateID}
                onChange={(e) => {
                    onChange(e, () => handleReferralValidation());
                }}
                disabled={disabledAffiliate}
            />
            {!affiliateID && <label className='label-placeholder'>{t('global:global.form.affiliateID-placeholder')}</label>}
            <div className='input-error'>{t(affiliteIDErrors)}</div>
        </div>
    );
};

const SubmitButtonField = () => {
    const { t } = useTranslation();
    const { disabledButton, isFormValid, handleSubmit, turnstileValidation } = useContext(RegisterContext);
    return (
        <button
            className='btn-next btn-submit'
            disabled={disabledButton || !isFormValid || turnstileValidation}
            onClick={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            {t('register:register.form.button', 'REGISTER').toUpperCase()}
        </button>
    );
};

const AcknowledgeField = () => {
    const { getTnC } = useContext(RegisterContext);
    return (
        <p className='text-p'>
            <Trans i18nKey={'register:register.acknowledgement'} components={[<span />, <span onClick={() => getTnC()} />]} />
        </p>
    );
};

const LoginField = () => {
    const { t } = useTranslation();
    const { isWeb } = useContext(RegisterContext);
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <p className='text-p'>
            {t('register:register.alreadyHaveAcc', 'Already have account ?')}{' '}
            <span
                className='login-btn'
                onClick={() => {
                    isWeb ? history.push('/login') : dispatch(portalAction.floatingPageUpdated('login'));
                }}
            >
                {t('global:global.menu.account.login', 'LOGIN')}
            </span>
        </p>
    );
};

const MemberBenefitImage = ({ imageSrc, classNames }) => {
    const { t } = useTranslation();
    return (
        <div className='member-benefit-image-container'>
            <LazyLoadImage className={classNames} src={imageSrc}></LazyLoadImage>
            <div className='image-content-cotainer'>
                {/** temporarily use translation to do, when change SSBO will remove */}
                <span className='image-content-title'>{t('register:register.ambassador.ambassador', 'WELCOME GATITA YAN')}</span>
                <span className='image-content-description'>{t('register:register.ambassador.message', "me88's New Brand Ambassador 2024-27")}</span>
            </div>
        </div>
    );
};

const MemberBenefitContent = () => {
    const [title, setTitle] = useState();
    const [titleDesc, setTitleDesc] = useState();
    const [description, setDescription] = useState();

    const language = useSelector((state) => state?.language);
    const screen = useSelector((state) => state?.screen);
    const currency = language.currencyLang;
    const key = language.key;

    useEffect(() => {
        window.SPL_Content.readMemberBenefit(language.countryLanguageKey, (res) => {
            const data = res?.[screen.viewType];
            if (data) {
                setTitle(data?.title);
                setTitleDesc(data?.['title-desc']);
                setDescription(data?.description);
            }
        });
    }, []);

    useEffect(() => {
        if (title && titleDesc && description) {
            [title, titleDesc, description].map((data) => {
                renderMemberBenefitContent(data);
            });
        }
    }, [title, titleDesc, description]);

    const renderMemberBenefitContent = (item) => {
        if (!item) return null;
        if (Array.isArray(item.content)) {
            item.content.map((data) => {
                if (data?.content?.[currency]) {
                    handleContent(data?.content?.[currency]?.[key] || data?.content?.[currency]?.['en'], data?.style ? data?.style : item?.contentStyle, item?.htmlStyle);
                }
            });
        } else {
            if (item?.content?.[currency]) {
                handleContent(item?.content?.[currency]?.[key] || item?.content?.[currency]?.['en'], item?.style, item?.htmlStyle);
            }
        }
    };

    const handleContent = (content, styles, htmlStyle) => {
        const memberBenefitContentContainer = document.getElementById('member-benefit-content-container');

        if (content.html) {
            generateHTMLContent(memberBenefitContentContainer, content.html, htmlStyle);
        } else {
            generateContent(memberBenefitContentContainer, content, styles);
        }
    };

    const generateContent = (memberBenefitContentContainer, content, styles) => {
        const paragraphElement = document.createElement('p');
        paragraphElement.innerHTML = content;
        //handle content style
        renderContentStyle(paragraphElement, styles);
        memberBenefitContentContainer.appendChild(paragraphElement);
    };

    const generateHTMLContent = (memberBenefitContentContainer, content, styles) => {
        memberBenefitContentContainer.insertAdjacentHTML('beforeend', content);

        //update html style
        if (styles) {
            styles.forEach((style) => {
                if (style?.className?.includes('ul-li-')) {
                    updateListStyle(style);
                }
                const classNameElement = document.getElementsByClassName(style.className);
                if (classNameElement) {
                    renderHtmlContentStyle(classNameElement, style.style);
                }
            });
        }
    };

    // update styles for <ul> <li> in html
    const updateListStyle = (styles) => {
        var styleTag = document.getElementById('ul-li-listStyles');
        if (!styleTag) {
            styleTag = document.createElement('style');
            styleTag.id = 'ul-li-listStyles';
            document.head.appendChild(styleTag);
        }

        const listStyles = styles.style;
        const listMarkerStyles = styles.markerStyle;

        const updateMarkerStyle = (styles) => {
            let css = '';
            for (const style in styles) {
                if (styles.hasOwnProperty(style)) {
                    css += `${style}: ${styles[style]};`;
                }
            }
            return css;
        };

        var css = `#contentMarkerStyle li{${updateMarkerStyle(listStyles)}} li::marker {${updateMarkerStyle(listMarkerStyles)}}`;

        styleTag.innerHTML = css;
    };

    //inject style for <p>
    const renderContentStyle = (content, styles) => {
        if (content) {
            for (var property in styles) {
                if (styles.hasOwnProperty(property)) {
                    content.style[property] = styles[property];
                }
            }
        }
    };

    //inject style for html
    const renderHtmlContentStyle = (content, styles) => {
        if (content.length) {
            for (var property in styles) {
                for (let i = 0; i < content.length; i++) {
                    content[i].style[property] = styles[property];
                }
            }
        }
    };

    return <div id='member-benefit-content-container' className='member-benefit-content-container'></div>;
};

const HeaderTitle = ({ oneStepRegistration, active, list }) => {
    const { t } = useTranslation();
    let title = t('register:register.titleStep', 'Step {{step}} of {{totalStep}}', {
        step: active + 1,
        totalStep: list.length,
    });

    if (oneStepRegistration) {
        title = t('register:register.title', 'Registration');
    }
    return <span>{title}</span>;
};
