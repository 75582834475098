import slotVm from './fast-games.controller';
import { TweenMax } from 'gsap';
import providerService from 'root/utils/providerService';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';
import { gu_getNestedValue } from 'root/utils/general-util';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            currentProvider: '',
            currentProviderKey: '',
            tabFilter: 'HTML5',
            keyword: '',
            brandActive: 0,
            typeActive: 0,
            typeList: [],
            brandList: [],
            gameList: [],
            favoritedGame: [],
            tween1: null,
            tween2: null,
        };
    },

    populateFromSlotLanding(tween1, tween2) {
        return new Promise((resolve) => {
            if (slotVm) {
                const pathname = window.location.pathname;
                const pathArray = pathname.split('/');
                const slotProviderData = gu_getNestedValue(slotVm, 'providerData') || {};

                let currentProvider = gu_getNestedValue(slotVm, 'vm.state.currentProvider') || '';
                let currentProviderKey = gu_getNestedValue(slotVm, 'vm.state.currentProviderKey') || '';

                for (const filterKey of Object.values(slotProviderData)) {
                    const filterRouteName = filterKey.routeName;

                    if (filterRouteName && pathArray.includes(filterRouteName)) {
                        currentProvider = filterKey.provider;
                        currentProviderKey = filterKey.key;
                        break; // Exit the loop early once a match is found
                    }
                }

                controller.vm.setState({ currentProvider: currentProvider, currentProviderKey: currentProviderKey, tween1: tween1, tween2: tween2 }, () => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    },

    getDefaultTab() {
        const { currentProvider } = controller.vm.state;
        // all WhiteLabel required set NS default tab as hot
        if (currentProvider === 'NS') {
            return 'HOT';
        } else {
            return 'HTML5';
        }
    },

    updateTabFilter(type) {
        controller.vm.setState({ tabFilter: type }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    resetProviderFilter() {
        controller.vm.setState({
            tabFilter: 'HTML5',
            keyword: '',
            typeActive: 0,
            typeList: [],
            gameList: [],
        });
    },

    getProviderGameList(provider, tabFilter = 'HTML5', keyword = '', tween1, tween2) {
        const { language } = controller.vm.props;
        const providerCategory = ['All', 'Hot', 'New'];
        let passProvider = provider;

        controller.vm.setState({ gameList: [] });

        return new Promise(async (resolve) => {
            let param = {
                category: 'FAST', //ONLY FOR FASTGAME
                merchantCode: window?.location?.hostname,
                currencyCode: language?.currencyLang,
                isTemplate: false,
                provider: passProvider,
            };

            let data = await window.SPL_Provider.getMerchantGamesFunc(param);

            for (let i = 0; i < data.length; i++) {
                if (data[i].customImage) {
                    data[i].imggame = data[i].thumbnail;
                } else {
                    data[i].imggame = `/public/html/games/images/s1/${provider}/${data[i].game}.jpg`;
                }
                data[i].text = language.key === 'zh-CN' ? data[i].nameCN : data[i].name;
            }
            let displayGame = [];

            if (!controller.vm.state.showMore && data?.length > 25) {
                for (let i = 0; i < 25; i++) {
                    displayGame.push(data?.displayGames[i]);
                }
            } else {
                displayGame = data;
                controller.vm.setState({ showMore: true });
            }

            let brandList = controller.vm.state.brandList;
            if (brandList) {
                for (let j = 0; j < brandList.length; j++) {
                    if (provider === brandList[j].provider) {
                        displayGame.underMaintenance = brandList[j]?.underMaintenance;
                    }
                }
            }

            controller.vm.setState({ typeList: providerCategory, loading: false }, () => {
                controller.getMemberFavoriteGame(displayGame, tween1, tween2);
                resolve();
            });
        });
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const { screen } = controller.vm.props;
        let isMobile = null;

        if (screen.viewType === 'web') {
            isMobile = false;
        } else {
            isMobile = true;
        }

        window.SPL_Member.getMemberFavoriteGame(isMobile).then((data) => {
            let favGameList = data.favoritedGame;

            for (let k = 0; k < favGameList.length; k++) {
                favGameList[k].imggame = `/public/html/games/images/s1/${favGameList[k].provider}/${favGameList[k].game}.jpg`;

                for (let a = 0; a < gameList.length; a++) {
                    if (gameList[a].id === favGameList[k].id) {
                        gameList[a].isFav = true;
                    }
                }
            }

            controller.vm.setState({ favoritedGame: favGameList, gameList: gameList, loading: false }, () => {
                controller.doSuspendAni(tween1, tween2);
            });
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;

            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.5, { alpha: 1 });
        });
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    launchSlotGame(gameObj, isFun) {
        if (gameObj) {
            gameObj.category = 'SL';
        }

        const { language, screen, user } = controller.vm.props;
        let params = {
            domain: window.location.hostname,
            isMobile: screen.isMobile,
            isFun: isFun,
            isMenuMode: !isFun,
            isMiniGame: false,
            languageKey: language.key,
        };

        if (controller.vm.state.provider === 'UL' || gameObj.provider === 'UL') {
            params.gameCode = 'S-UL01';
            params.provider = 'UL';
        } else {
            params.gameCode = gameObj.game;
            params.provider = gameObj.provider;
        }

        let isMobile = false;
        if (screen.viewType === 'mobile') {
            isMobile = true;
        }

        providerService.launchGame(gameObj, language, user.isLogin, screen, isFun, params, controller.vm);
    },
};

export default controller;
