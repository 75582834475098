// library
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// component
import MenuList from '../../molecules/menu-list/menu-list';
import styles from './hamburger-menus.module.scss';
import SVGIcon from '../../molecules/svg-icon/svg-icon';
import Text from '../../atoms/text/text';
import Button from '../../atoms/button/button';

// logic
import { gu_filterCommonKey } from '../../../utils/general-util';
import { userAction } from 'root/redux/action/user.action';

// menus expect to be redux, but due to update to redux efforts are no enuf time in this ticket, so skip first =x
const HamburgerMenus = ({ menus }) => {
    const [isHamburgerMenuHovered, setHamburgerMenuHovered] = useState(false);
    const [commonMenus, setCommonMenus] = useState([]);
    const [highlightMenus, setHighlightMenus] = useState([]);

    const hamburgerContentRef = useRef(null);
    const portalRedux = useSelector((state) => state.portal);
    const userRedux = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!menus || !menus?.length > 0) {
            return;
        }

        if (!highlightMenus.length > 0) {
            processHighlightMenus();
        }

        if (!commonMenus.length > 0) {
            processCommonMenus();
        }
    }, [menus, highlightMenus, commonMenus]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            // Check if the clicked element is not within the content
            if (hamburgerContentRef.current && !hamburgerContentRef.current.contains(event.target)) {
                setHamburgerMenuHovered(false);
            }
        };

        document.body.addEventListener('click', handleDocumentClick);

        return () => {
            document.body.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const processHighlightMenus = () => {
        const customHighlightMenus = gu_filterCommonKey(portalRedux?.settings?.features?.navbarSettings?.highlightMenus);
        const highlightMenus = (menus || []).filter((menu) => (customHighlightMenus || []).includes(menu.name));
        setHighlightMenus(highlightMenus);
    };

    const processCommonMenus = () => {
        const customCommonMenus = gu_filterCommonKey(portalRedux?.settings?.features?.navbarSettings?.commonMenus);
        const commonMenus = (menus || []).filter((menu) => (customCommonMenus || []).includes(menu.name));
        setCommonMenus(commonMenus);
    };

    const onHamburgerMenuIconHover = () => {
        setHamburgerMenuHovered(true);
    };

    const onMenuClickSuccessCallback = () => {
        setHamburgerMenuHovered(false);
    };

    const onAuthButtonClick = () => {
        const isLogin = userRedux?.isLogin;

        if (!isLogin) {
            login();
        } else {
            logout();
        }
        setHamburgerMenuHovered(false);
    };

    const login = () => {
        history.push('/login');
    };

    const logout = () => {
        dispatch(userAction.logout()).then(() => {
            window.location.href = '/home';
        });
    };

    return (
        <>
            <div className={`hamburger-organisms ${styles.hamburgerOrganisms}`}>
                <HamburgerIcon onMouseOver={onHamburgerMenuIconHover} />

                {/* if got enable hamburger menus, these content will show, just using styling hide, due to wanna achieve animation effect */}
                <div ref={hamburgerContentRef} className={`hamburger-content ${styles.hamburgerContentContainer}  ${isHamburgerMenuHovered ? styles.fadeIn : ''}`}>
                    <div className={styles.hamburgerContent}>
                        <div className={`left ${styles.left}`}>
                            <HamburgerMenuList menus={commonMenus} onMenuClickSuccessCallback={onMenuClickSuccessCallback} />
                        </div>
                        <div className={`right ${styles.right}`}>
                            <HamburgerMenuList menus={highlightMenus} onMenuClickSuccessCallback={onMenuClickSuccessCallback} />
                            <div>
                                <Button classes='largeAuthButton' scssClasses='hamburger-auth-button' onClick={onAuthButtonClick}>
                                    <Text textKey={`${userRedux?.isLogin ? 'global:global.menu.account.logout' : 'global:global.menu.account.login'}`}>{`${
                                        userRedux?.isLogin ? 'Logout' : 'Login'
                                    } `}</Text>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HamburgerMenus;

export const HamburgerIcon = ({ ...props }) => {
    return (
        <SVGIcon
            classes={'iconHamburger'}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick}
            src='/public/html/images/common_img/icon-hamburger.svg'
        />
    );
};

export const HamburgerMenuList = ({ menus, onMenuClickSuccessCallback }) => {
    return <MenuList menus={menus} iconPosition='left' icon={{ isSVG: true, directory: `/public/html/images/icon/` }} onClickCallback={onMenuClickSuccessCallback} />;
};
