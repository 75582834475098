// library
import React, { Fragment } from 'react';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';

// selector / component
import providerService from 'root/utils/providerService';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';

// controller / util
import {
    gu_getSettingFromPortalSettings,
    gu_getContentByCurrencyLanguage,
    gu_getFourDResult,
    gu_extractUnusedKeywordsForDrawResults,
    gu_getFourDPayout,
    gu_formatNewDateToCustomFormat,
} from '../../../../utils/general-util';

const controller = {
    vm: null,
    // to map each provider's bg image and animation
    // suspend = initial position before animation
    // after animation need to change at four-d.scss there
    // .four-d-panel > .slider > .slider-container > div > container > .<providerCode>
    providerData: {
        GDL: {
            key: 'gdl',
            provider: 'GDL',
            suspend: [
                { left: 680, bottom: 170, width: 113, height: 119, suspendImg: '/public/html/images/fourD/icon/suspend/1_1.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/fourD/icon/suspend/1_2.png' },
            ],
            text: 'fourD:fourD.gdl.mobile_desc_1',
            mobileText: 'fourD:fourD.gdl.mobile_desc_1',
            bgImg: '/public/html/images/fourD/1.png',
            routeName: 'granddragon',
            maintenance: false,
            providerName: 'global:global.submenu.4d.gdl',
        },
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
            providerCodeList: [],
            isInit: true,
            selectedIndex: null,
            drawResultSettings: '',
            isFetchingDrawResultsData: false,
            drawDate: '',
            compSettings: {},
            drawList: ['drawResult', 'payout'],
            drawListIndex: 0,
            payoutCategoryIndex: 0,
            currentProvider: '',
            currentProviderKey: '',
            providerList: [],
            selectedDrawIndex: 0,
        };
    },

    initCompSettings() {
        return (callback) => {
            // Step 1: Standard way to get from portal settings
            const vm = controller.vm;
            const providerPageSettings = gu_getSettingFromPortalSettings({
                vm,
                settingName: 'providerPageSettings',
                notViewType: true,
                isCurrencyLang: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let fourDPageSettings = gu_getContentByCurrencyLanguage(vm, providerPageSettings.fourD);

            const compSettings = {
                fourDPageSettings,
            };

            vm.setState({ compSettings }, () => {
                callback && callback();
            });
        };
    },

    getFourDProviderList(isMobile = false, providerObj) {
        const { language, t } = controller.vm.props;
        const { currentProvider, currentProviderKey } = controller.vm.state;
        let contentType = 'web';
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        if (isMobile) {
            contentType = 'mobile';
        }

        return new Promise(function (resolve) {
            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, contentType, 'FD', false).then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let count = 0;
                let list = [];
                let fourDData = []; // reset value first
                let providerCodeList = [];
                let tempCurrentProvider = providerObj?.provider || currentProvider;
                let tempCurrentProviderKey = providerObj?.key || currentProviderKey;
                let pathname = window.location.pathname;
                fourDData.length = 0;

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    let foundData = controller.providerData[provider];
                    let disableProvider = submenu[i].isDisableProvider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                submenu[i].gameMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundData) {
                        Object.assign(foundData, { isDisableProvider: disableProvider });

                        // desktop handling
                        if (!isMobile) {
                            foundData.maintenance = submenu[i].gameMaintenance;
                            let bannerElement = generateFourDBannerElement(provider, foundData, count, isHotProvider, isNewProvider);
                            count = count + 1;

                            fourDData.push(foundData);
                            list.push(bannerElement); 
                        } else {
                            // mobile handling
                            foundData.isHotProvider = isHotProvider;
                            foundData.isNewProvider = isNewProvider;
                            foundData.maintenance = submenu[i].gameMaintenance;
                            list.push(foundData);
                        }

                        const isWebConditionProvider = !tempCurrentProvider && !tempCurrentProviderKey && i === 0 && !isMobile;
                        const isMobileConditionProvider = isMobile && pathname.includes(foundData.routeName);

                        if (isWebConditionProvider || isMobileConditionProvider) {
                            tempCurrentProvider = foundData.provider;
                            tempCurrentProviderKey = foundData.key;
                        }

                        providerCodeList.push(provider);
                        controller.vm.setState(
                            { list: list, providerCodeList: providerCodeList, currentProvider: tempCurrentProvider, currentProviderKey: tempCurrentProviderKey },
                            () => {
                                if (tempCurrentProvider) {
                                    controller.getProviderGameList(tempCurrentProvider);
                                }
                            }
                        );
                        resolve(fourDData);
                    }
                }
            });
        });

        function generateFourDBannerElement(provider, data, index, isHotProvider, isNewProvider) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src={data.bgImg} alt=''  className='banner-img'/>
                        {data.blockImg && (
                            <LazyLoadImage
                                key='item-block'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-block'
                                src={data.blockImg}
                            />
                        )}
                        {data.girlImg && (
                            <LazyLoadImage
                                key='item-girl'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-girl'
                                src={data.girlImg}
                            />
                        )}
                        <div className={'container ' + provider}>
                            {data.suspend.map((item, j) => (
                                <div id={`suspend_${index}_${j}`} className={['suspend'].join(' ')} key={`${index + 1}_${j + 2}`}>
                                    <LazyLoadImage src={item.suspendImg} alt='' />
                                </div>
                            ))}
                            <div className='content'>
                                <span className='icon-box'>
                                    <i className={'icon-' + data.key}></i>
                                </span>
                                <span className='desc' dangerouslySetInnerHTML={{ __html: t(data.text, { interpolation: { escapeValue: false } }) }}></span>
                                <span
                                    className={`btn ${data.maintenance ? 'maintenance-disable' : ''}`}
                                    onClick={() => {
                                        controller.launchGame(provider, data.disableProvider, data.maintenance);
                                    }}
                                >
                                    {t('fourD:fourD.playNow', 'PLAY NOW')}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
                tab: (
                    <div className={`${data.isDisableProvider ? 'provider-disable' : ''}`}>
                        <i className={['icon-small-' + data.key, 'gray'].join(' ')}></i>
                        <i className={['icon-small-' + data.key, 'on'].join(' ')}></i>
                        {isHotProvider && <div className='hot-provider-casino'></div>}
                        {isNewProvider && <div className='new-provider-casino'></div>}
                    </div>
                ),
                providerMaintenance: data.maintenance ? <Fragment>{data.maintenance && <div className='maintenance-hover-box'></div>}</Fragment> : null,
            };
        }
    },

    launchGame(provider, disableProvider, maintenance) {
        if (!maintenance) {
            if (disableProvider) {
                controller.vm.props.history.push('/redirect-error');
            } else {
                const { language, screen, user } = controller.vm.props;
                let gameObj = {
                    provider: provider,
                    category: 'FD',
                };
                let extraParam = null;

                providerService.launchGame(gameObj, language, user.isLogin, screen, false, extraParam, controller.vm);
            }
        }
    },

    selectProvider(index) {
        const { providerCodeList, isInit } = controller.vm.state;
        // to prevent 1st time loading to execute 2 times
        // control with isInit flag
        if (isInit) {
            controller.vm.setState({ isInit: false });
        } else {
            let providerCode = providerCodeList[index];
            let foundProvider = controller.providerData[providerCode];
            controller.vm.props.history.push('/fourD/' + foundProvider.routeName);
        }
    },

    selectProviderBasedOnUrl() {
        const { providerCodeList } = controller.vm.state;
        let switchProvider = true;
        if (window.location.pathname.indexOf('/4d') >= 0 && window.location.pathname.indexOf('/4d/') <= -1) {
            switchProvider = false;
        }

        // check current provider based on url,
        // define selectedIndex to makesure Slider component
        // will auto select the provider when 1st time init
        if (switchProvider && providerCodeList.length > 0) {
            for (let i = 0; i < providerCodeList.length; i++) {
                let providerCode = providerCodeList[i];
                let routeName = controller.providerData[providerCode].routeName;

                if (window.location.pathname.indexOf(routeName) >= 0) {
                    controller.vm.setState({ selectedIndex: i });
                    return i;
                }
            }
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        } else {
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        }
    },

    async getDrawResultConfig(callback) {
        try {
            const data = await window.SPL_JsonSettings.getDrawResultConfigJson();
            if (data) {
                controller.vm.setState({ drawResultSettings: data }, () => {
                    callback && callback();
                });
            }
        } catch (err) {
            console.error(err);
        }
    },

    /**
     * if before draw results start, need pass yesterday date as param to receive data
     */
    initDrawResultDateTime() {
        const vm = controller.vm;
        const { drawResultSettings } = vm.state;
        const startTimeInfo = drawResultSettings?.drawResultsConfig?.startTimeInfo || {};
        const { hour, minute, second, millisecond } = startTimeInfo;

        const currentMalaysiaTime = moment().utcOffset('+08:00'); // Get current Malaysia time
        const drawResultsStartTime = moment().utcOffset('+08:00').set({ hour: hour, minute: minute, second: second, millisecond: millisecond });
        let returnDate = null;

        if (currentMalaysiaTime.isBefore(drawResultsStartTime)) {
            // Before 6:55 PM, return the day before
            returnDate = currentMalaysiaTime.subtract(1, 'day');
        } else {
            // After 6:55 PM, return today's date
            returnDate = currentMalaysiaTime;
        }

        return returnDate;
    },

    /**
     * Every second, will check if the draw results is ready to start
     * will clear the timer once draw results is ready
     * TODO: moved to functional component inside, so can reusable for both desktop and mobile
     */
    checkTimeAndTriggerGetFourDResults() {
        const vm = controller.vm;
        const { drawResultSettings } = vm.state;
        const drawResultsConfig = drawResultSettings?.drawResultsConfig;
        const startTimeInfo = drawResultsConfig?.startTimeInfo || {};
        const endTimeInfo = drawResultsConfig?.endTimeInfo || {};

        const malaysiaTime = moment().utcOffset('+08:00');
        const drawResultsStartTriggerTime = moment()
            .utcOffset('+08:00')
            .set({ hour: startTimeInfo?.hour, minute: startTimeInfo?.minute, second: startTimeInfo?.second, millisecond: startTimeInfo?.millisecond });
        const drawResultsTriggerEndTime = moment()
            .utcOffset('+08:00')
            .set({ hour: endTimeInfo?.hour, minute: endTimeInfo?.minute, second: endTimeInfo?.second, millisecond: startTimeInfo?.millisecond });
        const isDrawResultsEnded = malaysiaTime > drawResultsTriggerEndTime;

        vm.setState({ isAutoFetchIntervalTriggered: false, drawResultsTriggerEndTime: drawResultsTriggerEndTime }, () => {
            const intervalId = setInterval(() => {
                if (isDrawResultsEnded) {
                    clearInterval(intervalId);
                    return;
                }

                // if draw results haven't end then proceed
                if (malaysiaTime >= drawResultsStartTriggerTime) {
                    clearInterval(intervalId);
                    this.setState({ isAutoFetchIntervalTriggered: true });
                } else {
                    return;
                }
            }, 1000);
        });
    },

    /**
     * retrieve 4d draw results data
     * @param {Date} date -- e.g. new Date()
     * @returns
     */
    getFourDResults(date) {
        const vm = controller.vm;
        const { isFetchingDrawResultsData, drawResultSettings, isAutoFetchIntervalTriggered } = vm.state;
        const endTimeInfo = drawResultSettings?.drawResultsConfig?.endTimeInfo || {};
        const { hour, minute, second, millisecond } = endTimeInfo;
        const drawResultsEndTime = moment().utcOffset('+08:00').set({ hour: hour, minute: minute, second: second, millisecond: millisecond });
        // got interval timer trigger function, hence is fetching then skip to prevent duplicate call
        if (isFetchingDrawResultsData) {
            return;
        }

        vm.setState({ isFetchingDrawResultsData: true, drawDate: date }, () => {
            const drawEndTime = isAutoFetchIntervalTriggered ? '' : new Date(drawResultsEndTime); //to custom custom time stamp date
            const intervalRoundingUnit = drawResultSettings?.drawResultsConfig?.intervalRoundingUnit; //to custom the rounding unit
            const drawData = gu_getFourDResult(date, drawEndTime, intervalRoundingUnit);

            const regexToExtract = /{(.|[\r\n])*}/;
            const fourDList = gu_extractUnusedKeywordsForDrawResults(drawData, regexToExtract);
            let updatedLotteryList = '';

            //purpose of this is GDLotto(6D) & GrandDragon(4D) need combine tgt
            if (fourDList) {
                if (fourDList?.results['GDLotto']) {
                    fourDList.results['GrandDragon'] = {
                        ...fourDList.results['GrandDragon'],
                        ...fourDList.results['GDLotto'],
                    };
                }
                updatedLotteryList = Object.fromEntries(Object.entries(fourDList?.results).filter(([key]) => key !== 'GDLotto'));
            }
            let mergedResult = controller.mergeData(updatedLotteryList, drawResultSettings?.fourDResultSequence);

            vm.setState({ fourDList: mergedResult, isFetchingDrawResultsData: false });
        });
    },

    mergeData(source, target) {
        const merged = { ...target }; // Create a new object to hold the merged data

        Object.keys(source).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                if (typeof source[key] === 'object' && source[key] !== null) {
                    // Check if the key exists in the target and is an object
                    if (!merged[key]) {
                        // If the key doesn't exist in the merged object, create a new object or array
                        merged[key] = Array.isArray(source[key]) ? [] : {};
                    }
                    // Recursively merge nested objects or arrays
                    merged[key] = controller.mergeData(source[key], merged[key]);
                } else {
                    // If the value is not '---', update the merged object
                    if (source[key] !== '---') {
                        merged[key] = source[key];
                    }
                }
            }
        });

        return merged; // Return the new merged object
    },

    // Payout
    // 1.Just get the date from cms
    getPayoutHistory() {
        const { language } = controller.vm.props;
        try {
            const payoutList = JSON.parse(gu_getFourDPayout(language.currencyLang, controller.vm.props));
            controller.vm.setState({ payoutList: payoutList?.response });
        } catch (err) {
            return;
        }
    },
    //4d logic end

    /**
     * once received flag, then will start auto fetch latest file data by interval time
     * stop upon draw results ended
     */
    autoTriggerGetFourDResults() {
        const vm = controller.vm;
        const { drawResultSettings } = vm.state;
        const drawResultsConfig = drawResultSettings?.drawResultsConfig;
        const endTimeInfo = drawResultsConfig?.endTimeInfo || {};
        const { hour, minute, second, millisecond } = endTimeInfo;
        const drawResultsTriggerEndTime = moment().utcOffset('+08:00').set({ hour: hour, minute: minute, second: second, millisecond: millisecond });
        const intervalSecondsFetchAPI = drawResultsConfig?.intervalSecondsFetchAPI * 1000;

        const stopAutoIntervalTriggerDrawResults = () => {
            vm.setState({ isAutoFetchIntervalTriggered: false });
            clearInterval(intervalId);
        };

        // Set up an interval to check the time every minute
        const intervalId = setInterval(() => {
            const { drawDate } = vm.state;
            const malaysiaTime = moment().utcOffset('+08:00');
            const selectedDate = gu_formatNewDateToCustomFormat(drawDate, 'YYYY-MM-DD');
            const isDrawResultsEnded = malaysiaTime > drawResultsTriggerEndTime;
            if (isDrawResultsEnded) {
                stopAutoIntervalTriggerDrawResults();
            }

            //this purpose is to pause trigger api when user select other date
            if (malaysiaTime.format('YYYY-MM-DD') !== selectedDate) {
                clearInterval(intervalId);
                this.setState({ isAutoFetchIntervalTriggered: false });
            }

            // Check if the current time is less than the specified time
            if (malaysiaTime.isBefore(drawResultsTriggerEndTime)) {
                vm.getDrawResultConfig(() => {
                    vm.getFourDResults(drawDate);
                });
            } else {
                stopAutoIntervalTriggerDrawResults();
            }
        }, intervalSecondsFetchAPI); // Interval set to 30seconds due to API every 30seconds update (adjust as needed)
    },

    //4d logic start
    updateSelectedDrawDate(date, callback) {
        controller.vm.setState({ drawDate: date }, () => {
            callback && callback();
        });
    },

    onDrawDateChange(date) {
        const vm = controller.vm;

        controller.updateSelectedDrawDate(date, () => {
            controller.getDrawResultConfig(() => {
                controller.getFourDResults(vm.state.drawDate);
            });
            controller.autoTriggerGetFourDResults();
        });
    },

    onDrawMenuClicked(index) {
        const vm = controller.vm;

        vm.setState({ drawListIndex: index });
    },

    updateSelectedProvider(providerObj) {
        controller.vm.setState({ currentProvider: providerObj.provider, currentProviderKey: providerObj.key }, () => {
            controller.getFourDProviderList(true, providerObj);
        });
    },

    getProviderGameList(provider) {
        const { language } = controller.vm.props;
        const { keyword } = controller.vm.state;
        let domain = '',
            gameList = [],
            currencyLang = language.currencyLang;

        if (window.location.hostname === 'localhost') {
            // local use
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let param = {
            category: 'LOTTERY',
            merchantCode: domain,
            currencyCode: currencyLang,
            isTemplate: false,
        };

        param.provider = provider;
        window.SPL_Provider.getMerchantGames(param, success, fail);

        function success(data) {
            for (let i = 0; i < data.length; i++) {
                data[i].imggame = `/public/html/games/images/s1/${provider}/${data[i].game}.jpg`;
                data[i].text = data[i].name;
                data[i].gameCode = data[i].game;
                data[i].category = 'L';

                if (keyword) {
                    if (data[i].name.toLowerCase().includes(keyword.toLowerCase())) {
                        gameList.push(data[i]);
                    }
                } else {
                    gameList.push(data[i]);
                }
            }

            controller.arrangeSeq(gameList);
        }

        // let brandList = controller.vm.state.brandList;
        // for (let j = 0; j < brandList.length; j++) {
        //     if (provider === brandList[j].provider) {
        //         gameList.underMaintenance = brandList[j].underMaintenance;
        //     }
        // }

        function fail(data) {
            gameList = null;
            controller.vm.setState({ gameList: gameList });
        }
    },

    arrangeSeq(gameList) {
        const { currentProvider, tween1, tween2 } = controller.vm.state;

        window.SPL_Content.getGamesArrangementList(currentProvider).then((json) => {
            if (json) {
                if (json[currentProvider] && json[currentProvider]['desktop']['Arrangement']) {
                    let gamesArrangement = json[currentProvider]['desktop']['Arrangement'];

                    gameList.forEach(function (e) {
                        e.seq += 100;
                        for (var i = 0; i < gamesArrangement.length; i++) {
                            if (e.game == gamesArrangement[i].game) {
                                e.seq = parseInt(gamesArrangement[i].seq);
                            }
                        }
                    });

                    gameList.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
            }

            controller.getMemberFavoriteGame(gameList, tween1, tween2);
        });
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const { screen } = controller.vm.props;
        let isMobile = null;

        if (screen.viewType === 'web') {
            isMobile = false;
        } else {
            isMobile = true;
        }

        window.SPL_Member.getMemberFavoriteGame(isMobile).then((data) => {
            let favGame = data.favoritedGame;
            let favGameList = data.favoritedGame.map((game) => game.id);
            let newGameList = [...gameList];
            for (let a = 0; a < gameList.length; a++) {
                if (favGameList.indexOf(gameList[a].id) >= 0) {
                    newGameList[a].isFav = true;
                } else {
                    newGameList[a].isFav = false;
                }
            }
            controller.vm.setState({ favoritedGame: favGame, gameList: newGameList, loading: false }, () => {
                controller.doSuspendAni(tween1, tween2);
            });
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { gameList, favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;
            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let deleteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        deleteGame = true;
                    }
                }

                if (deleteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    },

    onPayoutMenuClicked(index) {
        const vm = controller.vm;

        vm.setState({ payoutCategoryIndex: index });
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { tween1, tween2, currentProvider, currentProviderKey } = controller.vm.state;
            controller.getFourDProviderList(tween1, tween2, currentProvider, currentProviderKey);
        });
    },
};

export default controller;
