// library
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Text from 'root/webapp/components/atoms/text/text';
import styles from './special-for-you.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import SVGTransfer from 'root/components/svgIcon/svgTransfer';
import { MdClose } from 'react-icons/md';
// import SmallLoading from 'root/webapp/shared/loading/smallLoading';
// import { CSSTransition } from 'react-transition-group';
// import SVGElements from 'root/components/SVGElements';
// import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import { useTranslation, withTranslation } from 'react-i18next';
// import notification from 'root/utils/notification';
// import SVGProvider from 'root/components/svgIcon/svgProvider';
import { promotionAction } from '../../../redux/action/promotion.action';
import Button from '../../atoms/button/button';
import PromotionTransfer from '../promotion-transfer/promotion-transfer';

const SpecialForYou = () => {
    // const { t } = useTranslation(['promotion', 'popupTransfer']);
    const [claimTabHeader, setClaimTabHeader] = useState('unclaimed');
    const [totalCount, setTotalCount] = useState(10);
    const [promotionData, setPromotionData] = useState(null);
    const [claimedPromotionData, setClaimedPromotion] = useState(null);
    const [expiredPromotionData, setExpiredPromotion] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const languageRedux = useSelector((state) => state.language);
    const userRedux = useSelector((state) => state.user);
    const promotionRedux = useSelector((state) => state.promotionReducer?.promotionData);
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);

    const totalPages = Math.ceil(totalCount / 10);

    const onClaimTabHeaderClicked = (claimTitle) => {
        setClaimTabHeader(claimTitle);
        setCurrentPage(0);
        setPage(1);
        setTotalCount(null);
    };

    function triggerTabFunction() {
        const tab = tabHeaders.find((tab) => tab.key === claimTabHeader);
        if (tab && tab.tabHeaders) {
            tab.tabHeaders();
        }
    }

    function getUnclaimedPromotionData() {
        try {
            let promotion = promotionRedux?.promotionData;
            let totalCount = promotion?.headers['x-total-count'];

            setPromotionData(promotion?.data);
            setTotalCount(totalCount);
        } catch (err) {
            console.error(err);
        }
    }

    async function getClaimedPromotionData() {
        try {
            let params = {
                languageKey: languageRedux?.countryLanguageKey,
                login: userRedux?.account?.login,
                size: 10,
                page: currentPage,
            };
            const claimedData = await window.SPL_Member.getClaimedPromotionData(params);
            setClaimedPromotion(claimedData?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getExpiredPromotionData() {
        try {
            let params = {
                languageKey: languageRedux?.countryLanguageKey,
                login: userRedux?.account?.login,
                size: 10,
                page: currentPage,
            };
            const expiredDate = await window.SPL_Member.getExpiredPromotionData(params);
            setExpiredPromotion(expiredDate?.data);
        } catch (err) {
            console.error(err);
        }
    }

    function updatePromotionData() {
        let params = {
            languageKey: languageRedux?.countryLanguageKey,
            login: userRedux?.account?.login,
            size: 10,
            page: currentPage,
        };
        dispatch(promotionAction.getPromotionData(params));
    }

    //when go into the page will trigger api
    //when click the promotion apply or info will trigger api also becz of checking the new icon
    useEffect(() => {
        getUnclaimedPromotionData();
    }, [promotionRedux]);

    //only currentPage change data will trigger the api again
    useEffect(() => {
        triggerTabFunction();
    }, [currentPage]);

    useEffect(() => {
        triggerTabFunction();
    }, [claimTabHeader]);

    const tabHeaders = [
        { title: <Text textKey={'promotion:promotion.unclaimed'} />, key: 'unclaimed', tabHeaders: () => updatePromotionData() },
        { title: <Text textKey={'promotion:promotion.claimed'} />, key: 'claimed', tabHeaders: () => getClaimedPromotionData() },
        { title: <Text textKey={'promotion:promotion.expired'} />, key: 'expired', tabHeaders: () => getExpiredPromotionData() },
    ];

    const renderTabContent = () => {
        switch (claimTabHeader) {
            case 'unclaimed':
                return <Promotion promotionData={promotionData} updatePromotionData={updatePromotionData} />;
            case 'claimed':
                return <Promotion promotionData={claimedPromotionData} disabledApplyAndCondition={true} />;
            case 'expired':
                return <Promotion promotionData={expiredPromotionData} disabledApplyAndCondition={true} />;
            default:
                return null;
        }
    };

    const onPageChange = (page) => {
        setCurrentPage(page - 1);
    };

    const handlePageChange = (page) => {
        setPage(page);
        onPageChange(page);
    };

    return (
        <>
            <div className={`special-for-you-container ${styles.specialForYouContainer}`}>
                <div className={`tab-list ${styles.tabList}`}>
                    {(tabHeaders || []).map(({ title, key }) => {
                        return <TabHeader key={key} title={title} active={claimTabHeader === key} onClick={() => onClaimTabHeaderClicked(key)} />;
                    })}
                </div>
                {renderTabContent()}
                {totalCount > 10 && (
                    <Pagination
                        totalItems={totalCount}
                        totalPages={totalPages}
                        page={page}
                        itemsPerPage={10}
                        handlePageChange={handlePageChange}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
        </>
    );
};

export default withTranslation(['promotion', 'popupTransfer', 'special-for-you'])(SpecialForYou);

const Pagination = ({ totalItems, itemsPerPage, totalPages, page, handlePageChange, onPageChange }) => {
    // const [page, setPage] = useState(1);
    const { t } = useTranslation('global');

    return (
        <div className={`${styles.paginationContainer}`}>
            <span>{t('global:global.item-count', { first: (page - 1) * itemsPerPage + 1, second: Math.min(page * itemsPerPage, totalItems), total: totalItems })}</span>
            <div className={`${styles.paginationPage}`}>
                {page > 1 && (
                    <Button onClick={() => handlePageChange(page - 1)}>
                        <Text textKey={'special-for-you:pagination.previous'}>Previous</Text>
                    </Button>
                )}
                <PaginationNumber currentPage={page} totalPages={totalPages} handlePageChange={handlePageChange} />
                {page < totalPages && (
                    <Button onClick={() => handlePageChange(page + 1)}>
                        <Text textKey={'special-for-you:pagination.next'}>Next</Text>
                    </Button>
                )}
            </div>
        </div>
    );
};

export const PaginationNumber = ({ currentPage, totalPages, handlePageChange }) => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
        pages.push(
            <button key={i} onClick={() => handlePageChange(i)} disabled={i === currentPage}>
                {i}
            </button>
        );
    }
    return pages;
};

export const TabHeader = ({ title, active, onClick }) => {
    return (
        <div className={`tab-header ${styles.tabHeader} ${active && styles.active}`} onClick={onClick}>
            {title}
        </div>
    );
};

export const RemainingTime = ({ validTill }) => {
    const [remainingDays, setRemainingDays] = useState(0);
    const [remainingHours, setRemainingHours] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentDate = new Date();
            const validTillDate = new Date(validTill);

            const timeDifference = validTillDate.getTime() - currentDate.getTime();

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

            setRemainingDays(days);
            setRemainingHours(hours);
        }, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup function
    }, [validTill]);

    return (
        <div>
            <p>
                {remainingDays}
                <Text textKey={'promotion:promotion.days'}>day(s)</Text> {remainingHours}
                <Text textKey={'promotion:promotion.hours'}> hour(s)</Text>
            </p>
        </div>
    );
};

export const Promotion = ({ promotionData, disabledApplyAndCondition, updatePromotionData }) => {
    const [enableApplyButton, setEnableApplyButton] = useState(false);
    const [enablePromotionContent, setEnablePromotionContent] = useState(false);
    const [applyDetails, setApplyDetails] = useState();
    const [filteredProviderData, setFilteredProviderData] = useState(null);

    const languageRedux = useSelector((state) => state.language);

    const onApplyClicked = async (details) => {
        if (details) {
            // Run all the necessary functions to set details asynchronously
            await Promise.all([checkNewTagAvailable(details), setApplyDetails(details), categoriseProviderByMoreType(details)]);
        }

        // Now that all details have been set, enable the apply button
        setEnableApplyButton(!enableApplyButton);
        setBackgroundOverflow(!enableApplyButton);
    };

    const setBackgroundOverflow = (popupOpen) => {
        if (popupOpen) {
            document.body.classList.add('popup-open');
        } else {
            document.body.classList.remove('popup-open');
        }
    };

    const onShowContentClick = async (details) => {
        if (details) {
            // Run all the necessary functions to set details asynchronously
            await Promise.all([checkNewTagAvailable(details), setApplyDetails(details), categoriseProviderByMoreType(details)]);
        }

        // Now that all details have been set, enable the apply button
        setEnablePromotionContent(!enablePromotionContent);
        setBackgroundOverflow(!enablePromotionContent);
    };

    async function checkNewTagAvailable(details) {
        if (details?.isNewTag) {
            await window.SPL_Member.savePromotionViewLog(details);
            updatePromotionData();
        }
    }

    function categoriseProviderByMoreType(details) {
        const providerCode = details?.providerLists.split(',');
        const name = details?.providerNameLists.split(',');

        // Merging the two arrays into an array of objects
        const mergedProviderData = providerCode?.map((code, index) => ({
            provider: code,
            name: name[index],
        }));

        window.SPL_Provider.categoriseProviderByMoreType(mergedProviderData, languageRedux.currencyLang).then((categorizedProviderListByMoreType) => {
            const filteredData = categorizedProviderListByMoreType.filter((item) => item.list.length > 0);
            setFilteredProviderData(filteredData);
        });
    }

    return (
        <section>
            {!promotionData || promotionData.length === 0 ? (
                <div className={`${styles.noPromotionYet}`}>
                    <span>
                        <Text textKey={'promotion:promotion.noPromotionYet'}>No Promotion Yet</Text>
                    </span>
                </div>
            ) : (
                <>
                    {(promotionData || []).map((details) => {
                        const { id, bonusName, validTill, isNewTag } = details;
                        return (
                            <div key={id} className={`unclaimed-container ${styles.unclaimedContainer}`}>
                                <div className={`unclaimed-details-container ${styles.unclaimedDetailsContainer}`}>
                                    <div>
                                        <div className={`unclaimed-name ${styles.unclaimedName}`} onClick={() => onShowContentClick(details)}>
                                            {bonusName}
                                        </div>
                                        {!disabledApplyAndCondition && (
                                            <div className={`remaining-time-container ${styles.remainingTimeContainer}`}>
                                                <span className={`remaining-time ${styles.remaningTime}`}>
                                                    <Text textKey={'promotion:promotion.remainingTime'}>Remaining Time</Text>-
                                                </span>
                                                <span className={`remaining-countdown ${styles.remaningCountdown}`}>{<RemainingTime validTill={validTill} />}</span>
                                            </div>
                                        )}
                                    </div>

                                    <button onClick={() => !disabledApplyAndCondition && onApplyClicked(details)} disabled={disabledApplyAndCondition}>
                                        <Text textKey={'global:global.form.apply'}>Apply</Text>
                                    </button>
                                </div>

                                {isNewTag && <LazyLoadImage src={`/public/html/images/promotion/new-icon.svg`} className={`${styles.newIcon}`} />}
                            </div>
                        );
                    })}
                </>
            )}

            {enablePromotionContent && (
                <PromotionContent applyDetails={applyDetails} filteredProviderData={filteredProviderData} onShowContentClick={() => onShowContentClick()} />
            )}
            {enableApplyButton && <PromotionTransfer applyDetails={applyDetails} filteredProviderData={filteredProviderData} onApplyClicked={() => onApplyClicked()} />}
        </section>
    );
};

export const PromotionContent = ({ onShowContentClick, applyDetails, filteredProviderData }) => {
    const languageRedux = useSelector((state) => state.language);
    const screen = useSelector((state) => state.screen);
    const isMobile = screen.viewType === 'mobile';
    const { bonusName, bonusAmount, targetMultiplier, minDeposit, maxBonus, maxDeposit } = applyDetails;
    return (
        <section>
            <div className={`promosDetail-popup promosTransfer-popup ${styles.promosDetailPopup}  ${isMobile && 'm-promosDetail-popup m-promosTransfer-popup'}`}>
                <div className={`popup ${styles.popup}`}>
                    <div className={`popup-header  ${styles.popupHeader}`}>
                        <div className='popup-header-left'>
                            <span className='popup-title'>{bonusName}</span>
                        </div>

                        <div className={`popup-header-right ${styles.popupHeaderRight}`}>
                            <MdClose onClick={() => onShowContentClick()} />
                        </div>
                    </div>

                    <div className={`${styles.popupContentBody}`}>
                        <div className={`${styles.popupContent}`}>
                            <div className={`${styles.popupContentDetails}`}>
                                <span>
                                    <Text textKey={'settings:settings.bonus'}>Bonus</Text>
                                </span>
                                : <span>{bonusAmount}%</span>
                            </div>
                            <div className={`${styles.popupContentDetails}`}>
                                <span>
                                    <Text textKey={'transaction:transaction.transfer.promoDetail.TURNOVER'}>Turnover Multiplier</Text>
                                </span>
                                : <span>{targetMultiplier}x</span>
                            </div>
                            <div className={`${styles.popupContentDetails}`}>
                                <span>
                                    <Text textKey={'transaction:transaction.transfer.promoDetail.minDeposit'}>Min Deposit</Text>
                                </span>
                                :
                                <span>
                                    {languageRedux?.currencyLang} {minDeposit}
                                </span>
                            </div>
                            <div className={`${styles.popupContentDetails}`}>
                                <span>
                                    <Text textKey={'transaction:transaction.transfer.promoDetail.maxDeposit'}>Max Deposit</Text>
                                </span>
                                :
                                <span>
                                    {languageRedux?.currencyLang} {maxDeposit}
                                </span>
                            </div>
                            <div className={`${styles.popupContentDetails}`}>
                                <span>
                                    <Text textKey={'transaction:transaction.transfer.promoDetail.maxBonus'}>Max Bonus</Text>
                                </span>
                                :
                                <span>
                                    {languageRedux?.currencyLang} {maxBonus}
                                </span>
                            </div>
                        </div>

                        {isMobile ? (
                            <div className={`${styles.popupContentProvider}`}>
                                {(filteredProviderData || []).map((provider) => {
                                    return (
                                        <div className={styles.providerContentContainer} key={provider?.key}>
                                            <div className={`${styles.productTitle}`}>
                                                <Text textKey={'promotion:promotion.products'}>Products</Text>
                                            </div>
                                            <div className={`${styles.productContent}`}>
                                                <Text textKey={provider.title}>All</Text>
                                            </div>
                                            <div className={`${styles.providerTitle}`}>
                                                <Text textKey={'promotion:promotion.applicableProvider'}>Applicable Provider</Text>
                                            </div>
                                            <div className={`${styles.providerContent}`}>
                                                <span>
                                                    {(provider?.list || []).map((list, listIndex) => {
                                                        const isLastItem = listIndex === provider.list.length - 1;
                                                        return (
                                                            <React.Fragment key={listIndex}>
                                                                <span>{list.name}</span>
                                                                {!isLastItem && <span>, </span>}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className={`${styles.popupContentProvider}`}>
                                <div className={`${styles.productTitle}`}>
                                    <Text textKey={'promotion:promotion.products'}>Products</Text>
                                </div>
                                <div className={`${styles.providerTitle}`}>
                                    <Text textKey={'promotion:promotion.applicableProvider'}>Applicable Provider</Text>
                                </div>
                                {(filteredProviderData || []).map((provider) => {
                                    return (
                                        <Fragment key={provider?.key}>
                                            <div className={`${styles.productContent}`}>
                                                <Text textKey={provider.title}>All</Text>
                                            </div>
                                            <div className={`${styles.providerContent}`}>
                                                <span>
                                                    {(provider?.list || []).map((list, listIndex) => {
                                                        const isLastItem = listIndex === provider.list.length - 1;
                                                        if (list?.name) {
                                                            return (
                                                                <React.Fragment key={listIndex}>
                                                                    <span>{list.name}</span>
                                                                    {!isLastItem && <span>, </span>}
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        )}
                        <div className={`${styles.tncText}`}>
                            *<Text textKey={'promotion:promotion.terms-condition'}>Term & Conditions Apply</Text>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export const Expired = () => {
    return <section>Expired component</section>;
};
