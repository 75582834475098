import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import providerService from 'root/utils/providerService';
import { TweenMax } from 'gsap';
import { portalAction } from 'root/redux/action';
import notification from 'root/utils/notification';

const controller = {
    vm: null,
    providerData: {
        GPTHAI: {
            key: 'gpthai',
            provider: 'GPTHAI',
            suspend: [
                { left: 180, bottom: 240, width: 120, height: 124, suspendImg: '/public/html/images/lottery/icon/suspend/1_2.png' },
                { left: 240, bottom: 0, width: 180, height: 182, suspendImg: '/public/html/images/lottery/icon/suspend/1_3.png' },
            ],
            text: 'lottery:lottery.gpthai.desc_1',
            mobileText: 'lottery:lottery.gpthai.mobile_desc_1',
            bgImg: '/public/html/images/lottery/gpthai-banner.png',
            maintenance: false,
        },
        GPSODE: {
            key: 'gpsode',
            provider: 'GPSODE',
            suspend: [
                { left: 150, bottom: 180, width: 160, height: 165, suspendImg: '/public/html/images/lottery/icon/suspend/2_2.png' },
                { left: 550, bottom: 50, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/2_3.png' },
            ],
            text: 'lottery:lottery.gpsode.desc_1',
            mobileText: 'lottery:lottery.gpsode.mobile_desc_1',
            bgImg: '/public/html/images/lottery/gpsode-banner.png',
            maintenance: false,
        },
        QQKENO: {
            key: 'QQKENO',
            provider: 'QQK',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/3_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/3_3.png' },
            ],
            // text: 'lottery:lottery.qqk.desc_1',
            text: 'global:global.submenu.lottery.qqkeno',
            mobileText: 'lottery:lottery.qqk.mobile_desc_1',
            bgImg: '/public/html/images/lottery/qqk-banner.png',
            maintenance: false,
            routeName: 'qqkeno',
        },
        QQKTHAI: {
            key: 'qqkthai',
            provider: 'QQKTHAI',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 128, suspendImg: '/public/html/images/lottery/icon/suspend/4_2.png' },
                { left: 300, bottom: 50, width: 150, height: 146, suspendImg: '/public/html/images/lottery/icon/suspend/4_3.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/4_4.png' },
            ],
            text: 'lottery:lottery.qqkthai.desc_1',
            mobileText: 'lottery:lottery.qqkthai.mobile_desc_1',
            bgImg: '/public/html/images/lottery/qqkthai-banner.png',
            maintenance: false,
        },
        GPKENO: {
            key: 'gpkeno',
            provider: 'GPKENO',
            suspend: [
                { left: 150, bottom: 140, width: 140, height: 139, suspendImg: '/public/html/images/lottery/icon/suspend/5_2.png' },
                { left: 470, bottom: 120, width: 100, height: 102, suspendImg: '/public/html/images/lottery/icon/suspend/5_3.png' },
            ],
            text: 'lottery:lottery.gpkeno.desc_1',
            mobileText: 'lottery:lottery.gpkeno.mobile_desc_1',
            bgImg: '/public/html/images/lottery/gpkeno-banner.png',
            maintenance: false,
        },
        FunkyGames: {
            key: 'FunkyGames',
            provider: 'FG',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/3_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/3_3.png' },
            ],
            // text: 'lottery:lottery.qqk.desc_1',
            text: 'global:global.submenu.slots.funkyGames',
            mobileText: 'lottery:lottery.qqk.mobile_desc_1',
            bgImg: '/public/html/images/lottery/qqk-banner.png',
            maintenance: false,
            routeName: 'funkygames',
        },
        SuperSpeedNorth: {
            key: 'superspeednorth',
            provider: 'SuperSpeedNorth',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.win',
            mobileText: 'lottery:lottery.win.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
        },
        SuperSpeedSouth: {
            key: 'superspeedsouth',
            provider: 'SuperSpeedSouth',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.win',
            mobileText: 'lottery:lottery.win.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
        },
        NorthernLottery: {
            key: 'northernlottery',
            provider: 'NorthernLottery',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.win',
            mobileText: 'lottery:lottery.win.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
        },
        SouthLottery: {
            key: 'southlottery',
            provider: 'SouthLottery',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.win',
            mobileText: 'lottery:lottery.win.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
        },
        CentralLottery: {
            key: 'centrallottery',
            provider: 'CentralLottery',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.win',
            mobileText: 'lottery:lottery.win.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
        },
        WinWinLottery: {
            key: 'WinWinLottery',
            provider: 'WWL',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            // text: 'global:global.submenu.lottery.win',
            text: 'global:global.submenu.lottery.winlottery',
            mobileText: 'lottery:lottery.win.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
            routeName: 'sgwinlottery',
        },
        GamePlayLottery: {
            key: 'GamePlayLottery',
            provider: 'GP',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            // text: 'global:global.submenu.lottery.win',
            text: 'global:global.submenu.casino.gamePlay',
            mobileText: 'lottery:lottery.win.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
            routeName: 'gameplay',
        },
        GrandDragon: {
            key: 'GrandDragon',
            provider: 'GDL',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.gdl',
            mobileText: 'lottery:lottery.gdl.mobile_desc_1',
            bgImg: '/public/html/images/lottery/ssnorth-banner.png',
            routeName: 'granddragon',
        },
        ShiCai: {
            key: 'ShiCai',
            provider: 'GW',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.gw',
            mobileText: 'lottery:lottery.gw.mobile_desc_1',
            bgImg: '/public/html/images/lottery/gw-banner.png',
            routeName: 'gwlottery',
        },
        TCG: {
            key: 'TCG',
            provider: 'TCG',
            suspend: [
                { left: 130, bottom: 80, width: 130, height: 122, suspendImg: '/public/html/images/lottery/icon/suspend/6_2.png' },
                { left: 630, bottom: 100, width: 80, height: 78, suspendImg: '/public/html/images/lottery/icon/suspend/6_3.png' },
            ],
            text: 'global:global.submenu.lottery.tcg',
            mobileText: 'lottery:lottery.tcg.mobile_desc_1',
            bgImg: '/public/html/images/lottery/gw-banner.png',
            routeName: 'gwlottery',
        },
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
            currentProvider: '',
            currentProviderKey: '',
            brandActive: 0,
        };
    },

    getProviderGameList(provider) {
        const { language } = controller.vm.props;
        const { keyword } = controller.vm.state;
        let domain = '',
            gameList = [],
            currencyLang = language.currencyLang;

        if (window.location.hostname === 'localhost') {
            // local use
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let param = {
            category: 'LOTTERY',
            merchantCode: domain,
            currencyCode: currencyLang,
            isTemplate: false,
        };

        param.provider = provider;
        window.SPL_Provider.getMerchantGames(param, success, fail);

        function success(data) {
            for (let i = 0; i < data.length; i++) {
                data[i].imggame = `/public/html/games/images/s1/${provider}/${data[i].game}.jpg`;
                data[i].text = data[i].name;
                data[i].gameCode = data[i].game;
                data[i].category = 'L';

                if (keyword) {
                    if (data[i].name.toLowerCase().includes(keyword.toLowerCase())) {
                        gameList.push(data[i]);
                    }
                } else {
                    gameList.push(data[i]);
                }
            }

            controller.arrangeSeq(gameList);
        }

        let brandList = controller.vm.state.brandList;
        for (let j = 0; j < brandList.length; j++) {
            if (provider === brandList[j].provider) {
                gameList.underMaintenance = brandList[j].underMaintenance;
            }
        }

        function fail(data) {
            gameList = null;
            controller.vm.setState({ gameList: gameList });
        }
    },

    updateSelectedProvider(providerObj) {
        controller.vm.setState({ currentProvider: providerObj.provider, currentProviderKey: providerObj.key, gameList: [] }, () => {
            const { tween1, tween2 } = controller.vm.state;
            controller.getLotteryProviderList(tween1, tween2, providerObj);
        });
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { tween1, tween2, currentProvider, currentProviderKey } = controller.vm.state;
            controller.getLotteryProviderList(tween1, tween2, currentProvider, currentProviderKey);
        });
    },

    arrangeSeq(gameList) {
        const { currentProvider, tween1, tween2 } = controller.vm.state;

        window.SPL_Content.getGamesArrangementList(currentProvider).then((json) => {
            if (json) {
                if (json[currentProvider] && json[currentProvider]['desktop']['Arrangement']) {
                    let gamesArrangement = json[currentProvider]['desktop']['Arrangement'];

                    gameList.forEach(function (e) {
                        e.seq += 100;
                        for (var i = 0; i < gamesArrangement.length; i++) {
                            if (e.game == gamesArrangement[i].game) {
                                e.seq = parseInt(gamesArrangement[i].seq);
                            }
                        }
                    });

                    gameList.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
            }

            controller.getMemberFavoriteGame(gameList, tween1, tween2);
        });
    },

    getLotteryProviderList(tween1, tween2, providerObj, key = '', isMobile = false) {
        const { language, t, screen } = controller.vm.props;
        let view = screen.viewType;
        let currencyLang = language.currencyLang;
        let pathname = window.location.pathname;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        if (view === 'mobile') {
            isMobile = true;
        }

        controller.vm.setState({
            gameList: [],
        });

        return new Promise(function (resolve) {
            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, view, 'L').then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];
                let count = 0;
                let list = [];
                let lotteryData = []; // reset value first
                lotteryData.length = 0;
                let brandList = [];
                let currentProvider = providerObj.provider ? providerObj.provider : controller.vm.state.currentProvider;
                let currentProviderKey = providerObj.key ? providerObj.key : controller.vm.state.currentProviderKey;

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].key;
                    let providerCode = submenu[i].provider;
                    let foundData = controller.providerData[provider];
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider || providerCode === maintenanceProvider) {
                                submenu[i].gameMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundData) {
                        // desktop handling
                        if (view === 'web') {
                            foundData.underMaintenance = submenu[i].gameMaintenance;
                            let bannerElement = generateLotteryBannerElement(provider, foundData, count, isHotProvider, isNewProvider);
                            count = count + 1;

                            lotteryData.push(foundData);
                            list.push(bannerElement);
                        } else {
                            // mobile handling
                            foundData.underMaintenance = submenu[i].gameMaintenance;
                            list.push(foundData);
                        }

                        const isWebConditionProvider = !currentProvider && !currentProviderKey && i === 0 && !isMobile;
                        const isMobileConditionProvider = isMobile && pathname.includes(foundData.routeName);

                        if (isWebConditionProvider || isMobileConditionProvider) {
                            currentProvider = foundData.provider;
                            currentProviderKey = foundData.key;
                        }

                        foundData.isHotProvider = isHotProvider;
                        foundData.isNewProvider = isNewProvider;

                        brandList.push(foundData);
                        controller.vm.setState(
                            {
                                list: list,
                                brandList: brandList,
                                tween1: tween1,
                                tween2: tween2,
                                currentProvider: currentProvider,
                                currentProviderKey: currentProviderKey,
                            },
                            () => {
                                if (currentProvider) {
                                    controller.getProviderGameList(currentProvider);
                                }
                            }
                        );
                        resolve(lotteryData);
                    }
                }
            });
        });

        function generateLotteryBannerElement(provider, data, index, isHotProvider, isNewProvider) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src={data.bgImg} alt='' />
                        {data.blockImg && (
                            <LazyLoadImage
                                key='item-block'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-block'
                                src={data.blockImg}
                            />
                        )}
                        {data.girlImg && (
                            <LazyLoadImage
                                key='item-girl'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-girl'
                                src={data.girlImg}
                            />
                        )}
                        <div className='container'>
                            <div className={provider}>
                                {data.suspend.map((item, j) => (
                                    <div id={`suspend_${index}_${j}`} className={['suspend'].join(' ')} key={`${index + 1}_${j + 2}`}>
                                        <LazyLoadImage src={item.suspendImg} alt='' />
                                    </div>
                                ))}
                            </div>
                            <div className={'content content-' + data.key}>
                                <span className={'icon-box box-' + data.key}>
                                    <i className={'icon-' + data.key}></i>
                                    <i className={'icon-' + data.provider}></i>
                                    {/* <img className={'provider-icon icon-' + data.key} src={data.icon} /> */}
                                </span>
                                <span className='desc' dangerouslySetInnerHTML={{ __html: t(data.text, { interpolation: { escapeValue: false } }) }}></span>
                                <span
                                    className='btn'
                                    onClick={() => {
                                        controller.launchGame(provider, data.maintenance);
                                    }}
                                >
                                    {data.maintenance ? t('casino:casino.underMaintenance', 'Under Maintenance') : t('casino:casino.betnow', 'BET NOW')}
                                </span>
                            </div>
                        </div>
                    </div>
                ),

                tab: (
                    <div>
                        <i className={['icon-small-' + data.key, 'gray'].join(' ')}></i>
                        <i className={['icon-small-' + data.key, 'on'].join(' ')}></i>
                        {isHotProvider && <div className='hot-provider-casino'></div>}
                        {isNewProvider && <div className='new-provider-casino'></div>}
                    </div>
                ),

                providerMaintenance: data.maintenance ? <Fragment>{data.maintenance && <div className='maintenance-hover-box'></div>}</Fragment> : null,
            };
        }
    },

    checkUnderMaintenance(providerObj, underMaintenance, i) {
        if (!underMaintenance) {
            controller.updateSelectedProvider(providerObj, underMaintenance);
            controller.toggleTab(i, 'brand');
        }
    },

    toggleTab(index, type = 'type') {
        if (type == 'type' && index == controller.vm.state.typeActive) return;
        if (type == 'type') {
            controller.vm.setState({ typeActive: index });
        } else {
            controller.vm.setState({ brandActive: index });
        }
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const { screen } = controller.vm.props;
        let isMobile = null;

        if (screen.viewType === 'web') {
            isMobile = false;
        } else {
            isMobile = true;
        }

        window.SPL_Member.getMemberFavoriteGame(isMobile).then((data) => {
            let favGame = data.favoritedGame;
            let favGameList = data.favoritedGame.map((game) => game.id);
            let newGameList = [...gameList];
            for (let a = 0; a < gameList.length; a++) {
                if (favGameList.indexOf(gameList[a].id) >= 0) {
                    newGameList[a].isFav = true;
                } else {
                    newGameList[a].isFav = false;
                }
            }
            controller.vm.setState({ favoritedGame: favGame, gameList: newGameList, loading: false }, () => {
                controller.doSuspendAni(tween1, tween2);
            });
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { gameList, favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;
            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    },

    launchGame(provider, maintenance) {
        if (!maintenance) {
            const { language, screen, user } = controller.vm.props;
            let extraParam = null;
            let gameObj = {
                provider: provider,
                category: 'L',
            };

            providerService.launchGame(gameObj, language, user.isLogin, screen, false, extraParam, controller.vm);
        }
    },
};

export default controller;
