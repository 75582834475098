// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { DebounceInput } from 'react-debounce-input';
import DatePicker from 'react-datepicker';
import { AiOutlineCopy } from 'react-icons/ai';
import { EmailIcon, EmailShareButton, LineIcon, LineShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

// components/files
import QRCode from 'qrcode.react';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';

// controller/logic
import vm from '../../crypto.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { CryptoBankIcon } from '../../../../../../../../components/molecules/bank-icon/bank-icon';
class Crypto extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.getPaymentGatewaySetting().then(() => {
                vm.loadDepositDetails();
            });
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.CT));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.CT) });
        }
        if (prevState.depositMinMaxLimit?.min !== this.state.depositMinMaxLimit?.min && this.state.isAmountDirty) {
            vm.validateDepositAmount();
        }
    }

    onSubmitFormClick() {
        vm.submitForm();
    }
    onHandleAmountChange(value, field) {
        vm.handleAmountChange(value, field);
    }

    onSharedAddressAmountChange(e, item) {
        this.onHandleAmountChange(e.target.value, 'amount');
        transactionHelper.onDepositAmountClicked(this, item);
        this.setState({ isAmountDirty: true });
    }

    render() {
        const {
            cryptoQrLink,
            memberVerifyPopup,
            showName,
            cryptoFields,
            errors,
            fields,
            cryptoChain,
            selectedChainIndex,
            coinAmountDisable,
            disableDepositButton,
            depositMinMaxLimit,
            amountErrorValue,
            currency,
            depositAmountOptions,
            cryptoQrCode,
            isFormValid,
            isLoading,
        } = this.state;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {memberVerifyPopup && (
                            <div
                                className='memberVerify-box'
                                onClick={() => {
                                    transactionHelper.close(this, 'memberVerify');
                                }}
                            >
                                <div
                                    className='memberVerify-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.crypto.memberVerify', 'Member Information Verification')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                transactionHelper.close(this, 'memberVerify');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content'>
                                        {showName && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={cryptoFields['fullname']}
                                                    placeholder={t('global:global.form.fullname', 'Full Name')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'fullname');
                                                    }}
                                                />
                                                <div className='input-error'>
                                                    {errors['fullname'] !== 'global:global.form.fullname-placeholderv2' && t(errors['fullname'])}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.showEmail && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={fields['email']}
                                                    placeholder={t('global:global.form.email', 'Email Address')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'email');
                                                    }}
                                                />
                                                <div className='input-error'>{t(errors['email'])}</div>
                                            </div>
                                        )}

                                        {this.state.showPhone && (
                                            <div className='input'>
                                                <div>
                                                    <select
                                                        className='phone'
                                                        value={fields['telCode']}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'telCode');
                                                        }}
                                                    >
                                                        {this.state.optionsList['telCode'].map((option, i) => (
                                                            <option key={i} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <DebounceInput
                                                        type='text'
                                                        debounceTimeout='1000'
                                                        values={fields['phone']}
                                                        placeholder={t('global:global.form.phone', 'Telephone Number')}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'phone');
                                                        }}
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(errors['phone'])}</div>
                                            </div>
                                        )}

                                        {this.state.showDob && (
                                            <div className='input'>
                                                <div>
                                                    <span className='item-title'>{t('global:global.form.dob', 'Date of Birth')}</span>
                                                    <DatePicker
                                                        autoComplete='off'
                                                        type='text'
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        placeholderText={t('transaction:transaction.deposit.cashdeposit.depositdate', 'Deposit Date/Time')}
                                                        selectsStart
                                                        selected={cryptoFields.dob}
                                                        maxDate={this.state.maxDob}
                                                        popperPlacement='bottom-end'
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'dob');
                                                        }}
                                                        timeFormat='yyyy-MM-dd'
                                                        dateFormat='yyyy-MM-dd'
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(errors['dob'])}</div>
                                            </div>
                                        )}

                                        {this.state.requestRefill && (
                                            <div className='input'>
                                                <div className='input-error'>{t('transaction:transaction.deposit.crypto.fillAgain', 'Please fill in again.')}</div>
                                            </div>
                                        )}

                                        <button className='btn-submit' disabled={this.state.memberVerifyDisable} onClick={() => vm.handleMemberVerifySubmit()}>
                                            {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.getShareInfo && (
                            <div
                                className='shareInfo-box'
                                onClick={() => {
                                    transactionHelper.close(this, 'shareInfo');
                                }}
                            >
                                <div
                                    className='shareInfo-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('settings:settings.inviteFriend', 'Invite Friend')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                transactionHelper.close(this, 'shareInfo');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content shareInfo-content'>
                                        <EmailShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <EmailIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Email</span>
                                        </EmailShareButton>
                                        <WhatsappShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <WhatsappIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Whatsapp</span>
                                        </WhatsappShareButton>
                                        <TelegramShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <TelegramIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Telegram</span>
                                        </TelegramShareButton>
                                        <LineShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <LineIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>LINE</span>
                                        </LineShareButton>
                                        <button
                                            className='shareInfo-copy'
                                            onClick={() => {
                                                core.copy(t, this.state.cryptoQrCode);
                                            }}
                                        >
                                            <AiOutlineCopy></AiOutlineCopy> <span>Copy to Clipboard</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.cryptoOptions && this.state.cryptoOptions.length > 0 && (
                            <div className='group-item input-item crypto-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box crypto-box'>
                                        <select onChange={(e) => vm.changeCryptoChannel(e)}>
                                            {this.state.cryptoOptions.map((channel, index) => (
                                                <option key={index} value={index}>
                                                    {channel.name !== '{channel}'
                                                        ? channel.name
                                                        : ('transaction:transaction.deposit.crypto.channelName', 'Channel') + ' ' + (index + 1)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.crypto.cryptoCurrency', 'Crypto Currency')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list'>
                                <ul>
                                    {cryptoChain &&
                                        cryptoChain.length > 0 &&
                                        cryptoChain.map((chain, i) => (
                                            <li
                                                key={i}
                                                className={['icon-hover-box', selectedChainIndex === i ? 'on' : ''].join(' ')}
                                                onClick={() => {
                                                    vm.onChainChanged(chain);
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({ hoveredIndex: i });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ hoveredIndex: null });
                                                }}
                                            >
                                                <span className='icon-box'>
                                                    <i
                                                        className={[
                                                            'icon-bank',
                                                            selectedChainIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                ? 'icon-bank-on'
                                                                : 'icon-bank-off',
                                                        ].join(' ')}
                                                    >
                                                        <CryptoBankIcon bankCode={`${chain.coin}_${chain.network}`} coin={chain.coin} />
                                                    </i>
                                                </span>
                                                <span>{chain.displayName}</span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>

                        {!cryptoQrCode && !coinAmountDisable && !isLoading ? (
                            <>
                                <div className='group-item'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='amount-content'>
                                        <div className='input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={this.state.fields['amount']}
                                                onChange={(e) => this.onHandleAmountChange(e.target.value, 'amount')}
                                                onBlur={() => {
                                                    this.setState({ isAmountDirty: true });
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            <div className='error-validation'>
                                                <Trans
                                                    i18nKey={this.state.errors['amount']}
                                                    values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                                ></Trans>
                                            </div>
                                        </div>
                                        <ul>
                                            {depositAmountOptions.map((item) => (
                                                <li
                                                    key={item}
                                                    onClick={(e) => {
                                                        this.onSharedAddressAmountChange(e, item);
                                                    }}
                                                >
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : coinAmountDisable && !isLoading ? (
                            <div className='group-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.coinAmount', 'Coin Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='depositamount'
                                            type='numeric'
                                            autoComplete='off'
                                            placeholder={
                                                t('transaction:transaction.deposit.crypto.minLimit', 'Min Limit') +
                                                ': ' +
                                                cryptoChain[selectedChainIndex].coin +
                                                ' ' +
                                                window.SPL_Other.formatAmount(depositMinMaxLimit.min)
                                            }
                                            min={'0'}
                                            value={fields['amount']}
                                            onChange={(e) => this.onHandleAmountChange(e.target.value, 'amount')}
                                        />
                                    </div>
                                    <div className='error-validation'>
                                        <Trans i18nKey={errors['amount']} values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: currency }}></Trans>
                                    </div>
                                </div>
                            </div>
                        ) : cryptoQrCode ? (
                            <>
                                <div className='group-item bank-options crypto-options'>
                                    <div className='title'>
                                        <span></span>
                                    </div>
                                    <div className='bank-list'>
                                        <ul className='crypto-qr'>
                                            {this.state.cryptoQrCode && (
                                                <Fragment>
                                                    <li className='qr-box'>
                                                        <QRCode id='cryptoQR' value={vm.qrcode()} size={159} className='crypto-qr-img' />
                                                    </li>

                                                    <li className='box-info'>
                                                        <p className='info-minDeposit'>
                                                            {t('transaction:transaction.deposit.crypto.minDeposit', 'Min Deposit')} = {this.state.chainName}{' '}
                                                            {this.state.selectedChainMin}
                                                        </p>
                                                        <p className='info-currentRate'>{t('transaction:transaction.deposit.crypto.currentRate', 'Current exchange rate')}</p>
                                                        <p className='info-rate'>
                                                            {this.state.chainName} 1 = {userCurrency} {this.state.exchangeRate.toFixed(2)}
                                                        </p>
                                                        <p className='info-note'>
                                                            {t(
                                                                'transaction:transaction.deposit.crypto.cryptoDepositNote1',
                                                                'Note: The current exchange rate above is FOR REFERENCE ONLY.'
                                                            )}
                                                        </p>
                                                        <p className='info-note info-note-spacing'>
                                                            {t(
                                                                'transaction:transaction.deposit.crypto.cryptoDepositNote2',
                                                                'For the most accurate exchange rates, please refer to official {{chain}} exchange rates.',
                                                                {
                                                                    chain: this.state.chainName,
                                                                }
                                                            )}
                                                        </p>
                                                        {this.state.chainName !== 'BTC' && <p className='info-chain'>CHAIN : {this.state.chainBranch}</p>}
                                                        <p className='info-scanQr'>
                                                            {t('transaction:transaction.deposit.crypto.scanQrCodeWithWallet', 'SCAN THE QR CODE WITH YOUR CHAIN WALLET', {
                                                                chain: this.state.chainName,
                                                            })}
                                                        </p>
                                                        <span onClick={() => vm.downloadQrCode()}>{t('transaction:transaction.deposit.crypto.saveQr', 'SAVE QR CODE')}</span>
                                                    </li>
                                                </Fragment>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className='group-item bank-options crypto-options crypto-shareBox'>
                                    <div className='title'>
                                        <span></span>
                                    </div>
                                    <div className='bank-list'>
                                        <ul>
                                            <span>
                                                {t('transaction:transaction.deposit.crypto.sendToChainAddress', 'or send to this Chain address', {
                                                    chain: this.state.chainName,
                                                })}
                                            </span>
                                            <li className='share-box'>
                                                <p className='box-code'>{this.state.cryptoQrCode}</p>
                                                <p className='btn-share' onClick={() => vm.getShareInfo()}>
                                                    {t('transaction:transaction.deposit.crypto.share', 'SHARE')}
                                                </p>
                                            </li>
                                            {cryptoQrLink && (
                                                <button className='checkStatus' onClick={() => vm.getCryptoQrCode(cryptoQrLink)}>
                                                    {t('transaction:transaction.checkStatus', 'Check Status')}
                                                </button>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <SmallLoading></SmallLoading>
                        )}

                        {this.state.chainRemark && this.state.chainRemark.length > 0 && (
                            <div className='notice-box '>
                                <span className='item-title'>{t('transaction:transaction.deposit.crypto.reminder', 'REMINDER')} :</span>
                                <ul className='crypto-remark'>
                                    {this.state.chainRemark.map((info, index) => (
                                        <li key={index}>{info}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!cryptoQrCode && (
                            <div className='group-item submit-container'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='flexbox'>
                                        <button
                                            className='btn btn-submit'
                                            onClick={() => {
                                                this.onSubmitFormClick();
                                            }}
                                            disabled={disableDepositButton || !isFormValid}
                                        >
                                            {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(Crypto)));
