import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import GameList from 'root/webapp/shared/component/GameList';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import { TweenMax } from 'gsap';
import { DebounceInput } from 'react-debounce-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Scr from '../../scr/scr.selector';
import Mega from '../../mega/mega.selector';
import Psy8 from '../../psy8/psy8.selector';
import SVGElements from 'root/components/SVGElements';
import Loading from 'root/webapp/shared/loading/loading';

import vm from '../fast-games-detail.controller';
import slotVM from '../fast-games.controller';

let tween1, tween2;
class SubGameMenu extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    async componentDidMount() {
        // if (!this.props.location.query) {
        //     this.props.history.replace({ pathname: '/slots' });
        //     return;
        // }

        if (window.SPL_LiteApp.isZT828LiteApp()) {
            window.SPL_LiteApp.onGameListDisableRefresh(true);
        }

        await vm.populateFromSlotLanding(tween1, tween2);
        const tabFilter = this.getDefaultTab();
        await vm.getProviderGameList(this.state.currentProvider, tabFilter, '', tween1, tween2);
        this.getTabIndex();

        // await vm.getProviderList(tween1, tween2, true);
        // const tabFilter = this.getDefaultTab();
        // await vm.getProviderGameList(this.state.currentProvider, tabFilter, undefined, tween1, tween2);
        // this.getTabIndex();
    }

    componentWillUnmount() {
        if (window.SPL_LiteApp.isZT828LiteApp()) {
            window.SPL_LiteApp.onGameListDisableRefresh(false);
        }
    }

    getDefaultTab() {
        const defaultTab = vm.getDefaultTab();
        this.setState({ tabFilter: defaultTab });
        return defaultTab;
    }

    getTabIndex() {
        const { typeList, tabFilter } = this.state;
        const typeListNames = typeList.map((type) => type.toUpperCase());
        const tabIndex = typeListNames.indexOf(tabFilter);
        if (tabIndex > 0) {
            this.setState({ typeActive: tabIndex });
        }
    }

    toggleTab(index) {
        if (index === this.state.typeActive) return;

        this.setState({ typeActive: index });

        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='fastgames-page'>
                        {this.state.loading && <Loading className='vw-100 vh-100'></Loading>}

                        <HomeMemberProfile />
                        <div className='fastgames-box-container'>
                            <div className={'gamelist-page page-' + this.state.currentProvider}>
                                <div className='tools-box'>
                                    <div className='btn-box'>
                                        <span
                                            className='icon-box'
                                            onClick={() => {
                                                this.props.history.push('/fastgames');
                                            }}
                                        >
                                            <SVGElements className='icon-back2' name='back-icon' />
                                            {/* <i className="icon-back2"></i> */}
                                        </span>
                                        <span className='icon-box'>
                                            <LazyLoadImage src={'/public/html/images/fast-games/icon/' + this.state.currentProviderKey + '_on.png'} alt='' />
                                        </span>
                                        <span>{this.state.currentProvider && t(slotVM.providerData[this.state.currentProvider].text)}</span>
                                    </div>
                                    <div className='input-box'>
                                        <DebounceInput
                                            type='text'
                                            debounceTimeout={1000}
                                            values={this.state.keyword}
                                            placeholder={t('global:global.search', 'Search')}
                                            onChange={(e) => {
                                                vm.handleKeywordChanged(e);
                                            }}
                                        />
                                        <i className='icon-search'></i>
                                    </div>
                                </div>
                                {this.state.currentProvider === 'SCR' || this.state.currentProvider === 'SCR2' ? (
                                    <Scr />
                                ) : this.state.currentProvider === 'MEGA' ? (
                                    <Mega />
                                ) : this.state.currentProvider === 'PSY8' ? (
                                    <Psy8 />
                                ) : (
                                    <div className='fastgames-games-bottom'>
                                        <div className='type-nav-box'>
                                            <ul className='type-list'>
                                                {this.state.typeList.map((name, i) => (
                                                    <li
                                                        key={name}
                                                        className={[i === this.state.typeActive ? 'on' : ''].join(' ')}
                                                        onClick={() => {
                                                            vm.updateTabFilter(name);
                                                            this.toggleTab(i);
                                                        }}
                                                    >
                                                        <span>{name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className='type-game-list-box'>
                                            {this.state.gameList.length > 0 && (
                                                <GameList
                                                    list={this.state.gameList}
                                                    updateMemberFavoriteGame={vm.updateMemberFavoriteGame}
                                                    handleCustomClick={vm.launchSlotGame}
                                                    showMore={this.state.showMore}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('slot')(withRouter(SubGameMenu)));
