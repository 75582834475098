import { TweenMax } from 'gsap';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';

const controller = {
    vm: null,
    providerData: {
        KM: {
            provider: 'KM',
            key: 'km',
            text: 'global:global.submenu.games.kingmaker',
            routeName: 'kingmaker',
            desktopText: '',
            mobileText: 'global:global.submenu.games.KM2.mobileText',
            underMaintenance: false,
        },
        KM2: {
            provider: 'KM2',
            key: 'km',
            text: 'global:global.submenu.games.kingmaker',
            routeName: 'kingmaker',
            desktopText: '',
            mobileText: 'global:global.submenu.games.KM2.mobileText',
            underMaintenance: false,
        },
        GP: {
            provider: 'GP',
            key: '3d_gp',
            text: 'global:global.submenu.games.gamePlay',
            routeName: 'gameplay',
            desktopText: '',
            mobileText: 'global:global.submenu.games.GP.mobileText',
            underMaintenance: false,
        },
        V8: {
            provider: 'V8',
            key: 'v8',
            text: 'global:global.submenu.games.v8poker',
            routeName: 'v8poker',
            desktopText: '',
            mobileText: 'global:global.submenu.games.v8.mobileText',
            underMaintenance: false,
        },
        MT: {
            provider: 'MT',
            key: '3d_mt',
            text: 'global:global.submenu.games.minited',
            routeName: 'minited',
            desktopText: '',
            mobileText: 'global:global.submenu.games.mt.mobileText',
            underMaintenance: false,
        },
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            currentProvider: '',
            currentProviderKey: '',
            brandActive: 0,
            brandList: [],
            gameList: [],
            favoritedGame: [],
            tween1: null,
            tween2: null,
            keyword: '',
        };
    },

    updateSelectedProvider(providerObj) {
        controller.vm.setState({ currentProvider: providerObj.provider, currentProviderKey: providerObj.key, gameList: [] }, () => {
            const { tween1, tween2 } = controller.vm.state;
            controller.getProviderList(tween1, tween2, providerObj);
        });
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { tween1, tween2, currentProvider, currentProviderKey } = controller.vm.state;
            controller.getProviderList(tween1, tween2, currentProvider, currentProviderKey);
        });
    },

    getProviderList(tween1, tween2, providerObj, key = '') {
        const { language, screen } = controller.vm.props;
        let view = 'web';
        let currencyLang = language.currencyLang;
        let pathname = window.location.pathname;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        if (screen.viewType === 'mobile') {
            view = 'mobile';
        }

        let loadGetSubmenu = new Promise((resolve) => {
            window.SPL_Content.getSubmenu(language.countryLanguageKey, view, 'G').then((data) => resolve(data));
        });

        let loadProviderGameMaintenanceList = new Promise((resolve) => {
            window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
        });

        Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
            let submenu = loadResult[0];
            let providerMaintenance = loadResult[1];

            let brandList = [],
                currentProvider = providerObj.provider ? providerObj.provider : providerObj,
                currentProviderKey = providerObj.key ? providerObj.key : key,
                provider = '',
                foundProvider = '';

            for (let i = 0; i < submenu.length; i++) {
                provider = submenu[i].provider;

                foundProvider = controller.providerData[provider];
                const isHotProvider = submenu[i].isHotProvider;
                const isNewProvider = submenu[i].isNewProvider;

                if (providerMaintenance) {
                    for (let j = 0; j < providerMaintenance.length; j++) {
                        let maintenanceProvider = providerMaintenance[j].provider;

                        if (submenu[i].provider === maintenanceProvider) {
                            foundProvider.underMaintenance = providerMaintenance[j].gameMaintenance;
                        }
                    }
                }

                if (foundProvider) {
                    const isWebConditionProvider = !currentProvider && !currentProviderKey && i === 0 && !screen.isMobile;
                    const isMobileConditionProvider = screen.isMobile && pathname.includes(foundProvider.routeName);
                    foundProvider.isHotProvider = isHotProvider;
                    foundProvider.isNewProvider = isNewProvider;

                    if (isWebConditionProvider || isMobileConditionProvider) {
                        currentProvider = foundProvider.provider;
                        currentProviderKey = foundProvider.key;
                    }
                    // will depends on the path to get the provider
                    if (pathname.includes(foundProvider.routeName)) {
                        currentProvider = foundProvider.provider;
                        currentProviderKey = foundProvider.key;
                    }
                    brandList.push(foundProvider);
                }
            }

            controller.vm.setState(
                {
                    brandList: brandList,
                    currentProvider: currentProvider,
                    currentProviderKey: currentProviderKey,
                    tween1: tween1,
                    tween2: tween2,
                },
                () => {
                    if (controller.vm.state.brandList.length > 0) {
                        controller.getProviderGameList(currentProvider);
                    }
                }
            );
        });
    },

    toggleTab(index, type = 'type') {
        if (type == 'type' && index == controller.vm.state.typeActive) return;
        if (type == 'type') {
            controller.vm.setState({ typeActive: index });
        } else {
            controller.vm.setState({ brandActive: index });
        }
    },

    checkUnderMaintenance(providerObj, underMaintenance, i) {
        if (!underMaintenance) {
            controller.updateSelectedProvider(providerObj, underMaintenance);
            controller.toggleTab(i, 'brand');
        }
    },

    clearGameList() {
        controller.vm.setState({ gameList: [] });
    },

    getProviderGameList(provider) {
        const { language } = controller.vm.props;
        const { keyword, tween1, tween2 } = controller.vm.state;
        let domain = '',
            gameList = [],
            currencyLang = language.currencyLang;

        if (window.location.hostname === 'localhost') {
            // local use
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let param = {
            category: 'TABLE',
            merchantCode: domain,
            currencyCode: currencyLang,
            isTemplate: false,
        };

        param.provider = provider;
        window.SPL_Provider.getMerchantGames(param, success, fail);

        function success(data) {
            for (let i = 0; i < data.length; i++) {
                data[i].imggame = `/public/html/games/images/s1/${provider}/${data[i].game}.jpg`;
                data[i].text = data[i].name;
                data[i].gameCode = data[i].game;
                data[i].category = data[i].category;

                //massage gamelist name
                if (data[i].i18n && language.key !== 'zh-CN') {
                    let gameName = data[i].name;
                    try {
                        gameName = JSON.parse(data[i].i18n);
                        if (gameName[language.key.toUpperCase()]) {
                            data[i].name = gameName[language.key.toUpperCase()];
                        }
                    } catch (e) {}
                } else {
                    if (language.key === 'zh-CN') {
                        data[i].name = data[i].nameCN;
                    }
                }

                if (keyword) {
                    if (data[i].name.toLowerCase().includes(keyword.toLowerCase())) {
                        gameList.push(data[i]);
                    }
                } else {
                    gameList.push(data[i]);
                }
            }

            controller.arrangeSeq(gameList);
        }

        let brandList = controller.vm.state.brandList;
        for (let j = 0; j < brandList.length; j++) {
            if (provider === brandList[j].provider) {
                gameList.underMaintenance = brandList[j].underMaintenance;
            }
        }

        function fail(data) {
            gameList = null;
            controller.vm.setState({ gameList: gameList });
        }
    },

    arrangeSeq(gameList) {
        const { currentProvider, tween1, tween2 } = controller.vm.state;

        window.SPL_Content.getGamesArrangementList(currentProvider).then((json) => {
            if (json) {
                if (json[currentProvider] && json[currentProvider]['desktop']['Arrangement']) {
                    let gamesArrangement = json[currentProvider]['desktop']['Arrangement'];

                    gameList.forEach(function (e) {
                        e.seq += 100;
                        for (var i = 0; i < gamesArrangement.length; i++) {
                            if (e.game == gamesArrangement[i].game) {
                                e.seq = parseInt(gamesArrangement[i].seq);
                            }
                        }
                    });

                    gameList.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
            }

            //   controller.vm.setState({ gameList: gameList }, () => {
            //     controller.getMemberFavoriteGame(gameList, tween1, tween2);
            //   });
            controller.getMemberFavoriteGame(gameList, tween1, tween2);
        });
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const { screen } = controller.vm.props;
        let isMobile = null;

        if (screen.viewType === 'web') {
            isMobile = false;
        } else {
            isMobile = true;
        }

        for (let i = 0; i < gameList.length; i++) {
            gameList[i].isFav = false;
        }

        window.SPL_Member.getMemberFavoriteGame(isMobile).then((data) => {
            let favGame = data.favoritedGame;
            let favGameList = data.favoritedGame.map((game) => game.id);
            let newGameList = [...gameList];
            for (let a = 0; a < gameList.length; a++) {
                if (favGameList.indexOf(gameList[a].id) >= 0) {
                    newGameList[a].isFav = true;
                } else {
                    newGameList[a].isFav = false;
                }
            }

            controller.vm.setState({ favoritedGame: favGame, gameList: newGameList, loading: false }, () => {
                controller.doSuspendAni(tween1, tween2);
            });
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { gameList, favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;

            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        //controller.getProviderList(tween1, tween2, game);
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        // controller.getProviderList(tween1, tween2, game);
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    // animation effect
    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    },
};

export default controller;
