import React, { Fragment, useEffect, useState } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const RewardPopup = ({ user, history, screen }) => {
    const [getReward, setGetReward] = useState(false);
    const [rewardInfo, setRewardInfo] = useState(false);
    const [reward, setReward] = useState([]);
    let isWeb = screen.viewType === 'web';

    let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;

    if (userCurrency === 'KHUSD') userCurrency = 'USD';

    useEffect(() => {
        let isMount = true;
        getRewardDetail();

        function getRewardDetail() {
            if (user.account !== null) {
                const { id, currency } = user.account;

                window.SPL_Reward.getRewards(id, currency).then((res) => {
                    if (res && res.rewards) {
                        if (isMount) {
                            setReward(res.rewards);
                            setGetReward(true);
                        }
                    }
                });
            } else {
                setGetReward(false);
            }
        }

        return () => {
            isMount = false;
        };
    }, [user.isLogin, user.account]);

    function closeReward() {
        setGetReward(false);
    }

    function getRewardInfo(info) {
        setRewardInfo(!rewardInfo);
        setGetReward(false);

        if (info.rewardType !== 'FREE_SPIN') {
            window.SPL_Reward.claimRedPacket(info);
        } else {
            window.SPL_Reward.claimRewardFreeSpin(info);
        }
    }

    function getHistoryPage() {
        setRewardInfo(false);

        history.push('/myaccount/history');
    }

    return (
        <Translation>
            {(t) => (
                <Fragment>
                    {rewardInfo && (
                        <div className={`reward-popup ${!isWeb && 'm-reward-popup'}`}>
                            <div className='popup'>
                                <LazyLoadImage className='reward-popupImg' src={reward.popupImage} onClick={getHistoryPage} />
                                <MdClose className='reward-close' onClick={() => getRewardInfo(reward)} />

                                <div className='popup-detail' onClick={getHistoryPage}>
                                    <p className='detail-title' style={{ color: reward.titleColor.split('/').pop() }}>
                                        {reward.name}
                                    </p>
                                    <p className='detail-amount' style={{ color: reward.amountColor && reward.amountColor.split('/').pop() }}>
                                        {userCurrency} {reward.amount.toFixed(2)}
                                    </p>
                                    <p className='detail-desc' style={{ color: reward.descColor.split('/').pop() }}>
                                        {reward.description}
                                    </p>
                                </div>
                            </div>
                            <div className='popup-overlay' onClick={() => getRewardInfo(reward)}></div>
                        </div>
                    )}

                    {getReward && (
                        <div className={`reward-box ${!isWeb && 'm-reward-box'}`}>
                            <LazyLoadImage className='reward-img' src={reward.rewardImgSrc} onClick={() => getRewardInfo(reward)} />
                            <MdClose className='reward-close' onClick={closeReward} />
                        </div>
                    )}
                </Fragment>
            )}
        </Translation>
    );
};

export default connect(mapStateToProps)(withTranslation()(withRouter(RewardPopup)));
