import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import SVGElements from 'root/components/SVGElements';
import { CSSTransition } from 'react-transition-group';
import { createPortal } from 'react-dom';

import providerService from 'root/utils/providerService';

class MyCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.list,
            mobileActive: 0,
            isShowMobileBox: false,
            mobileSelectedGameObj: null,
        };
    }

    handleClick(game, isFun) {
        const { language, screen, user } = this.props;
        let gameObj = {
            provider: game.provider,
            gameCode: game.game,
            category: 'SL',
        };

        providerService.launchGameFromHomePage(gameObj, language, user.isLogin, screen, isFun, this);
    }

    openMobileHoverBox(i, gameObj) {
        if (!document.querySelector('#fixed-container')) return; //非mobile版
        document.body.style.overflow = 'hidden';
        //document.querySelector('#root').style.filter = "blur(1px)";
        document.querySelector('#fixed-container .tool-bar').style.filter = 'blur(1px)';

        this.setState((state) => ({ ...state, mobileActive: i, isShowMobileBox: true, mobileSelectedGameObj: gameObj }));
    }

    closeMobileHoverBox() {
        if (!document.querySelector('#fixed-container')) return; //非mobile版
        document.body.style.overflow = '';
        document.querySelector('#fixed-container .tool-bar').style.filter = '';
        this.setState((state) => ({ ...state, isShowMobileBox: false, mobileSelectedGameObj: null }));
    }

    render() {
        const { favoritedGame, screen } = this.props;
        let isWeb = screen.viewType === 'web';

        const responsive = {
            1025: { items: 7 },
            0: { items: 5 },
        };
        const prevButton = () => {
            return <SVGElements className='icon-arrow-prev' name='arrow-down-icon' />;
        };
        const nextButton = () => {
            return <SVGElements className='icon-arrow-next' name='arrow-down-icon' />;
        };

        return (
            <Translation>
                {(t) => (
                    <div className='my-collection'>
                        <div className='container'>
                            <div className='title-box'>
                                <span className='item-title'>{t('slot:slot.myCollection', 'MY COLLECTION')}</span>
                            </div>

                            <ul className='my-collection-list'>
                                {isWeb && favoritedGame.length > 5 ? (
                                    <AliceCarousel
                                        infinite={false}
                                        mouseTracking
                                        disableDotsControls
                                        responsive={responsive}
                                        renderPrevButton={prevButton}
                                        renderNextButton={nextButton}
                                    >
                                        {favoritedGame
                                            .filter((gameObj) => {
                                                if (!this.props.searchKeyword) {
                                                    return gameObj;
                                                } else if (gameObj.name.toLowerCase().includes(this.props.searchKeyword.toLowerCase())) {
                                                    return gameObj;
                                                }
                                            })
                                            .map((gameObj, i) => {
                                                return (
                                                    <div key={i} className='fav-item force-hover'>
                                                        <div className={['img-box', 'icon-game-common'].join(' ')} style={{ backgroundImage: `url("${gameObj.imggame}")` }}>
                                                            <div className='hover-box'>
                                                                <span
                                                                    style={{ textTransform: 'uppercase' }}
                                                                    onClick={() => {
                                                                        this.handleClick(gameObj, false);
                                                                    }}
                                                                >
                                                                    {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                                </span>
                                                                {gameObj.playForFun && (
                                                                    <span
                                                                        style={{ textTransform: 'uppercase' }}
                                                                        onClick={() => {
                                                                            this.handleClick(gameObj, true);
                                                                        }}
                                                                    >
                                                                        {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <p>
                                                            <span>{gameObj.name}</span>
                                                            <i
                                                                className='icon-love-on'
                                                                onClick={() => {
                                                                    isWeb && this.props.updateMemberFavoriteGame(gameObj);
                                                                }}
                                                            ></i>
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                    </AliceCarousel>
                                ) : favoritedGame.length > 0 ? (
                                    favoritedGame
                                        .filter((gameObj) => {
                                            if (!this.props.searchKeyword) {
                                                return gameObj;
                                            } else if (gameObj.name.toLowerCase().includes(this.props.searchKeyword.toLowerCase())) {
                                                return gameObj;
                                            }
                                        })
                                        .map((gameObj, i) => (
                                            <li
                                                key={i}
                                                className='force-hover'
                                                onClick={() => {
                                                    this.openMobileHoverBox(i, gameObj);
                                                }}
                                            >
                                                <div className={['img-box', 'icon-game-common'].join(' ')} style={{ backgroundImage: `url("${gameObj.imggame}")` }}>
                                                    <div className='hover-box'>
                                                        <span
                                                            style={{ textTransform: 'uppercase' }}
                                                            onClick={() => {
                                                                this.handleClick(gameObj, false);
                                                            }}
                                                        >
                                                            {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                        </span>
                                                        {gameObj.playForFun && (
                                                            <span
                                                                style={{ textTransform: 'uppercase' }}
                                                                onClick={() => {
                                                                    this.handleClick(gameObj, true);
                                                                }}
                                                            >
                                                                {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <p>
                                                    <span>{gameObj.name}</span>
                                                    <i
                                                        className='icon-love-on'
                                                        onClick={() => {
                                                            isWeb && this.props.updateMemberFavoriteGame(gameObj);
                                                        }}
                                                    ></i>
                                                </p>
                                            </li>
                                        ))
                                ) : (
                                    <span className='no-data'>{t('slot:slot.noFavouriteGameAdded', 'No Favourite Game Added')}</span>
                                )}
                            </ul>
                        </div>

                        {document.querySelector('#fixed-container') &&
                            createPortal(
                                <CSSTransition classNames='alert' in={this.state.isShowMobileBox} timeout={500} unmountOnExit>
                                    <div className='mobile-hover-box-wrap'>
                                        <div>
                                            <div
                                                className={['img-box', 'icon-game-common'].join(' ')}
                                                style={{ backgroundImage: this.state.mobileSelectedGameObj ? `url("${this.state.mobileSelectedGameObj.imggame}")` : '' }}
                                            >
                                                <div className='hover-box'>
                                                    <span
                                                        style={{ textTransform: 'uppercase' }}
                                                        onClick={() => {
                                                            this.handleClick(this.state.mobileSelectedGameObj, false);
                                                        }}
                                                    >
                                                        {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                    </span>
                                                    {this.state.mobileSelectedGameObj && this.state.mobileSelectedGameObj.playForFun && (
                                                        <span
                                                            style={{ textTransform: 'uppercase' }}
                                                            onClick={() => {
                                                                this.handleClick(this.state.mobileSelectedGameObj, true);
                                                            }}
                                                        >
                                                            {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            {favoritedGame.length > 0 && favoritedGame[this.state.mobileActive] && (
                                                <p>
                                                    <span>{favoritedGame[this.state.mobileActive].name}</span>
                                                    <i
                                                        className='icon-love-on'
                                                        onClick={() => {
                                                            this.props.updateMemberFavoriteGame(favoritedGame[this.state.mobileActive]);
                                                            this.closeMobileHoverBox();
                                                        }}
                                                    ></i>
                                                </p>
                                            )}

                                            <i
                                                className='icon-close'
                                                onClick={() => {
                                                    this.closeMobileHoverBox();
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </CSSTransition>,

                                document.querySelector('#fixed-container')
                            )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('slot')(withRouter(MyCollection)));
