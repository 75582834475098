import { portalAction } from 'root/redux/action/portal.action';
import notification from 'root/utils/notification';

const core = {
    getCorrectComponent(allComponent, type, viewType) {
        let templateKey = core.getTemplateKey(type);
        allComponent = core.getCorrectComponents(allComponent, viewType);
        if (templateKey && window[templateKey] && allComponent[window[templateKey]]) {
            return allComponent[window[templateKey]];
        } else {
            return allComponent['default'];
        }
    },

    getCorrectComponents(allComponent, viewType) {
        let isMobile = false;
        if (viewType === 'mobile') {
            isMobile = true;
        }

        if (allComponent.desktop && allComponent.mobile) {
            return isMobile ? allComponent.mobile : allComponent.desktop;
        } else {
            // fallback use old implementation
            return allComponent;
        }
    },

    getTemplateKey(type, viewType) {
        if (viewType === 'mobile') {
            return this.getMobileTemplateKey(type);
        } else {
            return this.getDesktopTemplateKey(type);
        }
    },

    getDesktopTemplateKey(type) {
        switch (type) {
            case 'navbar':
                return 'navbarTemplate';
            case 'home':
                return 'homeTemplate';
            case 'sport':
                return 'sportTemplate';
            case 'fishing':
                return 'fishingTemplate';
            case 'promotion':
                return 'promotionTemplate';
            case 'liveTv':
                return 'liveTVTemplate';
            case 'liveScore':
                return 'liveScoreTemplate';
            case 'sidebar':
                return 'sideBarTemplate';
            case 'footer':
                return 'footerTemplate';
            case 'loading':
                return 'loading';
            case 'promotionDetails':
                return 'promotionDetailsTemplate';
            case 'promotionApply':
                return 'promotionApplyTemplate';
            case 'promotionTransfer':
                return 'promotionTransferTemplate';
            case 'pagination':
                return 'paginationTemplate';
            case 'favourite':
                return 'favouriteTemplate';
            default:
                return null;
        }
    },

    getMobileTemplateKey(type) {
        switch (type) {
            case 'footer':
                return 'm_footerTemplate';
            default:
                return null;
        }
    },

    getCurrentLanguage(capitalCountryLanguage = true, setLanguage = false, vm, languageAction) {
        return new Promise((resolve) => {
            window.SPL_Content.getLanguageList().then((languageListJson) => {
                let user = vm.props.user;
                let account = user.account;
                let memberCurrency = null;
                if (account && window.SPL_Auth.isAuthenticated()) {
                    memberCurrency = account.currency;
                }
 

                let pathname = window.location.pathname;
                let localStorageLanguage = window.localStorage.getItem('LANGUAGE_CURRENCY');
                let pathArray = pathname.split('/');
                let computeLanguage = '';

                // 1st check for url contains country language or not
                if (pathArray.length >= 2 && pathArray[1].indexOf('-') !== -1 && window.languageList && window.languageList.filter((a) => a === pathArray[1]).length > 0) {
                    computeLanguage = pathArray[1];
                }
                // 2nd check for localstorage contains last language
                else if (localStorageLanguage) {
                    computeLanguage = localStorageLanguage;
                }
                // 3rd fallback use default language
                else {
                    computeLanguage = window.countryLanguageKey;
                }

                // make sure language is big capital and convert - to _
                computeLanguage = computeLanguage.toUpperCase().replace('-', '_');
                //    if got member currency mean login , only display member currency related language
                if (memberCurrency) {
                    languageListJson = languageListJson.filter((list) => list.currency === memberCurrency);
                    if (languageListJson.length > 0) {
                        const supportedLanguage = languageListJson[0].languages;
                        let filterLanguage = supportedLanguage.filter((sLang) => sLang.countryLanguageKey === computeLanguage);
                        if (filterLanguage.length <= 0 && supportedLanguage.length > 0) {
                            let isHaveSameLang = supportedLanguage.filter((sLang) => sLang.countryLanguageKey.substring(0, 2) === computeLanguage.substring(0, 2));
                            if (isHaveSameLang.length > 0) {
                                computeLanguage = isHaveSameLang[0].countryLanguageKey;
                            } else {
                                computeLanguage = supportedLanguage[0].countryLanguageKey;
                            }
                        }
                    }
                }

                // make sure language is big capital and convert - to _
                computeLanguage = computeLanguage.toUpperCase().replace('-', '_');

                if (setLanguage && vm && languageAction) {
                    core.setLanguageKeyAndCountryLanguage(vm, computeLanguage, languageAction);
                }

                if (!capitalCountryLanguage) {
                    resolve(computeLanguage.toLowerCase().replace('_', '-'));
                    return computeLanguage.toLowerCase().replace('_', '-');
                } else {
                    resolve(computeLanguage);
                    return computeLanguage;
                }
            });
        });
    },

    setLanguageKeyAndCountryLanguage(vm, countryLanguageKey, languageAction, updateUrl = false, backHomePage = false, checkRedirectDomain = false, isLogin = false) {
        let key = countryLanguageKey.toLowerCase().split('_')[0];
        countryLanguageKey = countryLanguageKey.toUpperCase().replace('-', '_');
        switch (key) {
            case 'zh':
                key = 'zh-CN';
                break;
            case 'th':
                if (countryLanguageKey === 'TH_TH') {
                    key = 'th';
                }
                break;
            case 'vi':
                if (countryLanguageKey === 'VI_VN') {
                    key = 'vi';
                }
                break;
            case 'id':
                if (countryLanguageKey === 'ID_ID') {
                    key = 'id';
                }
                break;
            case 'bn':
                if (countryLanguageKey === 'BN_BN') {
                    key = 'bn';
                }
                break;
            case 'km':
                if (countryLanguageKey === 'KM_KH') {
                    key = 'km';
                }
                break;
            case 'ms':
                if (countryLanguageKey === 'MS_MY') {
                    key = 'ms';
                }
                break;
            case 'tl':
                if (countryLanguageKey === 'TL_PH') {
                    key = 'tl';
                }
                break;
            default:
                key = 'en';
                break;
        }

        window.SPL_Translation.initCurrentLanguage(key);

        if (updateUrl) {
            const { history, portal } = vm.props;
            let path = countryLanguageKey.replace('_', '-').toLowerCase();
            if (history && history.location && history.location.pathname) {
                if (
                    checkRedirectDomain &&
                    portal &&
                    portal.settings &&
                    portal.settings.languageDomains &&
                    portal.settings.languageDomains.hasOwnProperty(countryLanguageKey)
                ) {
                    let defaultCountryLanguage = window.countryLanguageKey;
                    let lastDefaultKey = defaultCountryLanguage.split('_').pop();
                    let selectedKey = countryLanguageKey.split('_').pop();
                    let urlParam = portal.settings.languageDomains[countryLanguageKey];
                    let urlObj = new URL(urlParam);
                    let domainHost = urlObj.host;
                    let currentHost = window.location.host;
                    let pathname = backHomePage ? '/home' : history.location.pathname;
                    let jwt = window.SPL_Cookie.getCookie('t');
                    if (domainHost !== currentHost && lastDefaultKey != selectedKey) {
                        postLanguageChangedAppMessage(countryLanguageKey);
                        window.location.href = urlParam + '?langCode=' + path + '&path=' + pathname + '&t=' + jwt;
                        return;
                    }
                }
            }
            updatei18nKey();
            postLanguageChangedAppMessage();

            updateUrlFunc(path, history);
        } else {
            updatei18nKey();
            postLanguageChangedAppMessage();
        }

        function postLanguageChangedAppMessage() {
            if (!window.isAgent && !window.isAffiliate) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    window.SPL_LiteApp.onLanguageChanged(countryLanguageKey.toLowerCase().replace('_', '-'));
                }
            }
        }

        function updatei18nKey() {
            vm.props.i18n.changeLanguage(key);
            vm.props.dispatch(languageAction.changeLanguage({ key, countryLanguageKey }));
        }

        function updateUrlFunc(path, history) {
            if (backHomePage) {
                window.location.pathname = `/${path}/home`;
            } else {
                window.location.pathname = `/${path}` + history.location.pathname;
            }
        }
    },

    copy(t, text) {
        let copied = 'transaction:transaction.withdrawal.copied';
        let successful = window.SPL_Other.copyToClipboard(text);
        // try {
        //     let successful = document.execCommand('copy');
        //     if (successful) {
        //         notification.showNotification('success', t(copied));
        //     }
        // } catch (err) {
        //     notification.showNotification('error', 'Unable to copy');
        // }
        if (successful) {
            notification.showNotification('success', t(copied, 'Copied'), { autoClose: 2000 });
        }
    },

    openLivechat(countryLanguageKey, viewType) {
        window.SPL_Content.getContact(countryLanguageKey, viewType).then((data) => {
            let liveChatUrl = null;
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].name === 'LiveChat') {
                        liveChatUrl = data[i].url;
                        break;
                    }
                }
            }
            if (liveChatUrl) {
                window.open(liveChatUrl, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
            }
        });
    },

    replaceAll(str, search, replacement) {
        return str.replace(new RegExp(search, 'g'), replacement);
    },

    portalIsLoading(props, flag) {
        props.dispatch(portalAction.portalIsLoading(flag));
    },

    appendZero(str, length) {
        if (str && str.length > length) {
            return str;
        } else if (str) {
            let diff = length - str.length;
            let extraChar = '';
            for (let i = 0; i < diff; i++) {
                extraChar += '0';
            }
            return extraChar + str;
        } else {
            return str;
        }
    },

    getContact(language, screen) {
        return new Promise(function (resolve) {
            window.SPL_Content.getContact(language.countryLanguageKey, screen.viewType).then((res) => {
                let livechatObj = res.filter((a) => a.name.toLowerCase() === 'livechat');
                let socialMediaList = res.filter(
                    (a) =>
                        a.name.toLowerCase() === 'youtube' || a.name.toLowerCase() === 'facebook' || a.name.toLowerCase() === 'instagram' || a.name.toLowerCase() === 'twitter'
                );
                let contactList = res.filter(
                    (a) =>
                        a.name.toLowerCase() !== 'youtube' &&
                        a.name.toLowerCase() !== 'facebook' &&
                        a.name.toLowerCase() !== 'instagram' &&
                        a.name.toLowerCase() !== 'qrcode' &&
                        a.name.toLowerCase() !== 'livechat'
                );

                if (livechatObj.length > 0) {
                    livechatObj[0].isLiveChat = true;
                    livechatObj[0].desc = 'global:footer.chatNow';
                }

                let returnJson = {
                    contactList: contactList.concat(livechatObj),
                    socialMediaList: socialMediaList,
                };
                resolve(returnJson);
            });
        });
    },

    getCorrectBankIcon(bankCode, vm, isSvg) {
        const bankIconPrefix = isSvg ? 'bank_' : 'icon-bank_';
        if (bankCode) {
            switch (bankCode.toLowerCase()) {
                case 'scb':
                    if (vm.props.user.account.currency === 'MYR') {
                        return bankIconPrefix + 'scb_myr';
                    } else {
                        return bankIconPrefix + 'scb';
                    }
                default:
                    if (bankCode.toLowerCase().includes('other')) {
                        bankCode = 'other';
                    }
                    return bankIconPrefix + bankCode.toLowerCase();
            }
        }
        return bankIconPrefix;
    },
};

export default core;
