import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import SVGElements from 'root/components/SVGElements';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { portalAction } from 'root/redux/action';

import vm from '../sidemenu.controller';
import commonUtil from '../../../../utils/common-util';
import controller from '../sidemenu.controller';

class FloatingRightSideMenu extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        commonUtil.getAffiliateLink(this);
        commonUtil.loadCustomTranslation('mobileNavbar', this, function () {});
        vm.initCompSettings();
        vm.getMobileSidebar();
        vm.getCurrentPage();
    }

    render() {
        const { language } = this.props;
        const { customTranslationObj } = this.state;

        return (
            <Translation>
                {(t) => (
                    <div
                        className='side-menu-box'
                        onClick={() => {
                            vm.closeSubmenu();
                        }}
                    >
                        <div
                            className='side-menu'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div className='menu-btns'>
                                <i
                                    className='icon-back'
                                    onClick={() => {
                                        vm.closeSubmenu();
                                    }}
                                ></i>
                                <div
                                    className='right-box'
                                    onClick={() => {
                                        vm.submenuClicked({ name: 'Language', route: '/language' });
                                    }}
                                >
                                    <span className='lang-title'>{t('global:global.sidebar.language.heading2', 'Language')}</span>
                                    <LazyLoadImage className='lang-wap-side' src={language.src} alt={language.key + '.png'} />
                                </div>
                            </div>
                            <div className='side-menu-scroll-wrapper'>
                                {!this.props.user.isLogin && (
                                    <>
                                        <div className='welcome-content'>
                                            <Trans
                                                i18nKey='global:global.sidebar.account.welcomeBonusForNewPlayer'
                                                values={{ value: controller.getWelcomeBonusValue() }}
                                            ></Trans>
                                        </div>

                                        <span
                                            className='btn-sign-up'
                                            onClick={() => {
                                                this.props.dispatch(portalAction.floatingPageUpdated('register'));
                                            }}
                                        >
                                            {t('global:global.menu.account.registerNow', 'Register Now')}
                                        </span>

                                        <p className='login-text'>
                                            <Trans i18nKey={'global:global.sidebar.account.memberAlready'}>
                                                already a member ?
                                                <span
                                                    onClick={() => {
                                                        this.props.dispatch(portalAction.floatingPageUpdated('login'));
                                                    }}
                                                >
                                                    login here
                                                </span>
                                            </Trans>
                                        </p>
                                    </>
                                )}

                                {this.state.navList && (
                                    <Fragment>
                                        <ul className='popular-submenu'>
                                            {this.state.navList.popularSubmenu.length > 0 &&
                                                this.state.navList.popularSubmenu.map((menu, i) => (
                                                    <li
                                                        key={i}
                                                        className={menu.route === this.state.selectedMenuRoute ? 'on' : ''}
                                                        onClick={() => {
                                                            vm.submenuClicked(menu);
                                                        }}
                                                    >
                                                        {menu.isNewProvider && <SVGElements className='new-icon' name='new-icon' />}
                                                        {menu.isHotProvider && <LazyLoadImage className='hot-icon' src='/public/html/images/homepage/hot-icon.png' />}
                                                        <SVGElements
                                                            name={menu.key && 'side-' + menu.key.toLowerCase()}
                                                            className={`icon-menu-item ${menu.key && 'side-' + menu.key.toLowerCase()}`}
                                                            on={true}
                                                        />
                                                        <span>{t(menu.translation)}</span>
                                                    </li>
                                                ))}
                                        </ul>

                                        <ul className='submenu'>
                                            {this.state.navList.submenu.length > 0 &&
                                                this.state.navList.submenu.map((menu, i) => (
                                                    <li
                                                        key={i}
                                                        className={menu.route === this.state.selectedMenuRoute ? 'on' : ''}
                                                        onClick={() => {
                                                            vm.submenuClicked(menu);
                                                        }}
                                                    >
                                                        {menu.isNewProvider && <SVGElements className='new-icon' name='new-icon' />}
                                                        {menu.isHotProvider && <LazyLoadImage className='hot-icon' src='/public/html/images/homepage/hot-icon.png' />}
                                                        {customTranslationObj && customTranslationObj[menu.name] ? customTranslationObj[menu.name] : t(menu.translation)}
                                                        <span className='icon-box'>
                                                            <SVGElements
                                                                name={menu.key && 'side-' + menu.key.toLowerCase()}
                                                                className={`icon-menu-item ${menu.key && 'side-' + menu.key.toLowerCase()}`}
                                                                on={true}
                                                            />
                                                        </span>
                                                    </li>
                                                ))}
                                        </ul>
                                    </Fragment>
                                )}

                                {!commonUtil.getIsZT828LiteApp() && (
                                    <div className='app-download'>
                                        <div className='left-content'>
                                            <LazyLoadImage src='/public/html/images/download/me88-apps-icon.png' />

                                            <div className='download-content'>
                                                <span className='title'>{t(`app:app.appDownload.title`)}</span>
                                                <span className={'desc ' + language.key}>{t(`app:app.appDownload.desc2`)}</span>
                                            </div>
                                        </div>

                                        <span className='btn-download' onClick={() => vm.submenuClicked({ route: '/download' })}>
                                            {t(`app:app.download`)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['global', 'app'])(withRouter(FloatingRightSideMenu)));
